import { useLocation } from 'react-router-dom';
import { BannerCampaign } from '../../types';
import BannerCampaigns from './Components/BannerCampaigns';
import BannersByUrl from './Components/BannersByUrl';

export default function PageBanners({
    campaigns,
    url,
    query,
}: {
    campaigns?: BannerCampaign[];
    url?: string;
    query?: string;
}) {
    if (campaigns === undefined && url === undefined) {
        const location = useLocation();
        return <BannersByUrl url={location.pathname} />;
    } else if (url) {
        return <BannersByUrl url={url} query={query ?? ''} />;
    }
    if (campaigns) {
        return <BannerCampaigns campaigns={campaigns} />;
    }
    return <BannerCampaigns campaigns={[]} />;
}
