import { gql } from '@apollo/client';
import { FormikProps, FormikTouched, FormikValues } from 'formik';
import window from 'global/window';
import * as React from 'react';
import { client } from '../Graphql/ApolloClient';
import {
    CONTACT_INFO_ORDER_FIELDS,
    SAVED_PAYMENT_METHOD_FIELDS,
} from '../Graphql/fragments';
import { isHTTPS } from '../config';
import { empty } from '../functions';
import {
    AddressVerificationResponse,
    Contact,
    Order,
    PaymentSubmitResponse,
    PaymentTokenResponse,
    SavedPaymentMethodsResponse,
} from '../types';
import { CartContext } from './CartProvider';
import { UserAuth } from './UserAuthProvider';
import * as PaymentDeferredUtils from './PaymentDeferredUtils';

export type PaymentTypes =
    | 'credit'
    | 'paypal'
    | 'applepay'
    | 'googlepay'
    | 'affirm'
    | 'giftcard'
    | 'coupon'
    | 'rewardpoints'
    | 'dummy'
    | 'paid';
export type SavedPaymentTypes = 'credit' | 'paypal' | 'applepay' | 'googlepay';
export interface PaymentDetails {
    type: PaymentTypes;
    token?: string | undefined;
    image?: string | undefined;
    details?: string | undefined;
    isSavedPaymentType?: boolean | undefined;
}

export interface ContactFormValues {
    firstName: string;
    lastName: string;
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    phone: string;
}

interface NewsletterSignupCheckboxValues {
    email: string;
    newsletterSignup: boolean;
}

export type CheckoutForm = 'billing' | 'shipping' | 'payment' | 'newsletter';
export type CheckoutFormProps = FormikProps<FormikValues>;
export type ContactFormProps = FormikProps<ContactFormValues>;
export type CheckoutNewsletterFormProps =
    FormikProps<NewsletterSignupCheckboxValues>;
type CheckoutFormContactRef = React.MutableRefObject<FormikProps<FormikValues>>;
type CheckoutFormNewsletterRef = React.MutableRefObject<
    FormikProps<FormikValues>
>;
type CheckoutFormPaymentRef = React.MutableRefObject<FormikProps<FormikValues>>;
type CheckoutFormRef =
    | CheckoutFormContactRef
    | CheckoutFormNewsletterRef
    | CheckoutFormPaymentRef;
type CheckoutFormsProps =
    | CheckoutFormProps
    | ContactFormProps
    | CheckoutNewsletterFormProps;

interface CheckoutFormRefType {
    billing: CheckoutFormContactRef;
    shipping: CheckoutFormContactRef;
    newsletter: CheckoutFormNewsletterRef;
    payment: CheckoutFormPaymentRef;
}
interface CheckoutFormRefValidation {
    form: CheckoutFormsProps;
    errorMessage: string;
}

const DEFAULT_PAYMENT_TYPE_DETAILS = {
    type: 'credit' as PaymentTypes,
    isSavedPaymentType: false,
} as PaymentDetails;

const QL_UPDATE_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation updateOrderCustomerInfo(
        $orderId: ID!
        $type: String!
        $firstName: String!
        $lastName: String!
        $email: String!
        $company: String
        $addressLine1: String!
        $addressLine2: String!
        $city: String!
        $state: String!
        $zip: String!
        $country: String
        $phone: String!
    ) {
        updateOrderCustomerInfo(
            orderId: $orderId
            type: $type
            firstName: $firstName
            lastName: $lastName
            email: $email
            company: $company
            addressLine1: $addressLine1
            addressLine2: $addressLine2
            city: $city
            state: $state
            zip: $zip
            country: $country
            phone: $phone
        ) {
            ...ContactInfoOrderFields
        }
    }
`;

const QL_CHECKOUT_PAYMENT_TOKEN = gql`
    mutation checkoutPaymentToken(
        $orderId: ID!
        $type: String!
        $userId: String
        $returnUrl: String
    ) {
        checkoutPaymentToken(
            orderId: $orderId
            type: $type
            userId: $userId
            returnUrl: $returnUrl
        ) {
            type
            token
            signature
        }
    }
`;

const QL_CHECKOUT_PAYMENT_SUBMIT = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation getCheckoutPaymentSubmit(
        $orderId: ID!
        $type: String!
        $token: String!
        $cartVerificationToken: String!
        $additionalData: String
        $paymentDeferreds: String!
    ) {
        checkoutPaymentSubmit(
            type: $type
            orderId: $orderId
            token: $token
            cartVerificationToken: $cartVerificationToken
            additionalData: $additionalData
            paymentDeferreds: $paymentDeferreds
        ) {
            success
            redirectUrl
            errorMessage
            order {
                ...ContactInfoOrderFields
            }
        }
    }
`;
const QL_VERIFY_ADDRESS = gql`
    mutation verifyAddress(
        $orderId: ID!
        $line1: String!
        $line2: String!
        $city: String!
        $state: String!
        $zip: String!
        $country: String
    ) {
        verifyAddress(
            orderId: $orderId
            line1: $line1
            line2: $line2
            city: $city
            state: $state
            zip: $zip
            country: $country
        ) {
            isValidAddress
            addressReviewId
            currentAddress {
                line1
                line2
                city
                state
                zip
                country
            }
            suggestedAddress {
                line1
                line2
                city
                state
                zip
                country
            }
        }
    }
`;

const QL_UPDATE_ORDER_SMS_PHONE = gql`
    mutation updateOrderSMSPhone($orderId: ID!, $phone: String!) {
        updateOrderSMSPhone(orderId: $orderId, phone: $phone) {
            success
            message
        }
    }
`;

const QL_SAVED_PAYMENT_METHODS = gql`
    ${SAVED_PAYMENT_METHOD_FIELDS}
    mutation userSavedPaymentMethods {
        userSavedPaymentMethods {
            credit {
                ...SavedPaymentMethodFields
            }
            paypal {
                ...SavedPaymentMethodFields
            }
            applepay {
                ...SavedPaymentMethodFields
            }
            googlepay {
                ...SavedPaymentMethodFields
            }
        }
    }
`;
const QL_SAVED_PAYMENT_METHOD_DELETE = gql`
    ${SAVED_PAYMENT_METHOD_FIELDS}
    mutation userDeleteSavedPaymentMethod($token: ID!) {
        userDeleteSavedPaymentMethod(token: $token) {
            credit {
                ...SavedPaymentMethodFields
            }
            paypal {
                ...SavedPaymentMethodFields
            }
            applepay {
                ...SavedPaymentMethodFields
            }
            googlepay {
                ...SavedPaymentMethodFields
            }
        }
    }
`;

export const CheckoutContext = React.createContext({
    orderUpdating: false,
    updateCustomerInfo: (
        type: 'billing' | 'shipping',
        values: Contact,
        linkBillingInfo?: boolean,
        ignoreValidation?: boolean,
    ): Promise<Order> => {
        return new Promise(() => {
            return;
        });
    },
    updateOrderSMSPhone: (phoneNumber: string): Promise<void> => {
        return new Promise(() => {
            return;
        });
    },
    getCheckoutPaymentToken: (
        type: PaymentTypes,
        returnUrl?: string,
    ): Promise<PaymentTokenResponse> => {
        return new Promise(() => {
            return;
        });
    },
    checkoutPaymentToken: null,
    braintreeClientInstance: null,
    setBraintreeClientInstance: (instance: any) => {
        return;
    },
    paymentDetails: DEFAULT_PAYMENT_TYPE_DETAILS,
    setPaymentDetails: (details: PaymentDetails) => {
        return;
    },
    getPaymentDetails: (): PaymentDetails => {
        return DEFAULT_PAYMENT_TYPE_DETAILS;
    },
    clearPaymentDetails: () => {
        return;
    },

    selectedPaymentType: 'dummy' as PaymentTypes,
    setSelectedPaymentType: (type: PaymentTypes) => {
        return;
    },
    canUseApplePay: (): boolean => {
        return false;
    },
    submitPayment: (
        type?: PaymentTypes,
        token?: string,
        additionalData?: string,
    ): Promise<PaymentSubmitResponse> => {
        return new Promise(() => {
            return;
        });
    },
    activeStep: 1,
    setActiveStep: (step: number) => {
        return;
    },
    handleNext: () => {
        return;
    },
    handleBack: () => {
        return;
    },
    handleReset: () => {
        return;
    },
    getCheckoutFormRef: (type: CheckoutForm): CheckoutFormRef => {
        return React.useRef<CheckoutFormsProps>(null) as CheckoutFormRef;
    },
    showBillingForm: false,
    setShowBillingForm: (show: boolean) => {
        return;
    },
    paymentTypeRequiresReview: (type?: PaymentTypes): boolean => {
        return false;
    },
    paymentTypeIsDirectSubmit: (type?: PaymentTypes): boolean => {
        return true;
    },
    paymentTypeRequiresBilling: (type?: PaymentTypes): boolean => {
        return true;
    },
    paymentTypeRequiresShipping: (type?: PaymentTypes): boolean => {
        return true;
    },
    paymentTypeRequiresBillingAndShipping: (type?: PaymentTypes): boolean => {
        return true;
    },
    paymentTypeShowLegalText: (type?: PaymentTypes): boolean => {
        return true;
    },
    paymentTypeShowNewsletterSignup: (type?: PaymentTypes): boolean => {
        return true;
    },
    validateFormsAndSubmitPayment: (): Promise<string> => {
        return new Promise(() => {
            return '/cart/complete';
        });
    },
    verifyAddress: (
        contactInfo: Contact,
        bypass?: boolean,
    ): Promise<AddressVerificationResponse> => {
        return new Promise(() => {
            return true;
        });
    },
    verifySMS: (): Promise<boolean> => {
        return new Promise(() => {
            return true;
        });
    },
    setPaymentProcessMethod: (method: () => Promise<string>) => {
        return;
    },
    savedPaymentMethods: null as SavedPaymentMethodsResponse | null,
    setSavedPaymentMethods: (paymentMethods: SavedPaymentMethodsResponse) => {
        return;
    },
    getSavedPaymentMethods: (): Promise<SavedPaymentMethodsResponse> => {
        return new Promise(() => {
            return true;
        });
    },
    hasSavedPaymentsForType: (type: SavedPaymentTypes): boolean => {
        return false;
    },
    deleteSavedPaymentMethod: (
        token: string,
    ): Promise<SavedPaymentMethodsResponse> => {
        return new Promise(() => {
            return true;
        });
    },
});

let paymentProcessMethod = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        resolve('');
    });
};

export function CheckoutProvider(props: { children: any }) {
    const { getUserId, isLoggedIn } = React.useContext(UserAuth);
    const { order, setOrder } = React.useContext(CartContext);
    const [orderUpdating, setOrderUpdating] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [paymentDetails, setPaymentDetailsValue] = React.useState(
        DEFAULT_PAYMENT_TYPE_DETAILS,
    );
    const [checkoutPaymentToken, setCheckoutPaymentToken] =
        React.useState(null);
    const [braintreeClientInstance, setBraintreeClientInstance] =
        React.useState(null);
    const [showBillingForm, setShowBillingForm] = React.useState(false);
    const [selectedPaymentType, setSelectedPaymentType] =
        React.useState<PaymentTypes>('dummy');
    const [savedPaymentMethods, setSavedPaymentMethodsData] =
        React.useState<SavedPaymentMethodsResponse | null>(null);

    const checkoutForms = React.useRef<CheckoutFormRefType>({
        billing: React.useRef<CheckoutFormProps>(
            null,
        ) as CheckoutFormContactRef,
        shipping: React.useRef<CheckoutFormProps>(
            null,
        ) as CheckoutFormContactRef,
        newsletter: React.useRef<CheckoutFormProps>(
            null,
        ) as CheckoutFormNewsletterRef,
        payment: React.useRef<CheckoutFormProps>(
            null,
        ) as CheckoutFormPaymentRef,
    });

    const getCheckoutFormRef = (type: CheckoutForm): CheckoutFormRef => {
        return checkoutForms.current[type];
    };

    const setPaymentProcessMethod = (method: () => Promise<string>) => {
        paymentProcessMethod = method;
    };
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep =>
            prevActiveStep === 0 ? 0 : prevActiveStep - 1,
        );
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const canUseApplePay = () => {
        return (
            isHTTPS() &&
            window.ApplePaySession &&
            window.ApplePaySession.supportsVersion(3) &&
            window.ApplePaySession.canMakePayments()
        );
    };
    const updateCustomerInfo = (
        type: 'billing' | 'shipping',
        values: Contact,
        linkBillingInfo = true,
        ignoreValidation = false,
    ) => {
        return new Promise<Order>((resolve, reject) => {
            (async () => {
                if (
                    !ignoreValidation &&
                    (empty(values.firstName) ||
                        empty(values.lastName) ||
                        empty(values.email) ||
                        empty(values.address1) ||
                        empty(values.city) ||
                        empty(values.state) ||
                        empty(values.zip) ||
                        empty(values.phone))
                ) {
                    resolve(order);
                } else {
                    try {
                        setOrderUpdating(true);
                        const response = await client.mutate({
                            mutation: QL_UPDATE_ORDER,
                            variables: {
                                orderId: order.id,
                                type: type,
                                firstName: values.firstName ?? '',
                                lastName: values.lastName ?? '',
                                email: values.email ?? '',
                                company: values.company ?? '',
                                addressLine1: values.address1 ?? '',
                                addressLine2: values.address2 ?? '',
                                city: values.city ?? '',
                                state: values.state ?? '',
                                zip: values.zip ?? '',
                                phone: values.phone ?? '',
                                country: values.country ?? 'US',
                            },
                            fetchPolicy: 'no-cache',
                        });
                        if (response.errors) {
                            setOrderUpdating(false);
                            reject(response.errors[0].message);
                        }
                        if (response.data.updateOrderCustomerInfo) {
                            if (linkBillingInfo && type === 'shipping') {
                                updateCustomerInfo(
                                    'billing',
                                    values,
                                    false,
                                    ignoreValidation,
                                ).then(orderData => {
                                    setOrder(orderData);
                                    setOrderUpdating(false);
                                    resolve(orderData);
                                });
                            } else {
                                setOrder(response.data.updateOrderCustomerInfo);
                                setOrderUpdating(false);
                                resolve(response.data.updateOrderCustomerInfo);
                            }
                        }
                    } catch (err: any) {
                        reject(err.message);
                    }
                }
            })();
        });
    };
    const updateOrderSMSPhone = (phoneNumber: string): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            if (phoneNumber.length < 7) {
                resolve();
                return;
            }
            (async () => {
                try {
                    const response = await client.mutate({
                        mutation: QL_UPDATE_ORDER_SMS_PHONE,
                        variables: {
                            orderId: order.id,
                            phone: phoneNumber,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject(response.errors[0].message);
                    }
                    if (response.data.updateOrderSMSPhone) {
                        resolve();
                    }
                } catch (err: any) {
                    setOrderUpdating(false);
                    reject(err.message);
                }
            })();
        });
    };

    const setPaymentDetails = (details: PaymentDetails) => {
        localStorage.setItem(
            'checkout-payments',
            JSON.stringify({ [order.id]: details }),
        );
        setPaymentDetailsValue(details);
    };

    const clearPaymentDetails = () => {
        localStorage.removeItem('checkout-payments');
        setPaymentDetailsValue(DEFAULT_PAYMENT_TYPE_DETAILS);
    };

    const getPaymentDetails = (): PaymentDetails => {
        const details = localStorage.getItem('checkout-payments');
        if (details) {
            const orderPayments = JSON.parse(details);
            if (orderPayments[order.id]) {
                return orderPayments[order.id] as PaymentDetails;
            }
        }
        return paymentDetails;
    };

    const getCheckoutPaymentToken = (
        type: PaymentTypes,
        returnUrl?: string,
    ) => {
        return new Promise<PaymentTokenResponse>((resolve, reject) => {
            (async () => {
                try {
                    const response = await client.mutate({
                        mutation: QL_CHECKOUT_PAYMENT_TOKEN,
                        variables: {
                            orderId: order.id,
                            type: type,
                            userId: `${getUserId()}`,
                            returnUrl: returnUrl != undefined ? returnUrl : '',
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        throw new Error(response.errors[0].message);
                    }
                    if (response.data.checkoutPaymentToken) {
                        setCheckoutPaymentToken(
                            response.data.checkoutPaymentToken,
                        );
                        resolve(response.data.checkoutPaymentToken);
                    }
                } catch (err: any) {
                    reject(err.message);
                }
            })();
        });
    };

    const submitPayment = (
        type?: PaymentTypes,
        token?: string,
        additionalData?: string,
    ) => {
        return new Promise<PaymentSubmitResponse>((resolve, reject) => {
            (async () => {
                const details = getPaymentDetails();
                const paymentType = type ?? details.type;
                const paymentToken = token ?? details.token ?? '';
                const paymentDeferredsString =
                    PaymentDeferredUtils.getPaymentDeferredsString();
                if (paymentDetails.type !== 'paid' && paymentToken == '') {
                    reject('Missing Payment');
                }
                if (order.cartVerificationToken === null) {
                    reject('Missing Verification Token');
                }
                try {
                    const response = await client.mutate({
                        mutation: QL_CHECKOUT_PAYMENT_SUBMIT,
                        variables: {
                            orderId: order.id,
                            type: paymentType,
                            token: paymentToken,
                            cartVerificationToken:
                                order.cartVerificationToken ?? '',
                            additionalData: additionalData,
                            paymentDeferreds: paymentDeferredsString,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject(response.errors[0].message);
                    }
                    if (
                        response.data?.checkoutPaymentSubmit?.success ==
                            false &&
                        response.data?.checkoutPaymentSubmit?.errorMessage
                    ) {
                        reject(
                            response.data.checkoutPaymentSubmit.errorMessage,
                        );
                    }
                    if (response.data.checkoutPaymentSubmit) {
                        // Need to set the deferred payment methods, other values
                        setOrder(response.data.checkoutPaymentSubmit.order);
                        resolve(response.data.checkoutPaymentSubmit);
                    }
                } catch (err: any) {
                    reject(err.message);
                }
            })();
        });
    };

    const paymentTypeRequiresReview = (type?: PaymentTypes) => {
        if (type === undefined || type === null) {
            type = paymentDetails.type ?? 'credit';
        }
        return ['paypal', 'googlepay', 'affirm'].indexOf(type) > -1;
    };
    const paymentTypeIsDirectSubmit = (type?: PaymentTypes) => {
        if (type === undefined || type === null) {
            type = paymentDetails.type ?? 'credit';
        }
        return (
            ['credit', 'paid'].indexOf(type) > -1 ||
            paymentDetails.isSavedPaymentType === true
        );
    };
    const paymentTypeRequiresBilling = (type?: PaymentTypes) => {
        if (type === undefined || type === null) {
            type = paymentDetails.type ?? 'credit';
        }
        return (
            ['credit', 'affirm', 'paid'].indexOf(type) > -1 ||
            paymentDetails.isSavedPaymentType === true
        );
    };
    const paymentTypeRequiresShipping = (type?: PaymentTypes) => {
        if (type === undefined || type === null) {
            type = paymentDetails.type ?? 'credit';
        }
        return (
            ['credit', 'affirm', 'paid'].indexOf(type) > -1 ||
            paymentDetails.isSavedPaymentType === true
        );
    };
    const paymentTypeRequiresBillingAndShipping = (type?: PaymentTypes) => {
        return (
            paymentTypeRequiresBilling(type) &&
            paymentTypeRequiresShipping(type)
        );
    };
    const paymentTypeShowLegalText = (type?: PaymentTypes) => {
        return true;
    };
    const paymentTypeShowNewsletterSignup = (type?: PaymentTypes) => {
        return paymentTypeShowLegalText(type);
    };

    const validateFormRef = async (
        formRef: CheckoutFormRefValidation,
    ): Promise<CheckoutFormRefValidation> => {
        return new Promise((resolve, reject) => {
            if (formRef.form) {
                formRef.form
                    .validateForm()
                    .then(touched => {
                        const remapped = { ...touched } as any;
                        // re-map values to true
                        Object.keys(remapped).forEach((v: any) => {
                            document.body.dispatchEvent(
                                new CustomEvent('checkoutFormValidationError', {
                                    detail: { errorField: v },
                                }),
                            );
                            remapped[v] = true;
                        });
                        if (Object.values(remapped).length > 0) {
                            if (
                                formRef.form &&
                                typeof formRef.form.setTouched === 'function'
                            ) {
                                formRef.form.setTouched(
                                    remapped as unknown as FormikTouched<any>,
                                    true,
                                );
                            }
                            reject(formRef.errorMessage);
                        } else {
                            resolve(formRef);
                        }
                    })
                    .catch(() => {
                        console.log('form validation failed');
                        // if we don't have the form set, just assume its hidden/empty and resolve true
                        resolve(formRef);
                    });
            } else {
                // if we don't have the form set, just assume its hidden/empty and resolve true
                resolve(formRef);
            }
        });
    };

    const validateForms = (
        forms: CheckoutFormRefValidation[],
    ): Promise<CheckoutFormsProps[]> => {
        return new Promise((resolve, reject) => {
            Promise.all([
                ...forms.map((n: CheckoutFormRefValidation) =>
                    validateFormRef(n),
                ),
            ])
                .then((d: CheckoutFormRefValidation[]) => {
                    resolve(
                        forms.map((n: CheckoutFormRefValidation) => n.form),
                    );
                })
                .catch((err: string) => {
                    reject(err);
                });
        });
    };

    const validateFormsAndSubmitPayment = (): Promise<string> => {
        return new Promise((resolve, reject) => {
            const formsToValidate: CheckoutFormRefValidation[] = [];
            const shippingFormRef = getCheckoutFormRef('shipping');
            const billingFormRef = getCheckoutFormRef('billing');
            const newsletterFormRef = getCheckoutFormRef('newsletter');
            const paymentFormRef = getCheckoutFormRef('payment');

            if (shippingFormRef.current) {
                formsToValidate.push({
                    form: shippingFormRef.current,
                    errorMessage:
                        'There is a problem. Review your shipping information for any missing fields or fields that require a particular format and try again.',
                });
            }
            if (billingFormRef.current) {
                formsToValidate.push({
                    form: billingFormRef.current,
                    errorMessage:
                        'There is a problem. Review your billing information for any missing fields or fields that require a particular format and try again.',
                });
            }
            if (newsletterFormRef.current) {
                formsToValidate.push({
                    form: newsletterFormRef.current,
                    errorMessage:
                        'There is a problem. The newsletter form failed to submit.',
                });
            }
            if (paymentFormRef.current) {
                formsToValidate.push({
                    form: paymentFormRef.current,
                    errorMessage:
                        'There is a problem. Review your payment details for any missing fields or fields that require a particular format and try again.',
                });
            }

            // 1. Validate Shipping / Billing / Payment forms
            validateForms(formsToValidate)
                .then(formsToSubmit => {
                    // 2. Submit Forms
                    Promise.all([
                        ...formsToSubmit.map((n: any) => n.submitForm()),
                    ])
                        .then(submittedResponse => {
                            // 3. Submit Payment / Process Order
                            paymentProcessMethod()
                                .then(redirectUrl => {
                                    document.body.dispatchEvent(
                                        new CustomEvent('OrderSubmitted'),
                                    );
                                    resolve(redirectUrl);
                                })
                                .catch(err => {
                                    reject(err);
                                });
                        })
                        .catch(err => {
                            reject(err);
                        });
                })
                .catch((errMessage: string) => {
                    reject(errMessage);
                });
        });
    };

    const verifyAddress = (values: Contact, bypass = false) => {
        return new Promise<AddressVerificationResponse>((resolve, reject) => {
            if (bypass) {
                resolve({
                    isValidAddress: true,
                } as AddressVerificationResponse);
            }
            (async () => {
                const response = await client.mutate({
                    mutation: QL_VERIFY_ADDRESS,
                    variables: {
                        orderId: order.id,
                        line1: values.address1,
                        line2: values.address2,
                        city: values.city,
                        state: values.state,
                        zip: values.zip,
                        phone: values.phone,
                        country: values.country,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.data.verifyAddress) {
                    if (response.data.verifyAddress.isValidAddress) {
                        resolve(response.data.verifyAddress);
                    } else {
                        reject(response.data.verifyAddress);
                    }
                }
            })();
        });
    };
    const setSavedPaymentMethods = (
        paymentMethods: SavedPaymentMethodsResponse,
    ) => {
        setSavedPaymentMethodsData(paymentMethods);
    };

    const getSavedPaymentMethods = (): Promise<SavedPaymentMethodsResponse> => {
        return new Promise<SavedPaymentMethodsResponse>((resolve, reject) => {
            if (!isLoggedIn()) {
                if (savedPaymentMethods !== null) {
                    setSavedPaymentMethodsData(null);
                }
                reject([]);
                return;
            }
            if (savedPaymentMethods) {
                resolve(savedPaymentMethods);
                return;
            }
            (async () => {
                try {
                    const response = await client.mutate({
                        mutation: QL_SAVED_PAYMENT_METHODS,
                        fetchPolicy: 'no-cache',
                    });
                    if (response.data.userSavedPaymentMethods) {
                        setSavedPaymentMethods(
                            response.data.userSavedPaymentMethods,
                        );
                        resolve(response.data.userSavedPaymentMethods);
                    } else {
                        reject([]);
                    }
                } catch (e) {
                    reject([]);
                }
            })();
        });
    };

    const hasSavedPaymentsForType = (type: SavedPaymentTypes): boolean => {
        return savedPaymentMethods &&
            savedPaymentMethods[type] &&
            savedPaymentMethods[type].length > 0
            ? true
            : false;
    };

    const deleteSavedPaymentMethod = (
        token: string,
    ): Promise<SavedPaymentMethodsResponse> => {
        return new Promise<SavedPaymentMethodsResponse>((resolve, reject) => {
            if (!isLoggedIn()) {
                reject([]);
                return;
            }
            (async () => {
                const response = await client.mutate({
                    mutation: QL_SAVED_PAYMENT_METHOD_DELETE,
                    fetchPolicy: 'no-cache',
                    variables: {
                        token: token,
                    },
                });
                if (response.data.userDeleteSavedPaymentMethod) {
                    setSavedPaymentMethods(
                        response.data.userDeleteSavedPaymentMethod,
                    );
                    resolve(response.data.userDeleteSavedPaymentMethod);
                } else {
                    reject([]);
                }
            })();
        });
    };

    const verifySMS = (): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const billingPhone =
                checkoutForms.current.billing.current?.values?.phone ?? '';
            const shippingPhone =
                checkoutForms.current.shipping.current?.values?.phone ?? '';

            const shouldOpen =
                checkoutForms.current.billing.current?.values?.smsChecked &&
                checkoutForms.current.shipping.current?.values?.smsChecked &&
                billingPhone.length > 0 &&
                shippingPhone.length > 0 &&
                billingPhone !== shippingPhone;
            resolve(shouldOpen);
        });
    };

    return (
        <CheckoutContext.Provider
            value={{
                orderUpdating,
                updateCustomerInfo,
                updateOrderSMSPhone,
                braintreeClientInstance,
                setBraintreeClientInstance,
                checkoutPaymentToken,
                getCheckoutPaymentToken,
                paymentDetails,
                setPaymentDetails,
                getPaymentDetails,
                clearPaymentDetails,
                submitPayment,
                activeStep,
                setActiveStep,
                handleNext,
                handleBack,
                handleReset,
                canUseApplePay,
                selectedPaymentType,
                setSelectedPaymentType,
                getCheckoutFormRef,
                showBillingForm,
                setShowBillingForm,
                paymentTypeRequiresReview,
                paymentTypeIsDirectSubmit,
                paymentTypeRequiresBilling,
                paymentTypeRequiresShipping,
                paymentTypeRequiresBillingAndShipping,
                paymentTypeShowLegalText,
                paymentTypeShowNewsletterSignup,
                validateFormsAndSubmitPayment,
                verifyAddress,
                verifySMS,
                setPaymentProcessMethod,
                savedPaymentMethods,
                setSavedPaymentMethods,
                getSavedPaymentMethods,
                hasSavedPaymentsForType,
                deleteSavedPaymentMethod,
            }}
        >
            {props.children}
        </CheckoutContext.Provider>
    );
}
