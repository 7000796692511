import { Box } from '@mui/material';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import VideoPlayer from '../../Components/_base/VideoPlayer';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { ExpertReviewClass } from '../../types';
import { ContentDisplay } from '../PageContent/PageContent';
import ExpertReviewsRatingsTable from './ExpertReviewRatingsTable';

const ExpertReviewVideo = ({
    expertReview,
    withVideo = true,
}: {
    expertReview: ExpertReviewClass;
    withVideo?: boolean;
}) => {
    return (
        <>
            {withVideo === true &&
                expertReview.videoImageUrl &&
                expertReview.videoUrl && (
                    <>
                        <VideoPlayer
                            src={expertReview.videoUrl}
                            image={expertReview.videoImageUrl}
                            title={
                                expertReview.content?.title ?? 'Expert Review'
                            }
                            fullWidth
                            imageSize="hq"
                            sx={{ mb: 2 }}
                        />
                    </>
                )}
        </>
    );
};

const ExpertReviewContentWithRatingsTable = (props: {
    expertReview: ExpertReviewClass;
    withVideo?: boolean;
}) => {
    const { expertReview, withVideo = true } = props;
    return (
        <TwoColumnLayout
            reverseLayout={true}
            columnWidth={{
                xs: '1fr',
                md: '5fr 2fr',
            }}
            leftSideComponent={
                <Box>
                    <MobileDevice>
                        <ExpertReviewVideo
                            expertReview={expertReview}
                            withVideo={withVideo}
                        />
                    </MobileDevice>
                    {expertReview.content && (
                        <ContentDisplay
                            content={expertReview.content}
                            withIntro={false}
                        />
                    )}
                </Box>
            }
            rightSideComponent={
                <Box position="sticky" top="1rem">
                    <DesktopAndTabletDevice>
                        <ExpertReviewVideo
                            expertReview={expertReview}
                            withVideo={withVideo}
                        />
                    </DesktopAndTabletDevice>
                    <ExpertReviewsRatingsTable expertReview={expertReview} />
                </Box>
            }
        />
    );
};

export default ExpertReviewContentWithRatingsTable;
