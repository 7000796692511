import { Helmet } from 'react-helmet-async';
import { CustomerReview, Item } from '../../../../../types';

const getSchemaOrgInfo = (item: Item, reviews: CustomerReview[]) => {
    const reviewSchemas = [];

    for (let i = 0; i < reviews.length; i++) {
        const review = reviews[i];
        if (review.isSyndicated) {
            continue;
        }
        reviewSchemas.push({
            '@context': 'https://schema.org/',
            '@type': 'Review',
            itemReviewed: {
                '@type': 'Product',
                image: item.imageUrl,
                name: item.name,
                brand: {
                    '@type': 'Brand',
                    name: item.manufacturerName,
                },
            },
            reviewRating: {
                '@type': 'Rating',
                ratingValue: review.rating,
            },
            name: review.title,
            author: {
                '@type': 'Person',
                name: review.name,
            },
            reviewBody: review.text,
            publisher: {
                '@type': 'Organization',
                name: 'BBQGuys',
            },
        });
    }
    return reviewSchemas;
};

const CustomerReviewsSchemaOrg = (props: {
    item: Item;
    reviews: CustomerReview[];
}) => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(getSchemaOrgInfo(props.item, props.reviews))}
            </script>
        </Helmet>
    );
};

export default CustomerReviewsSchemaOrg;
