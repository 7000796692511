import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../Contexts/DeviceTypeProvider';
import theme from '../../../Theme';
import { Item } from '../../../types';
import { Image } from '../../Image';
import ScContainer from '../../Layout/ScContainer/ScContainer';
import { ProductCardSmall } from '../../ProductCards/CardTypes/Small/ProductCardSmall';
import ProductCarousel, {
    ProductCarouselLoading,
} from '../ProductCarousel/ProductCarousel';
import { findDigitalDataEntry } from '../../../functions';

export default function RecentlyViewedItems(props: {
    size?: 'small' | 'large';
}) {
    const { size = 'large' } = props;
    const { getRecentlyViewedItems, recentlyViewedItems } =
        React.useContext(UserAuth);
    React.useEffect(() => {
        getRecentlyViewedItems();
    }, []);

    const isAllowedPage = () => {
        if (size === 'small') {
            return true;
        }
        const action =
            findDigitalDataEntry(['page', 'attributes', 'action']) ?? '';
        const pages = [
            'brand',
            'category',
            'hubpage',
            'item',
            'search',
            'clearance',
            'todaysdeals',
            'promotions',
        ];
        let show = false;
        pages.forEach(page => {
            if (page === action) {
                show = true;
            }
        });
        return show;
    };

    return (
        <React.Suspense fallback={<ProductCarouselLoading />}>
            {recentlyViewedItems.length > 0 && isAllowedPage() ? (
                <ScContainer className="recently-viewed-items">
                    {size === 'small' ? (
                        <RecentlyViewedItemsSmall
                            items={recentlyViewedItems}
                            title="Your Shopping History. Pick up where you left off."
                        />
                    ) : (
                        <ProductCarousel
                            items={recentlyViewedItems}
                            title="Recently Viewed Items"
                        />
                    )}
                </ScContainer>
            ) : null}
        </React.Suspense>
    );
}

const RecentlyViewedItemsSmall = (props: { items: Item[]; title?: string }) => {
    const { items = [], title = '' } = props;

    const renderItems = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    overflowX: 'scroll',
                    position: 'relative',
                    scrollbarWidth: 'none',
                }}
            >
                {items.map((item: Item, i: number) => {
                    return (
                        <React.Fragment
                            key={'recently-viewed-small-' + item.id}
                        >
                            <ProductCardSmall item={item} />
                        </React.Fragment>
                    );
                })}
            </Box>
        );
    };
    return (
        <ScContainer
            variant="alt-light"
            sx={{
                border: theme.palette.border,
                my: 4,
            }}
        >
            <DesktopAndTabletDevice>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '320px 1fr',
                        gap: 1,
                    }}
                >
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: 1,
                        }}
                    >
                        <Box sx={{ position: 'relative', width: 165 }}>
                            <Image
                                src="https://cdn.shocho.co/sc-site/redesign/people/expert-pointing--shopping-history.png"
                                alt="Eli Manning smiling holding a spatula with his arms crossed"
                                width={180}
                                height={180}
                                sx={{
                                    position: 'absolute',
                                    bottom: 0,
                                    height: 180,
                                }}
                            />
                        </Box>
                        <Typography
                            variant="bold"
                            component="h2"
                            sx={{
                                fontSize: 'medium',
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'end',
                                textAlign: 'right',
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>
                    <Box sx={{ py: 2, pr: 2, overflow: 'hidden' }}>
                        {renderItems()}
                    </Box>
                </Box>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Box sx={{ p: 2 }}>
                    <Typography
                        variant="bold"
                        component="h2"
                        sx={{ fontSize: 'medium', mb: 1 }}
                    >
                        Your Shopping History
                    </Typography>
                    {renderItems()}
                </Box>
            </MobileDevice>
        </ScContainer>
    );
};
