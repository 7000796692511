import { Typography } from '@mui/material';
import React from 'react';

const Asterisk: React.FC<{ required?: boolean }> = ({ required }) => {
    if (required) {
        return (
            <Typography component="span" color="error" sx={{ mr: 0.5 }}>
                *
            </Typography>
        );
    }
    return null;
};

export default Asterisk;
