import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    NativeSelect,
} from '@mui/material';
import theme from '../../../../Theme';
import Asterisk from '../../../../Components/Asterisk';
import { FieldWithLabelProps } from '../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';

export interface SelectWithLabelProps extends FieldWithLabelProps {
    bottomLabel?: string;
    data: any;
}

export const SelectWithLabel = (props: SelectWithLabelProps) => {
    const {
        name,
        id,
        formik,
        label = '',
        bottomLabel = '',
        sx = {},
        autoComplete = '',
        data = [],
        required = true,
        onChange,
        onBlur,
    } = props;
    const white = theme.palette.primary.contrastText;

    return (
        <FormControl
            variant="standard"
            disabled={formik.isSubmitting}
            sx={{ width: '100%' }}
        >
            {bottomLabel === '' ? (
                <Box
                    display="grid"
                    gridTemplateColumns={required ? '.8rem 1fr' : '1fr'}
                    component="span"
                    alignItems="baseline"
                >
                    <Asterisk required={required} />
                    <FormLabel htmlFor={id}>{label}</FormLabel>
                </Box>
            ) : (
                <FormLabel htmlFor={id} sx={{ color: white }}>
                    {label}
                </FormLabel>
            )}
            <NativeSelect
                id={id}
                name={name}
                value={formik.values[name]}
                sx={{ ...sx, mt: '0 !important' }}
                onChange={(event: any) => {
                    if (typeof onChange === 'function') {
                        onChange(event);
                    }
                    formik.setFieldValue(name, event.target.value);
                    formik.handleChange(event);
                }}
                error={formik.touched[name] && Boolean(formik.errors[name])}
                autoComplete={autoComplete}
                onBlur={onBlur}
            >
                <option value=""></option>
                {data?.map((item: any, i: any) => {
                    return (
                        <option key={i} value={item.value ?? ''}>
                            {item.label ?? ''}
                        </option>
                    );
                })}
            </NativeSelect>
            {bottomLabel === '' ? null : (
                <Box
                    display="grid"
                    gridTemplateColumns={required ? '.8rem 1fr' : '1fr'}
                    component="span"
                    alignItems="baseline"
                >
                    <Asterisk required={required} />
                    <FormLabel htmlFor={id} sx={{ pt: 1 }}>
                        {bottomLabel}
                    </FormLabel>
                </Box>
            )}
            {formik.touched[name] && formik.errors[name] ? (
                <FormHelperText error>{formik.errors[name]}</FormHelperText>
            ) : null}
        </FormControl>
    );
};
