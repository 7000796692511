import {
    Box,
    BoxProps,
    Button,
    Chip,
    Link,
    MenuItem,
    SxProps,
    Typography,
} from '@mui/material';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import React, { MouseEvent } from 'react';
import { ProductQtyContext } from '../../Contexts/ProductQtyProvider';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { FieldWithLabel } from '../../Pages/Cart/PageCheckout/Forms/Components/FieldWithLabel';
import { PRIMARY_FONT_FAMILY } from '../../Theme';
import { currency, loadAffirmScript } from '../../functions';
import { Item, Maybe, PricesCustom } from '../../types';
import { Select } from '../_base/BBQGuysComponents';
import { AffirmLogo } from '../_base/icons/icons';
import { FreeShippingText, ItemShippingText, OpenBoxLink } from './ItemInfo';

export const ProLabel = () => {
    return (
        <Typography component="span" color="pro.main" variant="bold">
            [PRO]
        </Typography>
    );
};

export const ProPriceLabel = (props: {
    label?: string;
    sx?: SxProps;
    forceDisplay?: boolean;
    item?: Item;
}) => {
    const { isPro } = React.useContext(UserAuth);
    const {
        label = 'Price',
        sx = {},
        forceDisplay = false,
        item = undefined,
    } = props;

    const showLogo =
        forceDisplay || (item !== undefined && isPro() && item.showProLogo);

    return showLogo ? (
        <Box
            component="span"
            sx={{
                fontFamily: PRIMARY_FONT_FAMILY,
                textTransform: 'uppercase',
                ...sx,
            }}
        >
            {/* <Image src="https://cdn.shocho.co/sc-site/bbqguys/branding/logos/Pro-Price.svg" height={24} /> */}
            <ProLabel /> {label}
        </Box>
    ) : (
        <></>
    );
};

export const screenReaderPrice = (price: string | number) => {
    const priceStr = (price + '').toLocaleLowerCase().replace('$', '');
    // handle 'Call for Pricing' text
    if (priceStr.indexOf('call') > -1) {
        return priceStr;
    }

    const priceSplit = priceStr.split('.');
    const priceDollars = priceSplit[0];
    const priceCents = priceSplit[1];
    return (
        priceDollars +
        ' dollars ' +
        (parseInt(priceCents) > 0 ? 'and ' + priceCents + ' cents' : '')
    );
};

interface PriceProps extends BoxProps {
    price: string | number;
    variant?: 'superscriptCents' | 'standard' | 'currentPrice';
}
interface CurrentPriceProps extends Omit<PriceProps, 'price'> {
    item: Item;
}

export const CurrentPrice = ({
    item,
    variant = 'superscriptCents',
    ...rest
}: CurrentPriceProps) => {
    if (item.isCallForPricing || item.hasCustomPricing) {
        return (
            <Typography
                variant="body1"
                sx={{ fontSize: '0.7em', lineHeight: 2.3 }}
            >
                {item.isCallForPricing ? 'Call For Pricing' : 'Multiple Prices'}
            </Typography>
        );
    }
    return (
        <Price
            variant={variant}
            price={item.pricingFormatted.current}
            aria-label={
                'Current Price ' +
                screenReaderPrice(item.pricingFormatted.current)
            }
            {...rest}
        />
    );
};

export const Price = ({
    price,
    variant = 'superscriptCents',
    ...rest
}: PriceProps): JSX.Element | null => {
    const priceStr = typeof price === 'string' ? price : currency.format(price);

    if (variant == 'standard') {
        return (
            <Box component="span" {...rest}>
                {priceStr}
            </Box>
        );
    } else if (variant !== 'superscriptCents') {
        return (
            <Box component="span" variant={variant} {...rest}>
                {priceStr}
            </Box>
        );
    }

    const priceSplit = priceStr.split('.');
    const priceDollars = priceSplit[0];
    const priceCents = priceSplit[1] || '00';

    if (variant === 'superscriptCents') {
        return (
            <Box component="span" {...rest}>
                <span>{priceDollars}</span>
                <span className="visually-hidden">.</span>
                <Typography variant="priceCents">{priceCents}</Typography>
            </Box>
        );
    } else if (variant === 'standard') {
        return (
            <Box component="span" {...rest}>
                {priceStr}
            </Box>
        );
    }
    return null;
};

export const ListPrice = (props: {
    amount: string | null;
    withText?: boolean;
}) => {
    const { amount, withText = false } = props;
    return (
        <>
            {amount !== null && (
                <>
                    <Typography
                        variant="listPrice"
                        component="span"
                        sx={{ textDecoration: 'line-through' }}
                    >
                        {withText && <span>List:&nbsp;</span>}
                        {amount ? (
                            <>
                                <span id="listPriceLabel" className="sr-only">
                                    {`List Price is ${screenReaderPrice(amount)}`}
                                </span>
                                <span
                                    aria-labelledby="listPriceLabel"
                                    aria-hidden="true"
                                >
                                    {amount}
                                </span>
                            </>
                        ) : (
                            <span
                                dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
                            />
                        )}
                    </Typography>
                </>
            )}
        </>
    );
};

interface CustomPriceFormValues {
    customPrice: string;
}

const CustomPricingInputField = () => {
    const { setCustomPricing, setDisableAddToCartButton } =
        React.useContext(ProductQtyContext);
    const MAX_AMOUNT = '2000.00';
    const INITAL_AMOUNT = '20.00';
    React.useEffect(() => {
        setCustomPricing(parseInt(INITAL_AMOUNT));
    }, []);
    return (
        <Formik
            initialValues={{
                customPrice: INITAL_AMOUNT,
            }}
            validate={(values: CustomPriceFormValues) => {
                const errors: FormikErrors<CustomPriceFormValues> = {};
                if (!/^\d+(\.\d{1,2})?$/.test(values.customPrice)) {
                    errors.customPrice = 'Amount must be a number';
                    setDisableAddToCartButton(true);
                } else {
                    const price = parseFloat(values.customPrice);
                    if (price < 0.01) {
                        errors.customPrice = 'Amount must be greater than zero';
                        setDisableAddToCartButton(true);
                    } else if (!/^\d+(\.\d{1,2})?$/.test(price + '')) {
                        errors.customPrice = 'Amount must be a number';
                        setDisableAddToCartButton(true);
                    } else if (
                        price !== undefined &&
                        price > parseFloat(MAX_AMOUNT)
                    ) {
                        errors.customPrice = `Amount must be less than or equal to $${MAX_AMOUNT}`;
                        setDisableAddToCartButton(true);
                    } else {
                        setDisableAddToCartButton(false);
                    }
                    if (price > 0) {
                        setCustomPricing(price);
                    }
                }
                return errors;
            }}
            onSubmit={(values: CustomPriceFormValues, actions: any) => {
                actions.validate(values);
            }}
        >
            {(formik: FormikProps<CustomPriceFormValues>) => {
                return (
                    <Form autoComplete="on">
                        <FieldWithLabel
                            id="customPrice"
                            name="customPrice"
                            label="Custom Amount"
                            autoComplete="off"
                            formik={formik}
                            touched={true}
                            value={formik.values.customPrice}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                                if (
                                    parseFloat(e.currentTarget.value) >
                                    parseFloat(MAX_AMOUNT)
                                ) {
                                    formik.setFieldValue(
                                        'customPrice',
                                        `${MAX_AMOUNT}`,
                                        true,
                                    );
                                }
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

const CustomPricingSelectField = (props: {
    item: Item;
    selectedValue: string | number;
    handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}) => {
    const {
        item,
        selectedValue,
        handleChange = () => {
            return;
        },
    } = props;
    const {
        qty,
        setQty,
        setMaxQty,
        setDisableAddToCartButton,
        setCustomPricing,
    } = React.useContext(ProductQtyContext);

    const adjustMaxQty = (val: string | number) => {
        // for giftcards, money laundering fraud restricts giftcard purchases of 2k or more so lets handle that
        const MAX_AMT = 2000;
        if (val === 'custom') {
            setMaxQty(10);
            setQty(1);
        } else {
            const AMT = typeof val === 'string' ? parseFloat(val) : val;
            const MAX_QTY = Math.floor(MAX_AMT / AMT);
            setCustomPricing(Math.min(AMT, MAX_AMT));
            setMaxQty(MAX_QTY);
            if (qty * AMT > MAX_AMT) {
                setQty(MAX_QTY);
            }
            setDisableAddToCartButton(false);
        }
    };

    React.useEffect(() => {
        adjustMaxQty(selectedValue);
    }, [selectedValue]);

    return (
        <Select
            value={selectedValue}
            label="Choose Value"
            onChange={handleChange}
        >
            {item.pricingCustom.map((price: Maybe<PricesCustom>, i: number) => {
                return (
                    <MenuItem key={i} value={price?.value}>
                        {price?.label}
                    </MenuItem>
                );
            })}
        </Select>
    );
};

const CustomPricingDisplay = (props: { item: Item }) => {
    const { item } = props;
    const initialPrice = item.pricingCustom[0]?.value ?? 0;
    const [selectedValue, setSelectedValue] = React.useState<number | string>(
        initialPrice,
    );
    const { setCustomPricing } = React.useContext(ProductQtyContext);

    const [showCustomPricingInput, setShowCustomPricingInput] = React.useState(
        initialPrice === 'custom',
    );

    React.useEffect(() => {
        if (parseFloat(initialPrice as string) > 0) {
            setCustomPricing(parseFloat(initialPrice as string));
        }
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(e.target.value);
        if (e.target.value === 'custom') {
            setShowCustomPricingInput(true);
        } else {
            setShowCustomPricingInput(false);
            setCustomPricing(parseFloat(e.target.value));
        }
    };
    if (showCustomPricingInput) {
        return <CustomPricingInputField />;
    }
    return (
        <CustomPricingSelectField
            item={item}
            handleChange={handleChange}
            selectedValue={selectedValue}
        />
    );
};

const Financing = (props: {
    item: Item;
    variant?: 'large' | 'small';
    withMarginBottom?: boolean;
}) => {
    const { item, variant = 'small', withMarginBottom = true } = props;
    const price = item.pricing.current;
    const financingPrice =
        price > 50
            ? item.pricingFormatted.financing.substring(
                  0,
                  item.pricingFormatted.financing.indexOf('.'),
              )
            : '0';
    const ariaLabel =
        'As low as ' +
        screenReaderPrice(financingPrice).replace(';', '') +
        ' per month with approved affirm financing;';
    const handleFinancingClick = (pricing: number) => {
        loadAffirmScript().then(() => {
            const cents = Math.ceil(pricing * 100);
            affirm.ui.openModal({ amount: cents });
        });
    };

    if (
        financingPrice == '0' ||
        item.isCallForPricing ||
        item.hasCustomPricing
    ) {
        return <Box sx={{ height: '1.5rem' }} component="div" />;
    }
    if (variant === 'large') {
        return (
            <Box component="span" sx={{ display: 'flex' }}>
                <Typography
                    id="item-primary-learn-finance"
                    variant="financingText"
                    aria-label={ariaLabel}
                    component="div"
                    sx={{ marginBottom: withMarginBottom ? '32px' : 0 }}
                    tabIndex={0}
                >
                    <span aria-hidden="true">
                        As low as <strong>{financingPrice}</strong>/mo
                        with&nbsp;
                        <AffirmLogo
                            tabIndex={-1}
                            aria-label="affirm"
                            sx={{ verticalAlign: 'inherit' }}
                        />
                        *&nbsp;
                    </span>
                </Typography>
                {variant === 'large' ? (
                    <Box component="span" sx={{ ml: 1 }}>
                        <Button
                            tabIndex={0}
                            variant="link"
                            onClick={() => handleFinancingClick(price)}
                        >
                            Qualify Now
                        </Button>
                    </Box>
                ) : null}
            </Box>
        );
    } else {
        return (
            <Link
                sx={{ fontSize: '.8rem', height: '1.5rem' }}
                href="#"
                underline="always"
                onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                    e.preventDefault();
                    handleFinancingClick(price);
                }}
                aria-label={ariaLabel}
            >
                <DesktopAndTabletDevice>
                    As low as {financingPrice} per month*
                </DesktopAndTabletDevice>
                <MobileDevice>As low as {financingPrice}/mo*</MobileDevice>
            </Link>
        );
    }
};

export const PriceDisplay = (props: {
    item: Item;
    variant?: 'large' | 'medium' | 'small';
    openBoxOnPageLink?: string;
}) => {
    const { item, variant = 'small' } = props;
    const { user } = React.useContext(UserAuth);
    const hasSavings = item.pricing.list > item.pricing.current;
    const savingsPercent = item.pricingFormatted.savingsPercent;
    const hasListPrice =
        item.pricing.list > 0 && item.pricing.list > item.pricing.current;
    const listPrice = item.pricingFormatted.list;
    const hasOpenBox = item.openBoxCount > 0;
    const openBoxPrice = item.pricingFormatted.openBox;
    const unitsCalculated = Math.round(
        item.pricing.current / item.pricing.unit,
    );
    const hasUnitPrice = unitsCalculated > 1;
    let badge = <></>;

    if (
        user.isEmployee &&
        item.pricing.type === 'employee' &&
        item.pricing.current < item.pricing.sale
    ) {
        if (variant === 'large') {
            badge = (
                <Chip
                    variant="outlined"
                    color="info"
                    label="Employee Pricing"
                    size="small"
                />
            );
        } else {
            badge = (
                <Chip
                    variant="outlined"
                    color="info"
                    label="Employee"
                    size="small"
                />
            );
        }
    }

    const renderLarge = () => {
        return (
            <>
                <>
                    {!item.isCallForPricing && !item.hasCustomPricing && (
                        <>
                            <ListPrice
                                amount={hasListPrice ? listPrice : null}
                            />
                            {hasSavings ? (
                                <Typography
                                    variant="youSave"
                                    component="span"
                                    aria-label={
                                        'You save ' + savingsPercent + ' off'
                                    }
                                >
                                    {savingsPercent} off
                                </Typography>
                            ) : null}
                        </>
                    )}
                </>
                <Typography
                    variant="currentPrice"
                    component="div"
                    sx={{ marginTop: '-3px' }}
                >
                    {!item.isCallForPricing && !item.hasCustomPricing ? (
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CurrentPrice item={item} />
                            {badge}
                            <ItemShippingText item={item} withPlusSign={true} />
                        </Box>
                    ) : item.hasCustomPricing ? (
                        <CustomPricingDisplay item={item} />
                    ) : null}
                </Typography>
                {hasUnitPrice &&
                    !item.isCallForPricing &&
                    !item.hasCustomPricing && (
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{ height: '1.5rem' }}
                        >
                            <Price
                                price={item.pricingFormatted.unit}
                                variant="standard"
                            />
                            /ea.
                        </Typography>
                    )}
                <Financing item={item} variant="large" />
                {hasOpenBox ? (
                    <OpenBoxLink
                        itemId={item.id}
                        price={openBoxPrice}
                        onPageLink={props.openBoxOnPageLink}
                    />
                ) : null}
            </>
        );
    };

    const renderMedium = () => {
        return (
            <>
                <>
                    {!item.isCallForPricing && !item.hasCustomPricing && (
                        <>
                            <ListPrice
                                amount={hasListPrice ? listPrice : null}
                            />
                            {hasSavings ? (
                                <Typography
                                    variant="youSave"
                                    component="span"
                                    aria-label={
                                        'You save ' + savingsPercent + ' off'
                                    }
                                >
                                    {savingsPercent} off
                                </Typography>
                            ) : null}
                        </>
                    )}
                </>
                <Typography
                    variant="currentPrice"
                    component="div"
                    sx={{ marginTop: '-3px' }}
                >
                    {!item.isCallForPricing && !item.hasCustomPricing ? (
                        <Box
                            component="span"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                alignContent: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CurrentPrice item={item} />
                            {badge}
                        </Box>
                    ) : item.hasCustomPricing ? (
                        <CustomPricingDisplay item={item} />
                    ) : null}
                </Typography>
                {hasUnitPrice &&
                    !item.isCallForPricing &&
                    !item.hasCustomPricing && (
                        <Typography
                            variant="body1"
                            component="div"
                            sx={{ height: '1.5rem' }}
                        >
                            <Price
                                price={item.pricingFormatted.unit}
                                variant="standard"
                            />
                            /ea.
                        </Typography>
                    )}
                <Financing item={item} variant="large" />
                {hasOpenBox ? (
                    <OpenBoxLink
                        itemId={item.id}
                        price={openBoxPrice}
                        onPageLink={props.openBoxOnPageLink}
                    />
                ) : null}
                <FreeShippingText
                    isFreeShipping={item.isFreeShipping}
                    withPlusSign={false}
                    size="small"
                />
            </>
        );
    };
    const renderSmall = () => {
        return (
            <>
                <Typography variant="currentPrice2" component="div">
                    <Box
                        component="span"
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            alignContent: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CurrentPrice item={item} />
                        <Box component="span" sx={{ marginLeft: '.5rem' }}>
                            {item.isCallForPricing || item.hasCustomPricing ? (
                                <></>
                            ) : (
                                <>
                                    <ListPrice
                                        amount={hasListPrice ? listPrice : null}
                                    />
                                    {/* {hasSavings ? (
                    <Typography variant="youSave" component="span" aria-label={savingsPercent + " off savings"}>&nbsp;({savingsPercent})</Typography>
                  ) : null} */}
                                </>
                            )}
                        </Box>
                    </Box>
                </Typography>

                <Financing item={item} variant="small" />
                <Typography
                    variant="financingText"
                    fontSize=".9375rem"
                    sx={{ height: '1.5rem' }}
                >
                    {hasOpenBox ? (
                        <OpenBoxLink
                            itemId={item.id}
                            price={openBoxPrice}
                            sx={{
                                height: '1.5rem',
                                display: 'inline',
                                fontSize: '.8rem',
                            }}
                        />
                    ) : (
                        <Box sx={{ height: '1.5rem' }} component="div" />
                    )}
                </Typography>
                <FreeShippingText
                    isFreeShipping={item.isFreeShipping}
                    withPlusSign={false}
                    size="small"
                />
            </>
        );
    };

    return (
        <>
            {variant === 'large'
                ? renderLarge()
                : variant === 'medium'
                  ? renderMedium()
                  : renderSmall()}
        </>
    );
};
