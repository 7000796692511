import fetch from 'cross-fetch';
import document from 'global/document';
import * as React from 'react';
import { getEnvironmentVariable } from '../config';
import { Item } from '../types';
import window from 'global/window';

export type HawkSearchProduct = Item & {
    name: string;
    sku: string;
    imageUrl: string;
    imageAlt: string;
    url: string;
};
export type HawkSearchKeywords = {
    url: string;
    value: string;
};
type HawkSearchResults = {
    terms: HawkSearchKeywords[];
    products: HawkSearchProduct[];
};

export const SearchBarContext = React.createContext({
    expandSearchBar: false,
    searchBarFocused: false,
    autocompleteOpen: false,
    searchTerm: '',
    highlightedTermIndex: -1,
    enterClicked: false,
    anchorEl: null as null | HTMLElement,
    searchResults: {} as HawkSearchResults,
    abortController: null as null | AbortController,
    inputRef: null as null | React.RefObject<HTMLInputElement>,
    setAbortController: (val: AbortController) => {
        return;
    },
    setSearchResults: (val: HawkSearchResults) => {
        return;
    },
    setAnchorEl: (val: null | HTMLElement) => {
        return;
    },
    setEnterClicked: (val: boolean) => {
        return;
    },
    setSearchTerm: (val: string) => {
        return;
    },
    setHighlightedTermIndex: (val: number) => {
        return;
    },
    setExpandSearchBar: (val: boolean) => {
        return;
    },
    setSearchBarFocused: (val: boolean) => {
        return;
    },
    setAutocompleteOpen: (val: boolean) => {
        return;
    },
    clearInputBox: () => {
        return;
    },
    openSearchResults: () => {
        return;
    },
    closeSearchResults: () => {
        return;
    },
    triggerSearchAutocomplete: (val: string, updateSuggestedTerms = true) => {
        return;
    },
    triggerSearch: () => {
        return;
    },
});

export function SearchBarProvider(props: { children: any }) {
    const [expandSearchBar, setExpandedValue] = React.useState(false);
    const [searchBarFocused, setSearchBarFocusedValue] = React.useState(false);
    const [autocompleteOpen, setAutocompleteOpenValue] = React.useState(false);
    const [searchTerm, setTerm] = React.useState('');

    const [highlightedTermIndex, setHighlightedTermIndexValue] =
        React.useState<number>(-1);
    const [enterClicked, setEnterClickedValue] = React.useState(false);
    const [anchorEl, setAnchorElValue] = React.useState<null | HTMLElement>(
        document.getElementById('search-bar'),
    );
    const [searchResults, setSearchResultsValue] = React.useState({
        terms: [],
        products: [],
    } as HawkSearchResults);
    const [abortController, setAbortControllerValue] = React.useState(
        new AbortController(),
    );

    const inputRef = React.createRef<HTMLInputElement>();

    const setExpandSearchBar = (val: boolean) => {
        setExpandedValue(val);
    };
    const setSearchBarFocused = (val: boolean) => {
        setSearchBarFocusedValue(val);
    };
    const setAutocompleteOpen = (val: boolean) => {
        setAutocompleteOpenValue(val);
    };
    const setSearchTerm = (val: string) => {
        setTerm(val);
    };
    const setAbortController = (val: AbortController) => {
        setAbortControllerValue(val);
    };
    const setSearchResults = (val: HawkSearchResults) => {
        setSearchResultsValue(val);
    };
    const setAnchorEl = (val: null | HTMLElement) => {
        setAnchorElValue(val);
    };
    const setEnterClicked = (val: boolean) => {
        setEnterClickedValue(val);
    };
    const setHighlightedTermIndex = (val: number) => {
        setHighlightedTermIndexValue(val);
    };

    const clearInputBox = () => {
        setAutocompleteOpen(false);
        setEnterClicked(false);
        setSearchTerm('');
        setExpandSearchBar(false);
        setSearchBarFocused(false);
        setHighlightedTermIndex(-1);
        // if(!isDesktop()){
        //     document.body.style.overflow="";
        // }
    };
    const openSearchResults = () => {
        if (!autocompleteOpen) {
            setAutocompleteOpen(true);
            setExpandSearchBar(true);
            // if(!isDesktop()){
            //     document.body.style.overflow="hidden";
            // }
        }
    };
    const closeSearchResults = () => {
        setAutocompleteOpen(false);
        setExpandSearchBar(false);
        // if(!isDesktop()){
        //     document.body.style.overflow="";
        // }
    };
    const triggerSearch = () => {
        setEnterClicked(true);
        if (inputRef.current) {
            if (abortController) {
                abortController.abort();
            }
            window.location.href =
                '/search?sstring=' + encodeURIComponent(inputRef.current.value);
            inputRef.current.blur();
        }
        clearInputBox();
    };

    const triggerSearchAutocomplete = (
        val: string,
        updateSuggestedTerms = true,
    ) => {
        if (
            val.length > 0 &&
            !enterClicked &&
            !abortController.signal.aborted
        ) {
            if (abortController) {
                abortController.abort();
            }
            const controller = new AbortController();
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                signal: controller.signal,
                body: JSON.stringify({
                    'MIME Type':
                        'application/x-www-form-urlencoded; charset=UTF-8',
                    ClientGuid: getEnvironmentVariable('HAWK_SEARCH_GUID'),
                    Keyword: val,
                    Query: 'include_in_search:1',
                }),
            };
            setAbortController(controller);
            fetch(
                getEnvironmentVariable('HAWK_SEARCH_ENDPOINT'),
                requestOptions,
            )
                .then(response => response.json())
                .then(data => {
                    const popularTerms: HawkSearchKeywords[] =
                        updateSuggestedTerms === false
                            ? searchResults.terms
                            : [];
                    const prods: HawkSearchProduct[] = [];
                    if (val.length === 0 || enterClicked) {
                        setAutocompleteOpen(false);
                    } else {
                        setEnterClicked(false);
                        openSearchResults();
                        if (updateSuggestedTerms && data && data.Popular) {
                            data.Popular.map((c: any) => {
                                popularTerms.push({
                                    url: '/search?sstring=' + c.RawValue,
                                    value: c.Value,
                                } as HawkSearchKeywords);
                            });
                            // make sure we have at least one term
                            if (popularTerms.length === 0) {
                                popularTerms.push({
                                    value: '<b>' + val + '</b>',
                                    url: '/search?sstring=' + val,
                                });
                            }
                        }
                        if (data.ProductCount > 0) {
                            data.Products.map((p: any) => {
                                const url = new URL(p.Url);
                                prods.push({
                                    name: p.ProductName,
                                    sku: p.Sku,
                                    imageUrl: p.Thumb.Url.replace(
                                        '(width:100,height:100)',
                                        '(width:250,height:250)',
                                    ),
                                    imageAlt: p.Thumb.AltText,
                                    url: url.pathname.replace('//', '/'),
                                } as HawkSearchProduct);
                            });
                        }
                    }
                    setSearchResults({ terms: popularTerms, products: prods });
                })
                .catch(err => {
                    console.error(err);
                });
        } else {
            setAutocompleteOpen(false);
        }
    };
    return (
        <SearchBarContext.Provider
            value={{
                inputRef,
                expandSearchBar,
                setExpandSearchBar,
                searchBarFocused,
                setSearchBarFocused,
                autocompleteOpen,
                setAutocompleteOpen,
                searchTerm,
                setSearchTerm,
                highlightedTermIndex,
                setHighlightedTermIndex,
                enterClicked,
                anchorEl,
                searchResults,
                abortController,
                setAbortController,
                setSearchResults,
                setAnchorEl,
                setEnterClicked,
                clearInputBox,
                openSearchResults,
                closeSearchResults,
                triggerSearchAutocomplete,
                triggerSearch,
            }}
        >
            {props.children}
        </SearchBarContext.Provider>
    );
}
