import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AddToCartItem, CartContext } from '../../../Contexts/CartProvider';
import { ProductQtyContext } from '../../../Contexts/ProductQtyProvider';
import { SiteContext } from '../../../Contexts/SiteProvider';
import { TrapModalContext } from '../../../Contexts/TrapModalProvider';
import { AddItemsResponse, Item, OpenBoxItem } from '../../../types';

export type AtcTrackingType = 'carousel';
export type AtcTrackingGroup = string;
export type AddToCartButtonColors = 'primary' | 'success';
type AddToCartButtonProps = {
    items?: AddToCartItem[];
    item?: Item | OpenBoxItem;
    qty?: number;
    parentItemId?: string;
    label?: string;
    withIcon?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    buttonColor?: AddToCartButtonColors;
    variant?: 'text' | 'outlined' | 'contained' | 'standard' | undefined;
    isPrimaryButton?: boolean;
    isItemPackage?: boolean;
    outOfStockOverride?: boolean;
    onItemsAdded?: () => void;
    sx?: SxProps;
    className?: string;
    trackingType?: AtcTrackingType;
    trackingGroup?: AtcTrackingGroup;
    trackingItemOrder?: number;
    showTrapModal?: boolean;
    isWishlist?: boolean;
} & ButtonProps;

const AddToCartButton = styled(LoadingButton)(({ theme }) => ({
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    textAlign: 'center',
    fontWeight: 'bold',
    '&:hover': {
        textDecoration: 'none',
    },
}));

const CallForPricingButton = styled(AddToCartButton)({
    backgroundColor: '#1376a4',
    borderColor: '#1376a4',
    color: 'white !important',
    '&:hover': {
        backgroundColor: '#2791c3',
        borderColor: '#1376a4',
        boxShadow: 'none',
        color: '#fff',
    },
    '&:active': {
        boxShadow: 'inset 0 0.125em 0.25em rgb(0 0 0 / 25%)',
        backgroundColor: '#2791c3',
        borderColor: '#1376a4',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.1875em #419ec9',
        outline: 0,
    },
});

const removeItemsThanCanNotBeAdded = (
    items: AddToCartItem[],
): AddToCartItem[] => {
    return items.filter((itm: AddToCartItem) => !itm.item.isCallForPricing);
};

const AddToCartButtonInner = React.memo(function AddToCartButtonInner(
    props: AddToCartButtonProps,
) {
    const {
        item,
        qty = 1,
        parentItemId,
        label,
        onItemsAdded,
        withIcon = false,
        fullWidth = true,
        isPrimaryButton = false,
        isItemPackage = false,
        outOfStockOverride = false,
        buttonColor = 'primary',
        sx = {},
        variant = 'contained',
        trackingType,
        trackingGroup,
        trackingItemOrder,
        showTrapModal = true,
        onClick,
        isWishlist,
        ...rest
    } = props;
    let { items = [], disabled = false, className = '' } = rest;
    // remove the let variables off rest so we can splat any remaining attributes on the button below
    delete rest.items;
    delete rest.disabled;
    delete rest.className;

    const { phone } = React.useContext(SiteContext);
    const { setOrder, addItemsToCart, itemQtyInCart } =
        React.useContext(CartContext);
    const {
        setItems,
        setQty,
        setDataLoaded,
        setOpen,
        setTrapNetworkError,
        resetTrapModal,
    } = React.useContext(TrapModalContext);
    const { customPricing } = React.useContext(ProductQtyContext);
    const [loading, setLoading] = React.useState(false);
    const [addedToCart, setAddedToCart] = React.useState(false);
    const itm = item as unknown as Item;
    const handleButtonClick = () => {
        handleClick(qty);
    };
    let forceOptionsChooser = false;
    if (typeof itm === 'object') {
        forceOptionsChooser =
            itm.hasCustomPricing &&
            itm.hasCustomPricing === true &&
            !isPrimaryButton;
    }

    const navigate = useNavigate();
    React.useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    className += 'btn-atc btn-addtocart add-to-cart';
    if (isPrimaryButton) {
        className += ' ga-atc-primary';
    } else {
        className += ' ga-atc-secondary';
    }
    if (items.length === 0 && item !== undefined) {
        const itemAtcProps: AddToCartItem = {
            item: item,
            qty: qty,
        } as AddToCartItem;
        if (customPricing > 0) {
            itemAtcProps.customPrice = customPricing;
        }
        items.push(itemAtcProps);
    }

    const handleClick = (qty: number) => {
        setLoading(true);
        if (forceOptionsChooser) {
            navigate(item?.url || '#');
            return;
        }

        // set the qty for each item
        items = items.map((atcItem: AddToCartItem, i: number) => {
            atcItem.qty = qty;
            return atcItem;
        });

        // apply any selected custom pricing (like gift cards have)
        if (customPricing > 0) {
            items = items.map((atcItem: AddToCartItem, i: number) => {
                if (
                    atcItem.item.__typename === 'Item' &&
                    atcItem.item.hasCustomPricing &&
                    atcItem.item.hasCustomPricing === true
                ) {
                    atcItem.customPrice = customPricing;
                }
                return atcItem;
            });
        }

        if (showTrapModal) {
            resetTrapModal();
            if (item !== undefined) {
                setItems([item as Item]);
                setOpen(true);
            } else if (items !== undefined) {
                setItems(
                    removeItemsThanCanNotBeAdded(items).map(
                        (atcItem: AddToCartItem, i: number) =>
                            atcItem.item as Item,
                    ),
                );
                setQty(qty);
                setOpen(true);
            }
        }

        if (parentItemId) {
            items.forEach((atcItem: AddToCartItem, i: number) => {
                atcItem.parentItemId = parentItemId;
            });
        }

        if (isItemPackage) {
            ///send adobe event to fire on each item id click////
            items.forEach((atcItem: AddToCartItem, i: number) => {
                document.body.dispatchEvent(
                    new CustomEvent('productItemPackageItemAdded', {
                        detail: { itemId: atcItem.item.id },
                    }),
                );
            });
        }

        addItemsToCart(removeItemsThanCanNotBeAdded(items))
            .then((response: AddItemsResponse) => {
                showTrapModal && setDataLoaded(true);
                setAddedToCart(true);
                setOrder(response.order);
                if (typeof onItemsAdded == 'function') {
                    onItemsAdded();
                }
                showTrapModal && setDataLoaded(true);
            })
            .catch(err => {
                console.error('There was an error adding to cart', err);
                showTrapModal &&
                    setTrapNetworkError(
                        'There was an error adding to cart. Please try again in a few minutes.',
                    );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    let firstItemPrice,
        firstItemId,
        firstItemParentId = undefined;
    if (items[0] && items[0].item && items[0].item.id) {
        firstItemId = items[0].item.id;
        items[0].qty = qty;
        if (items[0].parentItemId) {
            firstItemParentId = items[0].parentItemId;
        }
        if (customPricing) {
            firstItemPrice = customPricing;
        } else {
            const firstItem = items[0].item as unknown as Item;
            if (typeof firstItem.pricing === 'object') {
                if (firstItem.pricing.unit) {
                    firstItemPrice = firstItem.pricing.unit;
                }
            }
        }
    }

    let isCallForPricing = false,
        outOfStock = false,
        qtyInCart = 0;
    if (!disabled) {
        items.map((atcItem: AddToCartItem) => {
            qtyInCart = itemQtyInCart(atcItem.item.id);
            outOfStock =
                !outOfStockOverride &&
                (outOfStock || atcItem.item.inStock === false);
            isCallForPricing = atcItem.item.isCallForPricing;
            if (outOfStock && !isWishlist) {
                disabled = true;
            } else if (
                atcItem.item.qtyMax > 0 &&
                qtyInCart + atcItem.qty > atcItem.item.qtyMax
            ) {
                disabled = true;
            }
        });
    }
    if (isCallForPricing) {
        const callPhone = phone.replace(/\D/g, '');
        return (
            <CallForPricingButton
                href={`tel:${callPhone}`}
                loading={loading}
                fullWidth={fullWidth}
                disableElevation={true}
                sx={sx}
                className={'btn-atc ' + className}
                data-product-id={firstItemId}
                data-parent-product-group-type={trackingType}
                data-parent-product-group={trackingGroup}
                data-item-order={trackingItemOrder}
                data-ajax-add-to-cart={firstItemId}
                data-per-item-price={firstItemPrice}
                data-qty={qty}
                data-parent-order-item={firstItemParentId}
                {...rest}
            >
                Call For Pricing
            </CallForPricingButton>
        );
    }

    let atcLabel = label;
    if (label === undefined) {
        items = removeItemsThanCanNotBeAdded(items);
        switch (items.length) {
            case 0:
                atcLabel = 'Nothing to add';
                disabled = true;
                break;
            default:
                atcLabel = 'Add to Cart';
                break;
        }

        if (forceOptionsChooser) {
            atcLabel = 'View Options';
        }

        if (addedToCart) {
            atcLabel = 'Added to Cart';
        }

        if (outOfStock) {
            atcLabel = 'Out of Stock';
        }
    }
    return (
        <>
            <AddToCartButton
                onClick={onClick ?? handleButtonClick}
                startIcon={withIcon ? <AddShoppingCart /> : null}
                loading={loading}
                // loadingPosition={inCart ? undefined : "start"}
                variant={variant}
                fullWidth={fullWidth}
                disableElevation={true}
                sx={sx}
                className={className}
                color={buttonColor}
                disabled={(disabled || outOfStock) && !isWishlist}
                data-product-id={firstItemId}
                data-parent-product-group-type={trackingType}
                data-parent-product-group={trackingGroup}
                data-item-order={trackingItemOrder}
                data-ajax-add-to-cart={firstItemId}
                data-per-item-price={firstItemPrice}
                data-qty={qty}
                data-parent-order-item={firstItemParentId}
                {...rest}
            >
                {atcLabel}
            </AddToCartButton>
        </>
    );
});

export default function AddToCart(props: AddToCartButtonProps) {
    return <AddToCartButtonInner {...props} />;
}
