import { useQuery } from '@apollo/client';
import { QL_BANNERS } from '../../../Graphql/queries';
import BannerCampaigns from './BannerCampaigns';

const BannersByUrl = ({ url, query = '' }: { url: string; query?: string }) => {
    const { data, loading } = useQuery(QL_BANNERS, {
        variables: {
            url: url,
            query: query,
        },
    });

    if (loading || data === undefined) {
        return <BannerCampaigns campaigns={[]} />;
    }
    return <BannerCampaigns campaigns={data.bannerCampaigns} />;
};

export default BannersByUrl;
