import { Stack, Typography } from '@mui/material';
import { Box, SxProps, styled } from '@mui/system';
import { DetailedHTMLProps, ImgHTMLAttributes, createRef } from 'react';
import { Link } from 'react-router-dom';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../Contexts/DeviceTypeProvider';
import { getEnvironmentVariable } from '../config';
import { getCdnLink } from '../functions';
import { Item, Maybe } from '../types';

export interface ImageProps
    extends DetailedHTMLProps<
        ImgHTMLAttributes<HTMLImageElement>,
        HTMLImageElement
    > {
    src?: string;
    alt?: string;
    width?: number | string;
    height?: number | string;
    fullWidth?: boolean;
    sx?: SxProps;
    lazyLoadImage?: boolean;
    center?: boolean;
    rounded?: boolean;
    onClick?: () => void;
    href?: string;
    ref?: React.RefObject<HTMLImageElement>;
    useExactUrl?: boolean;
    hoverSrc?: string;
}

const BBQImage = styled('img')({});

const DEFAULT_IMG_SIZE = 120;

export const Image = (props: ImageProps) => {
    let { sx, width = DEFAULT_IMG_SIZE, height = DEFAULT_IMG_SIZE } = props;
    const {
        src = getEnvironmentVariable('NO_IMAGE_URL'),
        alt = '',
        hoverSrc,
        href,
        fullWidth = false,
        lazyLoadImage = true,
        ref = null,
        center = false,
        rounded = false,
        useExactUrl = false,
        ...remainingProps
    } = props;
    const imgref = ref !== null ? ref : createRef<HTMLImageElement>();
    if (width != height && height === DEFAULT_IMG_SIZE) {
        height = width;
    }
    if (width != height && width === DEFAULT_IMG_SIZE) {
        width = height;
    }

    const cdnSrc = useExactUrl ? src : getCdnLink(src as string, width, height);
    const hoverCdnSrc =
        useExactUrl || typeof hoverSrc !== 'string'
            ? hoverSrc
            : getCdnLink(hoverSrc as string, width, height);
    if (fullWidth) {
        sx = {
            ...sx,
            width: fullWidth ? '100%' : width,
            height: fullWidth ? 'auto' : height,
        };
    }
    if (center) {
        sx = { ...sx, display: 'block', margin: '0 auto' };
    }
    if (rounded) {
        sx = { ...sx, borderRadius: '5px' };
    }
    if (typeof remainingProps.onClick === 'function' || href) {
        sx = { ...sx, cursor: 'pointer' };
    }

    const handleMouseIn = () => {
        if (hoverCdnSrc) {
            imgref.current?.setAttribute('width', imgref.current?.width + 'px');
            imgref.current?.setAttribute(
                'height',
                imgref.current?.height + 'px',
            );
            imgref.current?.setAttribute('src', hoverCdnSrc);
        }
    };
    const handleMouseOut = () => {
        if (hoverCdnSrc) {
            imgref.current?.setAttribute('src', cdnSrc);
        }
    };

    const img = (
        <>
            <BBQImage
                loading={lazyLoadImage ? 'lazy' : 'eager'}
                src={cdnSrc}
                alt={alt || ''}
                width={width}
                height={height}
                ref={imgref}
                onError={() => {
                    if (imgref.current) {
                        imgref.current.onerror = null; // prevents looping
                        imgref.current.src =
                            getEnvironmentVariable('NO_IMAGE_URL');
                    }
                    return true;
                }}
                sx={sx}
                {...remainingProps}
                {...(hoverCdnSrc && { onMouseOver: () => handleMouseIn() })}
                {...(hoverCdnSrc && { onMouseOut: () => handleMouseOut() })}
            />
            {hoverCdnSrc && (
                <link rel="preload" as="image" href={hoverCdnSrc} />
            )}
        </>
    );
    if (href) {
        return (
            <Link target="_blank" to={href}>
                {img}
            </Link>
        );
    }
    return img;
};

export const MultiItemImageSet = (props: {
    items: Maybe<Item>[];
    resolution?: number;
}) => {
    const { items, resolution } = props;

    return (
        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
            {items.map((item: Maybe<Item>, i: number) => {
                return (
                    item && (
                        <Stack
                            key={'multi-image-' + i}
                            direction="row"
                            alignItems="center"
                        >
                            <DesktopAndTabletDevice>
                                {i > 0 && (
                                    <Typography
                                        variant="bold"
                                        sx={{ fontSize: '2rem', mx: 1 }}
                                    >
                                        +
                                    </Typography>
                                )}
                                <Image
                                    src={item.imageUrl}
                                    alt={item.name}
                                    width={resolution ?? 120}
                                    height={resolution ?? 120}
                                />
                            </DesktopAndTabletDevice>
                            <MobileDevice>
                                <Image
                                    src={item.imageUrl}
                                    alt={item.name}
                                    width={resolution ?? 90}
                                    height={resolution ?? 90}
                                />
                            </MobileDevice>
                        </Stack>
                    )
                );
            })}
        </Stack>
    );
};

export const GridItemImageSet = (props: {
    items: Maybe<Item>[];
    numRows?: number;
    resolution?: number;
}) => {
    let { items, resolution, numRows } = props;
    items = items ?? [];
    numRows = numRows ?? items.length;
    resolution = resolution ?? 90;
    const precision = 1000;
    const flexWidth = Math.floor((100 / numRows) * precision) / precision;

    return (
        <Box
            sx={{
                mb: 2,
                display: 'flex',
                flexWrap: 'wrap',
                marginLeft: '-0.25rem',
                marginRight: '-0.25rem',
            }}
        >
            {items.map((item: Maybe<Item>, i: number) => {
                return (
                    item && (
                        <Box
                            sx={{
                                flex: '0 1 ' + flexWidth + '%',
                                paddingLeft: '0.25rem',
                                paddingRight: '0.25rem',
                            }}
                            key={'multi-image-' + i}
                        >
                            <Image
                                src={item.imageUrl}
                                alt={item.name}
                                width={resolution}
                                height={resolution}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </Box>
                    )
                );
            })}
        </Box>
    );
};
