import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { Dialog, Link, Typography } from '@mui/material';
import { Box, SxProps, Theme, styled } from '@mui/system';
import window from 'global/window';
import PropTypes from 'prop-types';
import * as React from 'react';
import { findAncestor } from '../../functions';
import { Image } from '../Image';
import Modal, { CloseIconButton } from './BBQGuysModal';
import CloseIcon from '@mui/icons-material/Close';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';

const StyledVideoPlayLabel = styled(Box)(({ theme }: { theme: Theme }) => ({
    backgroundColor: 'rgba(0 0 0 / 75%)',
    borderTopLeftRadius: '3em',
    borderBottomLeftRadius: '3em',
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(0.75),
    position: 'absolute',
    right: 0,
    bottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backdropFilter: 'blur(0.25em)',
    boxShadow: 'inset 0 0 0 1px rgb(0 0 0)',
}));

export const YoutubeDetect = () => {
    const [youtubeLink, setYoutubeLink] = React.useState(null);

    window.onclick = (e: Event) => {
        let href;
        const t = e.target as any;
        const target =
            t.tagName && t.tagName === 'A' ? t : (findAncestor(t, 'a') as any);
        if (target !== null && target.tagName === 'A') {
            href = target.getAttribute('href');

            if (href) {
                const regExp =
                    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
                const match = href.match(regExp);
                if (match && match[2].length == 11) {
                    //tell the browser not to respond to the link click
                    e.preventDefault();

                    setYoutubeLink(href);
                }
            }
        }
    };

    return youtubeLink !== null ? (
        <YoutubeEmbed
            url={youtubeLink}
            open={youtubeLink !== null}
            onClose={() => {
                setYoutubeLink(null);
            }}
        />
    ) : (
        <></>
    );
};

const YoutubeVideo = (props: { url: string }) => {
    return (
        <Box
            component="iframe"
            width="853"
            height="480"
            src={props.url}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            sx={{
                width: {
                    xs: '100%',
                    md: 853,
                },
                height: {
                    xs: 'auto',
                    md: 480,
                },
                backgroundColor: '#282828',
                border: 0,
                outline: 0,
            }}
        />
    );
};

export const YoutubeEmbed = (props: {
    url: string;
    open?: boolean;
    onClose?: () => void;
}) => {
    const {
        open = false,
        onClose = () => {
            return;
        },
    } = props;
    let { url } = props;
    if (url.indexOf('?v=') > -1) {
        url =
            'https://www.youtube.com/embed/' +
            url.substr(url.indexOf('v=') + 2);
    }
    url +=
        (url.indexOf('?') > 0 ? '&' : '?') +
        'autoplay=1&playsinline=1&controls=0&modestbranding=1&rel=0&showinfo=0';
    const [openDialog, setOpenDialog] = React.useState(open);
    const handleCloseVideoPlayer = () => {
        setOpenDialog(false);
        if (typeof onClose === 'function') {
            onClose();
        }
    };
    return (
        <>
            <MobileDevice>
                <Modal
                    open={openDialog}
                    handleClose={handleCloseVideoPlayer}
                    title={'Watch Video'}
                    customHeight={'60vh'}
                >
                    <YoutubeVideo url={url} />
                </Modal>
            </MobileDevice>
            <DesktopAndTabletDevice>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseVideoPlayer}
                    // fullScreen={fullScreen}
                    maxWidth={'lg'}
                    sx={{
                        p: 0,
                        overflow: 'visible !important',
                        overflowY: 'visible !important',
                    }}
                    PaperProps={{
                        sx: {
                            border: 0,
                            overflow: 'visible !important',
                            overflowY: 'visible !important',
                        },
                    }}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                >
                    <CloseIconButton
                        size="medium"
                        aria-label="close"
                        onClick={handleCloseVideoPlayer}
                        sx={{
                            right: '-2em',
                            top: '-2em',
                        }}
                    >
                        <CloseIcon
                            sx={{
                                fill: 'white',
                                width: '1.5em',
                                height: '1.5em',
                            }}
                        />
                    </CloseIconButton>
                    <YoutubeVideo url={url} />
                </Dialog>
            </DesktopAndTabletDevice>
        </>
    );
};

YoutubeEmbed.propTypes = {
    url: PropTypes.string.isRequired,
};

export type YoutubeImageSizes = 'hq' | 'maxres';

export const getYoutubeVideoImageString = (type: YoutubeImageSizes) => {
    switch (type) {
        case 'hq':
            return 'hqdefault.jpg';
        default:
            return 'maxresdefault.jpg';
    }
};
export const getYoutubeVideoReplacedString = (type: YoutubeImageSizes) => {
    switch (type) {
        case 'hq':
            return getYoutubeVideoImageString('maxres');
        default:
            return getYoutubeVideoImageString('hq');
    }
};

export default function VideoPlayer(props: {
    src: string;
    image: string;
    title?: string;
    width?: number | string | undefined;
    height?: number | string | undefined;
    fullWidth?: boolean;
    showIcon?: boolean;
    sx?: SxProps;
    imageSize?: YoutubeImageSizes;
}) {
    const {
        src,
        image,
        title = '',
        fullWidth = true,
        width = '100%',
        height = 260,
        showIcon = true,
        sx = {},
        imageSize = 'maxres',
    } = props;

    return (
        <Link
            href={src}
            sx={{
                position: 'relative',
                display: 'flex',
                height: 'auto',
                width: '100%',
                minHeight: 100,
                ...sx,
            }}
        >
            <Image
                src={
                    image.replace(
                        getYoutubeVideoReplacedString(imageSize),
                        getYoutubeVideoImageString(imageSize),
                    ) as string
                }
                alt={title + (title.indexOf('video') === -1 ? ' Video' : '')}
                height={height}
                width={width}
                fullWidth={fullWidth}
                sx={{
                    borderRadius: 1,
                    objectFit: 'cover',
                    aspectRatio: '16/9',
                    width: '100%',
                    height: 'auto',
                }}
            />
            <StyledVideoPlayLabel>
                {showIcon && (
                    <PlayCircleFilledIcon
                        color="primary"
                        sx={{
                            backgroundColor: 'white',
                            mr: 1,
                            borderRadius: '50%',
                        }}
                    />
                )}
                <Typography variant="button" color="white">
                    Play Video
                </Typography>
            </StyledVideoPlayLabel>
        </Link>
    );
}
