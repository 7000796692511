import { WarningAmberTwoTone } from '@mui/icons-material';
import { LinkProps } from '@mui/material/Link';
import {
    PaletteColor,
    PaletteColorOptions,
    SxProps,
    createTheme,
    responsiveFontSizes,
} from '@mui/material/styles';
import React from 'react';
import LinkBehavior from './LinkBehavior';

export const PRIMARY_FONT_FAMILY =
    "'Archivo',ui-sans-serif,'Helvetica Neue',Helvetica,Arial,sans-serif";
export const SECONDARY_FONT_FAMILY =
    "'Krub',system-ui,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji'";

// Use the following lines to define a new Typography variant, then add it below under `typography` to style it

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        standard: true;
        link: true;
        quickView: true;
        mainMenuLink: true;
        pro: true;
    }
}
declare module '@mui/material/Alert' {
    interface AlertPropsVariantOverrides {
        text: true;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        pro: PaletteColor;
        brand: {
            emberorange: string;
            goldenyellow: string;
            promored: string;
            brightred: string;
            darkblue: string;
            darkgreen: string;
            darkorange: string;
            brightgreen: string;
            lightgreen: string;
            mediumtan: string;
            lighttan: string;
            lightesttan: string;
            lightorange: string;
            lightestorange: string;
            offwhite: string;
            charcoalgray: string;
            charcoalblack: string;
        };
        border: string;
        panel: {
            header: string;
            body: string;
        };
        orange: PaletteColor;
        lightGrey: PaletteColor;
    }
    interface PaletteOptions {
        pro: PaletteColorOptions;
        brand: {
            emberorange: string;
            goldenyellow: string;
            promored: string;
            brightred: string;
            darkblue: string;
            darkgreen: string;
            darkorange: string;
            brightgreen: string;
            lightgreen: string;
            mediumtan: string;
            lighttan: string;
            lightesttan: string;
            lightorange: string;
            lightestorange: string;
            offwhite: string;
            charcoalgray: string;
            charcoalblack: string;
        };
        border: string;
        panel: {
            header: string;
            body: string;
        };
        orange?: PaletteColorOptions;
        lightGrey?: PaletteColorOptions;
    }
    interface TypographyVariants {
        bold: React.CSSProperties;
        italic: React.CSSProperties;
        title: React.CSSProperties;
        subtitle: React.CSSProperties;
        pageTitle: SxProps;
        itemPageMeta: React.CSSProperties;
        linkColor: React.CSSProperties;
        listPrice: React.CSSProperties;
        priceCents: React.CSSProperties;
        currentPrice: React.CSSProperties;
        currentPrice2: React.CSSProperties;
        financingText: React.CSSProperties;
        financingPrice: React.CSSProperties;
        youSave: React.CSSProperties;
        freeShipping: React.CSSProperties;
        freeShippingSmall: React.CSSProperties;
        verifiedPurchaser: React.CSSProperties;
        inStock: React.CSSProperties;
        sectionTitle: React.CSSProperties;
        orderNumber: React.CSSProperties;
        muted: React.CSSProperties;
        mutedEmphasis: React.CSSProperties;
        popoverTitle: React.CSSProperties;
        outOfStock: React.CSSProperties;
        keyFeatureBullet: React.CSSProperties;
        green: React.CSSProperties;
        secondaryMenu: React.CSSProperties;
        mainMenuLevel1: React.CSSProperties;
        mainMenuLevel2: React.CSSProperties;
        mainMenuLevel3: React.CSSProperties;
        departmentChildTitle: React.CSSProperties;
        footerTitle: React.CSSProperties;
        small: React.CSSProperties;

        xsmall: React.CSSProperties;
        promoText: React.CSSProperties;
        screenReaderOnly: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        bold?: React.CSSProperties;
        italic?: React.CSSProperties;
        title?: React.CSSProperties;
        subtitle?: React.CSSProperties;
        pageTitle?: SxProps;
        itemPageMeta?: React.CSSProperties;
        linkColor?: React.CSSProperties;
        listPrice?: React.CSSProperties;
        priceCents?: React.CSSProperties;
        currentPrice?: React.CSSProperties;
        currentPrice2?: React.CSSProperties;
        financingText?: React.CSSProperties;
        financingPrice?: React.CSSProperties;
        youSave?: React.CSSProperties;
        freeShipping?: React.CSSProperties;
        freeShippingSmall?: React.CSSProperties;
        verifiedPurchaser?: React.CSSProperties;
        inStock?: React.CSSProperties;
        sectionTitle?: React.CSSProperties;
        orderNumber?: React.CSSProperties;
        muted?: React.CSSProperties;
        mutedEmphasis?: React.CSSProperties;
        popoverTitle?: React.CSSProperties;
        outOfStock?: React.CSSProperties;
        keyFeatureBullet?: React.CSSProperties;
        green?: React.CSSProperties;
        secondaryMenu?: React.CSSProperties;
        mainMenuLevel1?: React.CSSProperties;
        mainMenuLevel2?: React.CSSProperties;
        mainMenuLevel3?: React.CSSProperties;
        footerTitle?: React.CSSProperties;
        small?: React.CSSProperties;
        xsmall?: React.CSSProperties;
        promoText?: React.CSSProperties;
        screenReaderOnly?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        noUnderline: true;
        bold: true;
        italic: true;
        title: true;
        subtitle: true;
        pageTitle: true;
        itemPageMeta: true;
        linkColor: true;
        listPrice: true;
        priceCents: true;
        currentPrice: true;
        currentPrice2: true;
        financingText: true;
        financingPrice: true;
        youSave: true;
        freeShipping: true;
        freeShippingSmall: true;
        verifiedPurchaser: true;
        inStock: true;
        sectionTitle: true;
        orderNumber: true;
        muted: true;
        mutedEmphasis: true;
        popoverTitle: true;
        outOfStock: true;
        keyFeatureBullet: true;
        green: true;
        secondaryMenu: true;
        mainMenuLevel1: true;
        mainMenuLevel2: true;
        mainMenuLevel3: true;
        footerTitle: true;

        small: true;
        xsmall: true;
        promoText: true;

        customerServiceLinks: true;

        screenReaderOnly: true;
    }
}

let theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#cc4d02',
            light: '#e86412',
            dark: '#bb4902',
            contrastText: '#fff',
        },
        secondary: {
            main: '#42484d',
            light: '#636d76',
            dark: '#282b2d',
            contrastText: '#faf6f2',
        },
        text: {
            primary: '#282b2d',
            secondary: '#63676d',
            disabled: '#babec1',
        },
        info: {
            main: '#1376a4',
            light: '#33b4e8',
            dark: '#21546d',
            contrastText: '#fff',
        },
        grey: {
            50: '#fffdfb',
            100: '#f7f5f3',
            200: '#edf0f1',
            300: '#d7dbdd',
            400: '#babec1',
            500: '#84888d',
            600: '#707377',
            700: '#636d76',
            800: '#42484d',
            900: '#282b2d',
            A100: '#f7f5f3',
            A200: '#edf0f1',
            A400: '#babec1',
            A700: '#63676d',
        },
        contrastThreshold: 4.5, // WCAG2 AA
        background: {
            paper: '#fff',
        },
        pro: {
            main: '#1167b1',
            light: '#00bdd8',
            dark: '#003d72',
            contrastText: '#fff',
        },
        panel: {
            header: '#edf0f1',
            body: '#fbfdff',
        },
        brand: {
            emberorange: '#e86412',
            goldenyellow: '#f4a128',
            promored: '#ba0c2f',
            brightred: '#f83d34',
            darkblue: '#272749',
            darkgreen: '#006500',
            darkorange: '#bb4902',
            brightgreen: '#267038',
            lightgreen: '#add171',
            mediumtan: '#dac4a7',
            lighttan: '#f2e8dc',
            lightesttan: '#faf6f2',
            lightorange: 'rgba(232, 100, 18, .2)',
            lightestorange: 'rgba(238, 99, 12, .1)',
            offwhite: '#fdf9f5',
            charcoalgray: '#333',
            charcoalblack: '#171717',
        },
        divider: '#ccc',
        border: '1px solid #ccc',
    },
    typography: {
        fontFamily: [SECONDARY_FONT_FAMILY].join(','),
        fontSize: 16,
        fontWeightRegular: 500, // Assign medium weight for using 'Krub/500' as default
        htmlFontSize: 16,
        h1: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: '2rem',
            letterSpacing: '-0.0375em',
        },
        h2: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: '1.8125rem',
            letterSpacing: '-0.01875em',
        },
        h3: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: '1.625rem',
            letterSpacing: '-0.01875em',
        },
        h4: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: '1.4375rem',
        },
        h5: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: '1.25rem',
        },
        h6: {
            fontFamily: PRIMARY_FONT_FAMILY,
            fontSize: '1.125rem',
        },
        subtitle1: {
            fontWeight: 500,
        },
        subtitle2: {
            fontSize: '0.875rem',
            fontWeight: 700,
        },
        body1: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 500,
        },
        button: {
            fontFamily: PRIMARY_FONT_FAMILY,
            textTransform: 'none',
        },
        caption: {
            fontSize: '0.8125rem',
        },
        overline: {
            fontFamily: PRIMARY_FONT_FAMILY,
            letterSpacing: '0.375em',
        },
        screenReaderOnly: {
            visibility: 'hidden',
            padding: 0,
            margin: 0,
            height: 0,
        },
    },
    shape: {
        borderRadius: 3,
    },
});

// Assign and set a typographic scale (major second: 1.125)
theme = responsiveFontSizes(theme, { factor: 1.125 });

theme.typography.mainMenuLevel2 = {
    fontFamily: theme.typography.h1.fontFamily,
};

theme.typography.bold = {
    fontFamily: theme.typography.h1.fontFamily,
};

theme.typography.italic = {
    fontStyle: 'italic',
};

theme.typography.small = {
    fontSize: theme.typography.caption.fontSize,
};

theme.typography.muted = {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body2.fontSize,
};

theme.typography.mutedEmphasis = {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'italic',
};

theme.typography.title = {
    fontFamily: theme.typography.h1.fontFamily,
    fontSize: theme.typography.h1.fontSize,
    [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h3.fontSize,
        lineHeight: '1',
    },
};

theme.typography.subtitle = {
    fontFamily: theme.typography.h4.fontFamily,
    fontSize: theme.typography.h4.fontSize,
    lineHeight: '1',
    [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h5.fontSize,
    },
};
theme.typography.currentPrice = {
    fontSize: theme.typography.h2.fontSize,
    whiteSpace: 'nowrap',
};
theme.typography.popoverTitle = {
    backgroundColor: theme.palette.brand.lightesttan,
    borderBottom: '1px solid rgba(0,0,0,.2)',
    display: 'block',
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    padding: theme.spacing(1, 6, 1, 2),
};

// Item Page

theme.typography.pageTitle = {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    lineHeight: '1.2',
    [theme.breakpoints.only('xs')]: {
        ...theme.typography.h6,
    },
    [theme.breakpoints.only('sm')]: {
        ...theme.typography.h5,
    },
    [theme.breakpoints.up('md')]: {
        ...theme.typography.h4,
    },
};

theme.typography.itemPageMeta = {
    marginTop: 0,
    marginBottom: theme.spacing(4),
    whiteSpace: 'nowrap',
};

theme.typography.linkColor = {
    color: theme.palette.secondary.main,
};

theme.typography.listPrice = {
    color: theme.palette.grey[600],
    fontSize: theme.typography.caption.fontSize,
    verticalAlign: 'super',
    whiteSpace: 'nowrap',
};

theme.typography.priceCents = {
    fontSize: '0.5625em',
    fontWeight: theme.typography.fontWeightBold,
    verticalAlign: 'super',
    whiteSpace: 'nowrap',
};

theme.typography.currentPrice2 = {
    fontSize: theme.typography.h3.fontSize,
    whiteSpace: 'nowrap',
};

theme.typography.financingText = {
    fontSize: theme.typography.body1.fontSize,
    whiteSpace: 'nowrap',
};

theme.typography.financingPrice = {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.brand.promored,
    fontWeight: theme.typography.fontWeightBold,
};

theme.typography.youSave = {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.brand.promored,
    marginLeft: theme.spacing(2),
    verticalAlign: 'super',
    whiteSpace: 'nowrap',
};

theme.typography.freeShippingSmall = {
    color: theme.palette.brand.brightgreen,
    fontSize: theme.typography.caption.fontSize,
    whiteSpace: 'nowrap',
};

theme.palette.orange = {
    main: '',
    light: '#faf6f2',
    dark: '#cc4d02',
    contrastText: '',
};
theme.palette.lightGrey = {
    main: '#eeeeee',
    dark: '#bdbdbd',
    light: '',
    contrastText: '',
};

theme.typography.freeShipping = {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.brand.brightgreen,
    fontWeight: theme.typography.fontWeightBold,
    whiteSpace: 'nowrap',
};

theme.typography.verifiedPurchaser = {
    backgroundColor: '#e5f1d2',
    border: '0.0625rem solid rgba(45, 132, 66, .2)',
    borderRadius: '50em',
    color: '#2d8442',
    display: 'inline-block',
    fontSize: '.75em',
    fontWeight: theme.typography.fontWeightBold,
    padding: theme.spacing(0, 3),
    textTransform: 'uppercase',
};

theme.typography.keyFeatureBullet = {
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.brand.brightgreen,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
};

theme.typography.green = {
    color: theme.palette.brand.brightgreen,
    fontWeight: theme.typography.fontWeightBold,
};

theme.typography.inStock = {
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightBold,
};

theme.typography.outOfStock = {
    color: theme.palette.brand.promored,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.fontWeightBold,
};

theme.typography.sectionTitle = {
    display: 'inline-block',
    fontFamily: theme.typography.h2.fontFamily,
    fontSize: theme.typography.h2.fontSize,
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('md')]: {
        fontSize: theme.typography.h4.fontSize,
        marginBottom: 0,
    },
};

theme.typography.orderNumber = {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
};

theme.typography.footerTitle = {
    color: theme.palette.secondary.main,
    display: 'block',
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    margin: theme.spacing(0.5, 0),
};

theme.typography.xsmall = {
    fontSize: '0.625rem',
};

theme.typography.promoText = {
    color: theme.palette.brand.promored,
    fontWeight: theme.typography.fontWeightBold,
};

theme.components = {
    MuiAlert: {
        defaultProps: {
            iconMapping: {
                warning: <WarningAmberTwoTone fontSize={'small'} />,
            },
        },
        styleOverrides: {
            outlined: {
                outline: 0,
                border: 0,
                padding: theme.spacing(0, 1),
                // color: theme.palette.text.primary
            },
        },
        variants: [
            {
                props: { variant: 'text' },
                style: {
                    padding: 0,
                    border: 0,
                    '.MuiAlert-icon': {
                        padding: 0,
                        marginRight: 5,
                        svg: {
                            width: 18,
                            height: 18,
                            alignSelf: 'center',
                        },
                    },
                    '.MuiAlert-message': {
                        padding: 0,
                    },
                },
            },
            {
                props: { variant: 'text', severity: 'error' },
                style: {
                    color: theme.palette.error.main,
                },
            },
            {
                props: { variant: 'text', severity: 'warning' },
                style: {
                    color: theme.palette.text.primary,
                    '.MuiAlert-icon': {
                        color: theme.palette.warning.dark,
                        svg: {
                            fill: theme.palette.text.primary,
                        },
                    },
                },
            },
            {
                props: { variant: 'text', severity: 'info' },
                style: {
                    color: theme.palette.info.main,
                },
            },
        ],
    },
    MuiBreadcrumbs: {
        styleOverrides: {
            separator: {
                marginRight: 4,
                marginLeft: 0,
                width: '1.5rem',
            },
            ol: {
                flexDirection: 'row',
                flexWrap: 'nowrap',
                margin: theme.spacing(1, 0),
                overflowX: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                whiteSpace: 'nowrap',
                width: '100%',
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            },
        },
    },
    MuiDialogTitle: {
        styleOverrides: {
            root: {
                backgroundColor: theme.palette.brand.lightesttan,
                borderBottom: theme.palette.border,
                padding: theme.spacing(1),
                display: 'grid',
                gridTemplateColumns: '1fr 44px',
                alignItems: 'center',
                fontSize: theme.typography.h5.fontSize,
            },
        },
    },
    MuiDialogContent: {
        styleOverrides: {
            root: {
                padding: theme.spacing(4),
            },
        },
    },
    MuiDialogActions: {
        styleOverrides: {
            root: {
                [theme.breakpoints.up('md')]: {
                    backgroundColor: theme.palette.brand.lightesttan,
                    borderTop: theme.palette.border,
                },
                padding: theme.spacing(1),
                '&> button': {
                    paddingLeft: theme.spacing(3),
                    paddingRight: theme.spacing(3),
                },
            },
        },
    },
    MuiPaper: {
        defaultProps: {
            elevation: 0,
        },
        styleOverrides: {
            root: {
                border: `1px solid ${theme.palette.divider}`,
            },
        },
    },
    MuiTableCell: {
        styleOverrides: {
            root: {
                fontSize: '1rem',
            },
        },
    },
    MuiAppBar: {
        defaultProps: {
            elevation: 1,
        },
        styleOverrides: {
            root: {
                border: 0,
                padding: 0,
            },
        },
    },
    MuiChip: {
        styleOverrides: {
            root: {
                fontWeight: theme.typography.fontWeightBold,
                height: 'auto',
            },
        },
        variants: [
            {
                props: { color: 'info' },
                style: {
                    color: theme.palette.info.contrastText,
                },
            },
            {
                props: { color: 'warning' },
                style: {
                    color: theme.palette.secondary.contrastText,
                },
            },
            {
                props: { color: 'secondary' },
                style: {
                    color: theme.palette.secondary.contrastText,
                },
            },
            {
                props: { color: 'success', size: 'small' },
                style: {
                    marginTop: theme.spacing(0.75),
                    padding: 0,
                },
            },
            {
                props: { size: 'small' },
                style: {
                    fontSize: theme.typography.caption.fontSize,
                    fontWeight: theme.typography.fontWeightRegular,
                },
            },
        ],
    },
    MuiSelect: {
        styleOverrides: {
            select: {
                background: theme.palette.background.default,
            },
        },
    },
    MuiSvgIcon: {
        styleOverrides: {
            root: {
                width: 'auto',
            },
        },
    },
    MuiLink: {
        defaultProps: {
            underline: 'always',
            // component: LinkBehavior,
        } as LinkProps,
        styleOverrides: {
            root: {
                color: theme.palette.secondary.main, //'#21546d'

                textDecoration: 'underline',

                '> svg': {
                    fill: theme.palette.secondary.main,
                    color: theme.palette.secondary.main,
                    fontSize: 'inherit',
                    width: theme.spacing(2),
                    height: theme.spacing(2),
                },
                '&:focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 0.1875rem rgba(255, 138, 51, 0.8)`,
                },
            },
        },
        variants: [
            {
                props: { variant: 'customerServiceLinks' },
                style: {
                    textAlign: 'center',
                    backgroundColor: 'white',
                    padding: '0.75em',
                    color: '#1376a4',
                },
            },
            {
                props: { variant: 'noUnderline' },
                style: {
                    textDecoration: 'none',
                },
            },
        ],
    },
    MuiListItem: {
        styleOverrides: {
            root: {
                ':hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    MuiFormControl: {
        styleOverrides: {
            root: {
                marginBottom: theme.spacing(1),
            },
        },
    },
    MuiFormLabel: {
        styleOverrides: {
            root: {
                color: theme.palette.secondary.light,
                fontSize: theme.typography.body2.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                padding: theme.spacing(1, 0, 0),
                display: 'flex',
                alignItems: 'flex-start',
                '&> em': {
                    fontWeight: theme.typography.fontWeightRegular,
                },
                '&> a': {
                    float: 'right',
                    fontWeight: theme.typography.fontWeightRegular,
                },
                '&.Mui-error': {
                    color: theme.palette.secondary.light,
                },
            },
        },
    },
    MuiFormHelperText: {
        styleOverrides: {
            root: {
                marginLeft: 0,
                position: 'absolute',
                top: '100%',
                left: 2,
                '&.Mui-error': {
                    fontWeight: theme.typography.fontWeightBold,
                },
            },
        },
    },
    MuiInputBase: {
        styleOverrides: {
            root: {
                marginTop: 0,
                backgroundColor: `${theme.palette.background.paper}`,
                color: 'inherit',
                border: `1px solid ${theme.palette.grey[500]}`,
                borderRadius: theme.shape.borderRadius,
                boxShadow: 'inherit',
                outline: 0,
                transition: 'all 0.218s cubic-bezier(0.42,0,0.58,1)',
                width: '100%',
                '&:hover': {
                    borderColor: `${theme.palette.grey[600]}`,
                    borderTopColor: `${theme.palette.grey[700]}`,
                    boxShadow: 'inset 0 0.0625em 0.1875em rgb(0 0 0 / 20%)',
                },
                '&:focus, &:focus-within, &.Mui-focused': {
                    borderColor: `${theme.palette.primary.light}`,
                    backgroundColor: `${theme.palette.background.paper}`,
                    border: '1px solid #e86412',
                    boxShadow: 'inherit',
                    outline: '3px solid #ffc599',
                },
                '&:active': {
                    borderColor: `${theme.palette.grey[500]}`,
                },
                '&:before': {
                    borderColor: 'transparent !important',
                },
            },
            input: {
                height: 'auto',
                padding: theme.spacing(1.125),
            },
        },
    },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                '&:focus-within, &.Mui-focused': {
                    outline: '3px solid #ffc599',
                },
            },
            notchedOutline: {
                border: 0,
            },
            input: {
                padding: theme.spacing(1.125),
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            LinkComponent: LinkBehavior,
            disableRipple: true, // disable globally as it adds unecessary DOM elements
        },
        styleOverrides: {
            root: {
                color: theme.palette.secondary.main,
                '&:hover': {
                    backgroundColor: 'inherit',
                    color: theme.palette.primary.dark,
                },
                '&:focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 0.1875rem rgba(255, 138, 51, 0.8)`,
                },
            },
        },
    },
    MuiListItemButton: {
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: 'inherit',
                    color: theme.palette.primary.dark,
                },
            },
        },
    },
    MuiDrawer: {
        styleOverrides: {
            root: {
                zIndex: 1200000,
            },
        },
    },
    MuiPaginationItem: {
        defaultProps: {
            variant: 'outlined',
        },
        styleOverrides: {
            root: {
                border: theme.palette.border,
                fontWeight: 'bold',
                borderRadius: theme.shape.borderRadius,
                color: theme.palette.primary.main,
                height: theme.spacing(4),
                width: theme.spacing(4),
                '&:hover': {
                    borderColor: theme.palette.brand.lightorange,
                    backgroundColor: theme.palette.brand.lightestorange,
                    color: theme.palette.primary.main,
                },
                '&.Mui-selected': {
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.brand.lightestorange,
                    color: theme.palette.primary.dark,
                    boxShadow:
                        'inset 0 0.125em 0.25em rgba(0, 0, 0, .25), 0 0 0 0.25rem rgba(235, 123, 54, 0)',
                    '&:hover': {
                        borderColor: theme.palette.primary.light,
                        backgroundColor: theme.palette.brand.lightestorange,
                    },
                },
            },
        },
    },
    MuiMenuItem: {
        styleOverrides: {
            root: {
                '&:hover': {
                    backgroundColor: theme.palette.grey[200],
                    color: theme.palette.secondary.dark,
                },
            },
        },
    },
    MuiCardContent: {
        styleOverrides: {
            root: {
                position: 'relative',
                margin: '1rem 1rem 0 1rem',
            },
        },
    },
    MuiIconButton: {
        styleOverrides: {
            root: {
                color: 'inherit',
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    MuiButton: {
        styleOverrides: {
            root: {
                borderRadius: theme.shape.borderRadius,
                fontFamily: theme.typography.button.fontFamily,
                padding: theme.spacing(1, 2),
                '&:disabled': {
                    opacity: '0.6',
                },
                '&:focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 0.1875rem rgba(255, 138, 51, 0.8)`,
                },
            },
            text: {
                color: theme.palette.secondary.main,
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightRegular,
                verticalAlign: 'baseline',
                '&:hover': {
                    backgroundColor: 'transparent',
                    color: theme.palette.primary.dark,
                    textDecoration: 'underline',
                },
            },
            sizeLarge: {
                fontSize: '1.5rem',
            },
        },
        variants: [
            {
                props: { variant: 'standard' },
                style: {
                    backgroundColor: theme.palette.common.white,
                    backgroundImage:
                        'linear-gradient(to bottom, ' +
                        theme.palette.common.white +
                        ', #f8f8f8)',
                    border: `1px solid ${theme.palette.divider}`,
                    borderBottomColor: 'rgba(0, 0, 0, 0.4)',
                    boxShadow: '0 0.0625em 0.1875em rgb(0 0 0 / 0%)',
                    color: theme.palette.secondary.main,
                    textAlign: 'center',
                    textTransform: 'none',
                    userSelect: 'none',
                    verticalAlign: 'middle',
                    '&:hover': {
                        backgroundColor:
                            'linear-gradient(to bottom, #fffdfb,' +
                            theme.palette.grey[100] +
                            ')',
                        boxShadow: '0 0.0625em 0.1875em rgb(0 0 0 / 20%)',
                        color: theme.palette.primary.dark,
                    },
                },
            },
            {
                props: { variant: 'contained' },
                style: {
                    textAlign: 'center',
                    textTransform: 'none',
                    userSelect: 'none',
                    verticalAlign: 'middle',
                    '&:hover': {
                        color: theme.palette.primary.contrastText,
                    },
                    '&:active': {
                        boxShadow: 'inset 0 0.125em 0.25em rgb(0 0 0 / 25%)',
                    },
                },
            },
            {
                props: { variant: 'contained', color: 'primary' },
                style: {
                    '&:hover': {
                        color: theme.palette.primary.contrastText,
                    },
                    '&:disabled': {
                        backgroundColor: theme.palette.primary.main,
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText,
                    },
                },
            },
            {
                props: { variant: 'contained', color: 'secondary' },
                style: {
                    '&:hover': {
                        color: theme.palette.secondary.contrastText,
                    },
                },
            },
            {
                props: { variant: 'link' },
                style: {
                    color: theme.palette.secondary.main,
                    display: 'inline-block',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '100%',
                    padding: 0,
                    minWidth: 0,
                    width: 'auto',
                    height: 'auto',
                    lineHeight: 1,
                    textTransform: 'none',
                    verticalAlign: 'baseline',
                    textDecoration: 'underline',
                    '&:hover': {
                        backgroundColor: 'transparent',
                        color: theme.palette.primary.dark,
                    },
                },
            },
            {
                props: { variant: 'quickView' },
                style: {
                    backgroundColor: 'rgba(255 255 255 / 80%)',
                    backdropFilter: 'blur(0.25em)',
                    boxShadow:
                        'inset 0 0 0 1px white, 0 0 0 0.0625rem rgb(0 0 0 / 10%), 0 0.0625rem 0.125rem 0.0625rem rgb(0 0 0 / 0%)',
                    borderRadius: '3rem',
                    color: theme.palette.secondary.main,
                    fontSize: theme.typography.body2.fontSize,
                    letterSpacing: '0.0375em',
                    lineHeight: 'normal',
                    textTransform: 'uppercase',
                    '&:hover': {
                        backgroundColor: 'rgba(255 255 255 / 80%)',
                        backdropFilter: 'blur(0.5em)',
                        boxShadow:
                            'inset 0 0 0 1px white, 0 0.0625em 0.1875em rgb(0 0 0 / 20%), 0 0.0625rem 0.125rem 0.0625rem rgb(0 0 0 / 10%)',
                        color: theme.palette.primary.dark,
                    },
                },
            },
            {
                props: { size: 'small' },
                style: {
                    fontSize: theme.typography.caption.fontSize,
                    lineHeight: '1.75',
                    padding: theme.spacing(0.5, 1.25),
                },
            },
        ],
    },
};

export default theme;
