import {
    FieldWithLabel,
    FieldWithLabelProps,
} from '../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';
import React, { useEffect, useRef } from 'react';

export const PhoneWithLabel = (props: FieldWithLabelProps) => {
    const {
        name = 'phone',
        id = 'phone',
        label = 'Phone',
        type = 'tel',
        autoComplete = 'tel',
        formik,
        ...rest
    } = props;

    const initialFormat = useRef(false);

    useEffect(() => {
        if (!initialFormat.current) {
            const savedValue = formik.values[name];
            const isUnformatted = /^\d{10,}$/.test(savedValue);

            if (isUnformatted) {
                const formattedValue = savedValue
                    .substring(0, 10)
                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
                formik.setFieldValue(name, formattedValue);
            }
            initialFormat.current = true;
        }
    }, [formik, name]);

    const phoneFormatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const previousValue = formik.values[name];

        if (value.length < previousValue.length) {
            const digits = previousValue.replace(/\D/g, '').slice(0, -1);
            const formattedValue = digits
                .replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3')
                .replace(/-$/, '');
            formik.setFieldValue(name, formattedValue);
        } else {
            const digits = value.replace(/\D/g, '');
            let formattedValue = digits
                .substring(0, 10)
                .replace(/(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');

            if (digits.length > 10) {
                formattedValue += ' ext. ' + digits.substring(10);
            }

            formik.setFieldValue(name, formattedValue);
        }
    };
    return (
        <FieldWithLabel
            id={id}
            name={name}
            type={type}
            label={label}
            autoComplete={autoComplete}
            formik={formik}
            onChange={phoneFormatChange}
            {...rest}
        />
    );
};
