import { gql } from '@apollo/client';
import * as React from 'react';
import { client } from '../Graphql/ApolloClient';
import { CUSTOMER_REVIEW_FIELDS } from '../Graphql/fragments';
import { QL_CUST_REVIEWS } from '../Pages/PageItem/Components/CustomerReviews/Layouts/CustomerReviewsGrid';
import {
    CustomerReview,
    CustomerReviewMedia,
    ImageType,
    Item,
    Maybe,
    PaginatedCustomerReviewsResponse,
} from '../types';

type CustReviewSortOptions =
    | 'age_desc'
    | 'age_asc'
    | 'rating_desc'
    | 'rating_asc'
    | 'helpful_yes_desc'
    | string;
type CustReviewSortOption = {
    name: string;
    value: string;
};
const sortOptions: CustReviewSortOption[] = [
    {
        name: 'Newest first',
        value: 'created_date.desc',
    },
    {
        name: 'Oldest first',
        value: 'created_date',
    },
    {
        name: 'Highest rating first',
        value: 'overall_rating.desc',
    },
    {
        name: 'Lowest rating first',
        value: 'overall_rating',
    },
    {
        name: 'Most helpful first',
        value: 'helpful_votes.desc',
    },
];

const QL_CUST_REVIEW_MARK_HELPFUL = gql`
    ${CUSTOMER_REVIEW_FIELDS}
    mutation CustomerReviewMarkHelpful($reviewId: ID, $page: Int) {
        customerReviewHelpful(reviewId: $reviewId, page: $page) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            offset
            results {
                ...CustomerReviewFields
            }
        }
    }
`;

const QL_CUST_REVIEW_MARK_UNHELPFUL = gql`
    ${CUSTOMER_REVIEW_FIELDS}
    mutation CustomerReviewMarkUnhelpful($reviewId: ID, $page: Int) {
        customerReviewUnhelpful(reviewId: $reviewId, page: $page) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            offset
            results {
                ...CustomerReviewFields
            }
        }
    }
`;

const QL_CUST_REVIEW_MEDIA = gql`
    query CustomerReviewMedia($itemId: ID, $page: Int) {
        customerReviewMedia(itemId: $itemId, page: $page) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            offset
            results {
                type
                url
                thumbnailUrl
                caption
            }
        }
    }
`;

export const CustomerReviewsContext = React.createContext({
    page: 1,
    totalPages: 1,
    currentPage: 1,
    setPage: (val: number) => {
        return;
    },
    setTotalPages: (val: number) => {
        return;
    },
    setCurrentPage: (val: number) => {
        return;
    },
    sort: 'age_desc',
    setSort: (val: CustReviewSortOptions) => {
        return;
    },
    reviews: [] as CustomerReview[] | [],
    setReviews: (val: CustomerReview[]) => {
        return;
    },
    rating: null as null | number,
    setRating: (val: null | number) => {
        return;
    },
    item: {} as Item,
    setItem: (val: Item) => {
        return;
    },
    searchTerm: '',
    setSearchTerm: (term: string) => {
        return;
    },
    sortOptions: sortOptions as CustReviewSortOption[],
    markHelpful: (
        review: CustomerReview,
    ): Promise<PaginatedCustomerReviewsResponse> => {
        return new Promise(() => {
            return;
        });
    },
    markUnhelpful: (
        review: CustomerReview,
    ): Promise<PaginatedCustomerReviewsResponse> => {
        return new Promise(() => {
            return;
        });
    },
    hasInteractedWithHelpfulUnhelpful: (
        reviewId: string,
        type: 'helpful' | 'unhelpful',
    ): boolean => {
        return false;
    },
    fetchCustomerImages: (page: number): Promise<ImageType[]> => {
        return new Promise(() => {
            return;
        });
    },
});
interface ReviewMediaFetched {
    page: number;
    images: ImageType[];
}
const REVIEW_MEDIA_PAGES_FETCHED = [] as ReviewMediaFetched[];

export function CustomerReviewsProvider(props: {
    page: number;
    sort: CustReviewSortOptions;
    item: Item;
    children: any;
}) {
    const [page, setPageNumber] = React.useState(props.page);
    const [item, setItem] = React.useState(props.item);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sort, setPageSort] = React.useState(props.sort);
    const [searchTerm, setTerm] = React.useState('');
    const [rating, setPageRating] = React.useState<null | number>(null);
    const [reviews, setPageReviews] = React.useState<CustomerReview[]>([]);

    const setPage = (val: number) => {
        setPageNumber(val);
    };
    const setSort = (val: CustReviewSortOptions) => {
        setPageSort(val);
    };
    const setReviews = (reviews: CustomerReview[]) => {
        setPageReviews(reviews);
    };
    const setRating = (rating: null | number) => {
        setPageRating(rating);
    };
    const setSearchTerm = (term: string) => {
        setTerm(term);
    };

    const markHelpful = (
        review: CustomerReview,
    ): Promise<PaginatedCustomerReviewsResponse> => {
        return new Promise((resolve, reject) => {
            (async function () {
                if (hasInteractedWithHelpfulUnhelpful(review.id, 'helpful')) {
                    reject(reviews);
                }
                const response = await client.mutate({
                    mutation: QL_CUST_REVIEW_MARK_HELPFUL,
                    variables: {
                        reviewId: review.id,
                        page: page,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.errors) {
                    reject(reviews);
                }
                if (response.data.customerReviewHelpful) {
                    setHelpfulUnhelpfulIntoStorage(review.id, 'helpful');
                    setReviews(response.data.customerReviewHelpful.results);

                    resolve(response.data.customerReviewHelpful.results);
                } else {
                    reject(reviews);
                }
            })();
        });
    };

    const markUnhelpful = (
        review: CustomerReview,
    ): Promise<PaginatedCustomerReviewsResponse> => {
        return new Promise((resolve, reject) => {
            (async function () {
                if (hasInteractedWithHelpfulUnhelpful(review.id, 'unhelpful')) {
                    reject(reviews);
                }
                const response = await client.mutate({
                    mutation: QL_CUST_REVIEW_MARK_UNHELPFUL,
                    variables: {
                        reviewId: review.id,
                        page: page,
                    },
                    fetchPolicy: 'no-cache',
                    update(cache, { data: { customerReviewUnhelpful } }) {
                        cache.writeQuery({
                            query: QL_CUST_REVIEWS,
                            data: { customerReviews: customerReviewUnhelpful },
                        });
                    },
                    refetchQueries: ['CustomerReviews'],
                });
                if (response.errors) {
                    reject(reviews);
                }
                if (response.data.customerReviewUnhelpful) {
                    setHelpfulUnhelpfulIntoStorage(review.id, 'unhelpful');

                    setReviews(response.data.customerReviewUnhelpful.results);
                    resolve(response.data.customerReviewUnhelpful.results);
                } else {
                    reject(reviews);
                }
            })();
        });
    };

    const setHelpfulUnhelpfulIntoStorage = (
        reviewId: string,
        type: 'helpful' | 'unhelpful',
    ) => {
        if (hasInteractedWithHelpfulUnhelpful(reviewId, type)) {
            return;
        }
        const reviewedItemsStored = localStorage.getItem('reviews-' + type);
        let reviewedItems = [];
        if (reviewedItemsStored) {
            reviewedItems = JSON.parse(reviewedItemsStored);
        }

        reviewedItems.push(reviewId);
        localStorage.setItem('reviews-' + type, JSON.stringify(reviewedItems));
    };

    const hasInteractedWithHelpfulUnhelpful = (
        reviewId: string,
        type: 'helpful' | 'unhelpful',
    ): boolean => {
        const reviewedItemsStored = localStorage.getItem('reviews-' + type);
        let reviewedItems = [];
        if (reviewedItemsStored) {
            reviewedItems = JSON.parse(reviewedItemsStored);
        }
        return reviewedItems.indexOf(reviewId) > -1;
    };

    const fetchCustomerImages = (page: number): Promise<ImageType[]> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const existingFetchedImages = REVIEW_MEDIA_PAGES_FETCHED.filter(
                    obj => obj.page === page,
                ).pop();
                if (existingFetchedImages) {
                    resolve(existingFetchedImages.images);
                }
                const response = await client.query({
                    query: QL_CUST_REVIEW_MEDIA,
                    variables: {
                        itemId: item.id,
                        page: page,
                    },
                    fetchPolicy: 'cache-first',
                });
                if (response.errors) {
                    reject(response.errors);
                }

                if (
                    response &&
                    response.data.customerReviewMedia &&
                    response.data.customerReviewMedia.countResults > 0
                ) {
                    const allImages = [] as ImageType[];
                    response.data.customerReviewMedia.results.forEach(
                        (media: Maybe<CustomerReviewMedia>, i: number) => {
                            allImages.push({
                                id: `${media?.id}`,
                                description: `${media?.caption}`,
                                rank: i,
                                url: `${media?.url}`,
                            });
                        },
                    );
                    REVIEW_MEDIA_PAGES_FETCHED.push({
                        page: page,
                        images: allImages,
                    });
                    resolve(allImages);
                } else {
                    reject('Review images not found');
                }
            })();
        });
    };

    return (
        <CustomerReviewsContext.Provider
            value={{
                page,
                setPage,
                sort,
                setSort,
                reviews,
                setReviews,
                totalPages,
                setTotalPages,
                currentPage,
                setCurrentPage,
                item,
                setItem,
                rating,
                setRating,
                searchTerm,
                setSearchTerm,
                sortOptions,
                markHelpful,
                markUnhelpful,
                hasInteractedWithHelpfulUnhelpful,
                fetchCustomerImages,
            }}
        >
            {props.children}
        </CustomerReviewsContext.Provider>
    );
}
