import { useQuery } from '@apollo/client';
import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import document from 'global/document';
import window from 'global/window';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import PageBreadcrumbs, {
    PageBreadcrumbsLoading,
} from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { AddArticleSchema } from '../../Components/Meta/CreateSchemaOrg';
import { LoadingSpinner } from '../../Components/_base/BBQGuysComponents';
import {
    DesktopDevice,
    MobileDevice,
    TabletDevice,
} from '../../Contexts/DeviceTypeProvider';
import { doesScriptExist } from '../../functions';
import {
    Content,
    ContentDesignSystems,
    ContentScripts,
    ContentScriptsLoadType,
    Maybe,
} from '../../types';
import { decodeHTMLEntities } from '../../functions';
import './PageContent.scss';
import { SSRContext } from '../../Contexts/SSRProvider';
import { QL_CONTENT_WITH_BREADCRUMBS } from '../../Graphql/queries';

export const PageContentLoading = ({
    withBreadcrumbs = false,
}: {
    withBreadcrumbs?: boolean;
}) => {
    return (
        <ScContainer>
            {withBreadcrumbs && <PageBreadcrumbsLoading />}
            <LoadingSpinner center sx={{ mt: 3 }} />
            {/* <Skeleton variant="rectangular" width="100%" height="40vh" />
            <br />
            <Skeleton variant="text" width="50%" height="4rem" />
            {Array.from(Array(25).keys()).map((row: number, i: number) => {
                const newLine = [4,9,15,19,24].indexOf(i) > -1;
                return (
                    <React.Fragment key={i}>
                    <Skeleton key={i} variant="text" width={(newLine ? randomNumber(20, 80) : 100) + "%"} />
                    {newLine ? (
                        <br />
                    ) : null}
                    </React.Fragment>
                )
            })} */}
        </ScContainer>
    );
};

const InnerContentHtml = ({
    deviceType,
    html,
}: {
    deviceType: 'desktop' | 'tablet' | 'mobile';
    html: string;
}) => {
    return (
        <div
            id="content"
            className={'content page-content is-' + deviceType}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

export const ContentHTML = ({
    html,
    designSystem = ContentDesignSystems.Smarty,
}: {
    html: string;
    designSystem?: ContentDesignSystems;
}) => {
    useEffect(() => {
        // Initialize the social sharing script
        (function (doc, win) {
            const interfaceId = 'socialShare';
            const og = {
                url: encodeURIComponent(
                    doc
                        .querySelector('meta[property="og:url"]')
                        .getAttribute('content') || window.location.href,
                ),
                title: encodeURIComponent(
                    doc
                        .querySelector('meta[property="og:title"]')
                        .getAttribute('content') ||
                        doc.querySelector('title').innerText,
                ),
                description: encodeURIComponent(
                    doc
                        .querySelector('meta[property="og:description"]')
                        .getAttribute('content') ||
                        doc
                            .querySelector('meta[name="description"]')
                            .getAttribute('content') ||
                        '',
                ),
                image: encodeURIComponent(
                    doc
                        .querySelector('meta[property="og:image"]')
                        .getAttribute('content') || '',
                ),
            };
            const fb = {
                app_id: encodeURIComponent(
                    doc
                        .querySelector('meta[property="fb:app_id"]')
                        .getAttribute('content') || '',
                ),
            };
            const shareData: { [key: string]: string[] } = {
                facebook: [
                    `https://www.facebook.com/sharer.php?u=${og.url}&app_id=${fb.app_id}`,
                    'M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z',
                ],
                twitter: [
                    `https://twitter.com/intent/tweet?url=${og.url}&text=${og.description}`,
                    'M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z',
                ],
                pinterest: [
                    `https://www.pinterest.com/pin/create/button/?media=${og.image}&url=${og.url}&description=${og.description}`,
                    'M13.25,17.25C12.25,17.25 11.29,16.82 10.6,16.1L9.41,20.1L9.33,20.36L9.29,20.34C9.04,20.75 8.61,21 8.12,21C7.37,21 6.75,20.38 6.75,19.62C6.75,19.56 6.76,19.5 6.77,19.44L6.75,19.43L6.81,19.21L9.12,12.26C9.12,12.26 8.87,11.5 8.87,10.42C8.87,8.27 10.03,7.62 10.95,7.62C11.88,7.62 12.73,7.95 12.73,9.26C12.73,10.94 11.61,11.8 11.61,13C11.61,13.94 12.37,14.69 13.29,14.69C16.21,14.69 17.25,12.5 17.25,10.44C17.25,7.71 14.89,5.5 12,5.5C9.1,5.5 6.75,7.71 6.75,10.44C6.75,11.28 7,12.12 7.43,12.85C7.54,13.05 7.6,13.27 7.6,13.5A1.25,1.25 0 0,1 6.35,14.75C5.91,14.75 5.5,14.5 5.27,14.13C4.6,13 4.25,11.73 4.25,10.44C4.25,6.33 7.73,3 12,3C16.27,3 19.75,6.33 19.75,10.44C19.75,13.72 17.71,17.25 13.25,17.25Z',
                ],
                linkedin: [
                    `https://www.linkedin.com/shareArticle?mini=true&url=${og.url}&title=${og.title}&summary=${og.description}&source=${og.url}`,
                    'M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z',
                ],
                email: [
                    `mailto:?subject=${og.title}&body=${og.description}%0D%0A${og.url}`,
                    'M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z',
                ],
            };

            function shareHandler(event: Event) {
                event.preventDefault();
                const target = event.currentTarget as HTMLAnchorElement;
                const shareUrl = target.getAttribute('href');
                if (!shareUrl) return;
                if (target.dataset.social === 'email') {
                    window.location.href = shareUrl;
                } else {
                    window.open(
                        shareUrl,
                        'share-dialog',
                        'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,status=no,width=600,height=600',
                    );
                }
            }

            function _init() {
                const socialSharePlaceholder = doc.querySelector(
                    'nav[data-share="social"]',
                );
                if (!socialSharePlaceholder) {
                    return false;
                }

                _renderInterface(socialSharePlaceholder);
                window.dispatchEvent(new CustomEvent('ContentLoaded'));
            }

            function _renderInterface(placeholder: HTMLElement) {
                const interfaceElement = doc.createElement('nav');
                interfaceElement.id = interfaceId;
                interfaceElement.className =
                    'social social-share social-sml flex';
                interfaceElement.dataset.share = 'social';
                interfaceElement.dataset.prepend = 'Share:';
                const shareMethods = Object.keys(shareData);
                for (let i = 0; i < shareMethods.length; i++) {
                    const sharingMethod = shareMethods[i];
                    const btn = doc.createElement('a');
                    btn.setAttribute('href', shareData[sharingMethod][0]);
                    btn.setAttribute('target', '_blank');
                    btn.setAttribute('rel', 'noopener noreferrer');
                    btn.className = `btn btn-social btn-social-${sharingMethod} ga-${sharingMethod}-share`;
                    btn.dataset.social = sharingMethod;
                    btn.innerHTML = `<svg class="sc-icon sc-icon--${sharingMethod} sc-icon--sml-med" aria-hidden="true" focusable="false" viewBox="0 0 24 24"><path fill="currentColor" d="${shareData[sharingMethod][1]}"></path></svg><span class="sr-only">Share on ${sharingMethod.charAt(0).toUpperCase() + sharingMethod.slice(1)} (opens in a new window)</span>`;
                    btn.addEventListener('click', shareHandler);
                    interfaceElement.appendChild(btn);
                }
                placeholder.replaceWith(interfaceElement);
            }

            _init();
        })(document, window);
    }, []);

    return (
        <div className={'bbqg_content content_' + designSystem.toLowerCase()}>
            <DesktopDevice>
                <InnerContentHtml html={html} deviceType="desktop" />
            </DesktopDevice>
            <TabletDevice>
                <InnerContentHtml html={html} deviceType="tablet" />
            </TabletDevice>
            <MobileDevice>
                <InnerContentHtml html={html} deviceType="mobile" />
            </MobileDevice>
        </div>
    );
};

export const RenderContentData = (props: {
    content: Content;
    withIntro?: boolean;
    withBody?: boolean;
    sx?: SxProps;
}) => {
    const { content, withIntro = true, withBody = true } = props;
    const { isServerSide, isClientSide } = React.useContext(SSRContext);
    const location = useLocation();
    React.useEffect(() => {
        if (document && typeof document.querySelectorAll === 'function') {
            document.querySelectorAll('[data-src]').forEach((el: any) => {
                el.setAttribute('src', el.getAttribute('data-src'));
            });
            setTimeout(() => loadInlineScripts(content.scripts), 100);
        }
    }, [location]);
    let template = content.template;
    if (template) {
        template = template.replace(
            '{{INTRO}}',
            withIntro ? content.intro : '',
        );
        template = template.replace('{{BODY}}', withBody ? content.body : '');
    }
    return (
        <>
            <Helmet>
                {content.scripts
                    .filter(
                        (s: Maybe<ContentScripts>) =>
                            s &&
                            s.loadType === ContentScriptsLoadType.Remote &&
                            ((isClientSide() &&
                                !doesScriptExist(s.data ?? '')) ||
                                isServerSide()),
                    )
                    .map((s: Maybe<ContentScripts>, i: number) => {
                        return (
                            s &&
                            s.data && (
                                <script
                                    key={'script-' + s.hash}
                                    type={s.type}
                                    src={s.data ?? ''}
                                />
                            )
                        );
                    })}
            </Helmet>
            <Box sx={props.sx}>
                <ContentHTML
                    designSystem={content.designSystem}
                    html={'<!-- CONTENT ID: ' + content.id + ' -->' + template}
                />
            </Box>
        </>
    );
};

const loadedScripts: string[] = [];

const loadInlineScripts = (scripts: Maybe<ContentScripts>[]) => {
    if (scripts !== null && scripts.length > 0) {
        scripts.forEach((cs: Maybe<ContentScripts>) => {
            if (cs && cs.loadType === ContentScriptsLoadType.Inline) {
                if (loadedScripts.indexOf(cs.hash) === -1) {
                    loadedScripts.push(cs.hash);
                    const sc = document.createElement('script');
                    sc.innerHTML = cs.data;
                    sc.type = cs.type;
                    sc.defer = true;
                    document.body.appendChild(sc);
                }
            }
        });
    }
};

export const ContentDisplay = (props: {
    content: Content;
    withIntro?: boolean;
    withBody?: boolean;
    sx?: SxProps;
    withBreadcrumbs?: boolean;
    withPageHead?: boolean;
    withSchema?: boolean;
}) => {
    const {
        content,
        withIntro = false,
        withBody = true,
        withBreadcrumbs = false,
        withPageHead = false,
        sx = {},
        withSchema = false,
    } = props;
    const containerRef = React.useRef<HTMLDivElement>();
    const title = decodeHTMLEntities(content?.title ?? '');
    return (
        <>
            {withSchema && <AddArticleSchema content={content} />}
            <Helmet>
                {withPageHead && <title>{title}</title>}
                {content?.stylesheets
                    ? content?.stylesheets.map(
                          (styleUrl: Maybe<string>, i: number) => {
                              if (styleUrl) {
                                  return (
                                      <link
                                          key={'link-' + styleUrl}
                                          rel="stylesheet"
                                          href={styleUrl}
                                      />
                                  );
                              }
                          },
                      )
                    : null}
            </Helmet>
            <ScContainer containerProps={{ ref: containerRef }}>
                {withBreadcrumbs && (
                    <PageBreadcrumbs type="content" typeId={content.id} />
                )}
                <RenderContentData
                    content={content}
                    sx={sx}
                    withIntro={withIntro}
                    withBody={withBody}
                />
            </ScContainer>
        </>
    );
};

export const RenderContentWithId = (props: {
    contentId: string;
    withIntro?: boolean;
    withBody?: boolean;
    withBreadcrumbs?: boolean;
    withPageHead?: boolean;
    withSchema?: boolean;
}) => {
    const {
        contentId,
        withIntro = true,
        withBody = true,
        withBreadcrumbs = false,
        withPageHead = false,
        withSchema = false,
    } = props;
    const qry = QL_CONTENT_WITH_BREADCRUMBS;
    const variables = {
        contentId: contentId,
    };
    const { data, loading } = useQuery(qry, {
        variables: variables,
        fetchPolicy: 'network-only',
    });

    if (loading || !data) {
        return <PageContentLoading withBreadcrumbs={withBreadcrumbs} />;
    }

    return (
        <ContentDisplay
            content={data.content}
            withIntro={withIntro}
            withBody={withBody}
            withBreadcrumbs={withBreadcrumbs}
            withPageHead={withPageHead}
            withSchema={withSchema}
        />
    );
};

export default function PageContent(props: { contentId?: string }) {
    let { contentId = '' } = useParams();
    if (props.contentId !== undefined) {
        contentId = props.contentId;
    }
    if (parseInt(contentId) == 0) {
        throw new Error('Invalid Department Id');
    }

    return (
        <RenderContentWithId
            contentId={contentId}
            withBreadcrumbs={false}
            withPageHead={true}
            withSchema={true}
        />
    );
}
