import { LoadingButton } from '@mui/lab';
import {
    FormControl,
    FormLabel,
    MenuItem,
    Select,
    Stack,
    Alert,
    Typography,
    Box,
} from '@mui/material';
import { EmailOutlined } from '@mui/icons-material';
import { ResponsiveStyleValue } from '@mui/system';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { EmailWithLabel } from '../../Pages/Account/PageEditMyAccount/Components/EmailWithLabel';
import { PhoneWithLabel } from '../../Pages/Account/PageEditMyAccount/Components/PhoneWithLabel';
import { FieldWithLabel } from '../../Pages/Cart/PageCheckout/Forms/Components/FieldWithLabel';
import { ContactUsSchema } from '../../Schemas/Schemas';
import theme from '../../Theme';
import { MobileDevice } from '../../Contexts/DeviceTypeProvider';
import CustomerService from './CustomerService';
import { PhoneOutlined } from '@mui/icons-material';
import { ChatBubbleOutline } from '@mui/icons-material';
import { isProduction } from '../../config';
import window from 'global/window';

const emailMap: { [key: string]: string } = {
    'Product Questions / Availability': 'sales@bbqguys.com',
    'Request Design Services': 'sales@bbqguys.com',
    'Use Rewards Points': 'sales@bbqguys.com',
    'Payments / Checkout Assistance': 'sales@bbqguys.com',
    'PRO Account Questions': 'pro@bbqguys.com',
    'Order Status': 'customerservice@bbqguys.com',
    'Delivery Issues': 'customerservice@bbqguys.com',
    'Damaged / Missing Items': 'customerservice@bbqguys.com',
    'Cancel / Modify Order': 'customerservice@bbqguys.com',
    'Returns Assistance': 'customerservice@bbqguys.com',
    Other: 'customerservice@bbqguys.com',
};

const ContactUsForms: React.FC = () => {
    const GAP_SPACING = 1;
    const GAP_SPACING_HORIZONTAL = 2;
    const DIRECTION = { xs: 'column', md: 'row' } as ResponsiveStyleValue<
        'row' | 'row-reverse' | 'column' | 'column-reverse'
    >;
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            fullName: '',
            orderNumber: '',
            emailAddress: '',
            phoneNumber: '',
            subject: '',
            comment: '',
        },
        validationSchema: ContactUsSchema,
        onSubmit: async values => {
            setIsSubmitting(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            formik.resetForm();
            setIsSubmitting(false);
            setShowSuccess(true);
        },
    });
    const contactButtonStyle = {
        width: { xs: '100%', md: '50%' },
        justifyContent: 'flex-start',
        borderColor: `${theme.palette.grey[600]}`,
        '&:hover': {
            borderColor: `${theme.palette.grey[600]}`,
            '& .MuiSvgIcon-root, & .MuiTypography-root:not(.available-text)': {
                color: theme.palette.primary.main,
            },
        },
    };

    const iconStyle = {
        mr: 2,
        opacity: '0.6',
        fontSize: 40,
        color: theme.palette.text.secondary,
    };

    const textStyle = {
        fontSize: '1.25rem',
        fontWeight: 'normal',
        color: theme.palette.text.secondary,
    };
    const handlePhoneClick = () => {
        window.location.href = 'tel:18777432269';
    };
    const handleClickChat = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        // new zendesk messenger api
        if (typeof window.zE === 'function') {
            window.zE('webWidget', 'open');
        } else {
            // older zendesk api if enabled
            if (typeof window.$zopim === 'function') {
                window.$zopim(function () {
                    window.$zopim.livechat.window.toggle();
                });
            } else if (!isProduction()) {
                console.warn('Zendesk chat not found');
            }
        }
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack gap={GAP_SPACING}>
                <LoadingButton
                    variant="outlined"
                    sx={contactButtonStyle}
                    onClick={handlePhoneClick}
                >
                    <Box display="flex" alignItems="center">
                        <PhoneOutlined sx={iconStyle} />
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Typography component="span" sx={textStyle}>
                                1-877-743-2269
                            </Typography>
                            <Typography
                                component="span"
                                className="available-text"
                                sx={{
                                    fontStyle: 'italic !important',
                                    display: 'block',
                                    color: theme.palette.brand.brightgreen,
                                }}
                            >
                                Available
                            </Typography>
                        </Box>
                    </Box>
                </LoadingButton>
                <LoadingButton
                    variant="outlined"
                    sx={contactButtonStyle}
                    onClick={handleClickChat}
                >
                    <Box display="flex" alignItems="center">
                        <ChatBubbleOutline sx={iconStyle} />
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                        >
                            <Typography component="span" sx={textStyle}>
                                Live Chat
                            </Typography>
                            <Typography
                                component="span"
                                sx={{
                                    fontStyle: 'italic !important',
                                    display: 'block',
                                    color: theme.palette.text.secondary,
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                Sorry, we&#39;re not available
                            </Typography>
                        </Box>
                    </Box>
                </LoadingButton>
                <MobileDevice>
                    <CustomerService />
                </MobileDevice>
                <Typography variant="h4" component="h2" sx={{ mt: 5 }}>
                    <span
                        style={{
                            display: 'flex',
                        }}
                    >
                        <EmailOutlined sx={{ mr: 1, opacity: '0.6' }} />
                        Message Us
                    </span>
                </Typography>
                <Stack direction={DIRECTION} gap={GAP_SPACING_HORIZONTAL}>
                    <FormControl
                        variant="standard"
                        disabled={formik.isSubmitting}
                        fullWidth
                        sx={{ width: { xs: '100%', md: '50%' } }}
                    >
                        <FormLabel htmlFor="subject">
                            <span style={{ color: 'red' }}>*</span>Subject
                        </FormLabel>

                        <Select
                            inputProps={{ id: 'subject' }}
                            variant="outlined"
                            fullWidth
                            id="subject"
                            name="subject"
                            required
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.subject &&
                                Boolean(formik.errors.subject)
                            }
                            sx={{ padding: theme.spacing(1) }}
                        >
                            <MenuItem value="">
                                <em>Select a subject...</em>
                            </MenuItem>
                            <MenuItem value="Product Questions / Availability">
                                Product Questions / Availability
                            </MenuItem>
                            <MenuItem value="Request Design Services">
                                Request Design Services
                            </MenuItem>
                            <MenuItem value="Use Rewards Points">
                                Use Rewards Points
                            </MenuItem>
                            <MenuItem value="Payments / Checkout Assistance">
                                Payments / Checkout Assistance
                            </MenuItem>
                            <MenuItem value="PRO Account Questions">
                                PRO Account Questions
                            </MenuItem>
                            <MenuItem value="Order Status">
                                Order Status
                            </MenuItem>
                            <MenuItem value="Delivery Issues">
                                Delivery Issues
                            </MenuItem>
                            <MenuItem value="Damaged / Missing Items">
                                Damaged / Missing Items
                            </MenuItem>
                            <MenuItem value="Cancel / Modify Order">
                                Cancel / Modify Order
                            </MenuItem>
                            <MenuItem value="Returns Assistance">
                                Returns Assistance
                            </MenuItem>
                            <MenuItem value="Other">Other</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack direction={DIRECTION} gap={GAP_SPACING_HORIZONTAL}>
                    <FieldWithLabel
                        id="fullName"
                        name="fullName"
                        formik={formik}
                        label={`Full Name`}
                        required={true}
                    />
                    <FieldWithLabel
                        id="orderNumber"
                        name="orderNumber"
                        formik={formik}
                        label="Order Number (optional)"
                        required={false}
                    />
                </Stack>

                <Stack direction={DIRECTION} gap={GAP_SPACING_HORIZONTAL}>
                    <EmailWithLabel
                        id="emailAddress"
                        name="emailAddress"
                        formik={formik}
                        label={`Email Address`}
                        required={true}
                    />
                    <PhoneWithLabel
                        id="phoneNumber"
                        name="phoneNumber"
                        formik={formik}
                        label={`Phone Number`}
                        required={true}
                    />
                </Stack>
                <FieldWithLabel
                    sx={{ mb: 3 }}
                    id="comment"
                    name="comment"
                    formik={formik}
                    label={`Comment`}
                    required={true}
                    textFieldProps={{ multiline: true, rows: '5' }}
                />
            </Stack>
            {showSuccess && (
                <Alert severity="success" sx={{ marginBottom: 2 }}>
                    Thanks for contacting us. One of our experts will be in
                    touch with you soon. Have a great day!
                </Alert>
            )}
            <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                sx={{ width: { xs: '100%', md: 'fit-content' } }}
            >
                SEND MESSAGE
            </LoadingButton>
        </form>
    );
};

export default ContactUsForms;
