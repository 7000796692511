import { Box, Grid, Typography } from '@mui/material';

import { Helmet } from 'react-helmet-async';
import theme from '../../Theme';
import ScContainer from '../Layout/ScContainer/ScContainer';
import ContactUsForms from './ContactUsForms';
import CustomerService from './CustomerService';

import { RenderContentWithId } from '../../Pages/PageContent/PageContent';
import PageBanners from '../Banners/Banners';
import { DesktopAndTabletDevice } from '../../Contexts/DeviceTypeProvider';
import EmployeeTestimonials from './EmployeeTestimonials';

// const ContactToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
//     borderBottomLeftRadius: 0,
//     borderBottomRightRadius: 0,
//     borderLeft: `${theme.palette.border}`,
//     backgroundColor: selected
//         ? 'white!important'
//         : `${theme.palette.lightGrey.main}`,
//     borderTop: selected
//         ? `4px solid ${theme.palette.orange.dark}`
//         : `${theme.palette.border}`,
//     borderBottomColor: selected ? '#fff' : theme.palette.divider,
// }));

const ContactUs: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us</title>
            </Helmet>
            <ScContainer sx={{ pb: { md: theme.spacing(4) } }}>
                <Typography component="h1" variant="h2" sx={{ mt: 4 }}>
                    Contact Us
                </Typography>
                <PageBanners />
                <Grid
                    container
                    sx={{ flexDirection: { xs: 'column', md: 'row' } }}
                >
                    <DesktopAndTabletDevice>
                        <CustomerService />
                    </DesktopAndTabletDevice>

                    <Grid
                        container
                        direction="column"
                        sx={{
                            width: { md: '74%', xs: '100%' },
                            paddingX: {
                                xs: theme.spacing(2),
                                md: theme.spacing(1),
                            },
                        }}
                    >
                        <RenderContentWithId contentId="42429" />
                        <Box sx={{ marginTop: '85px' }}>
                            <ContactUsForms />
                        </Box>
                    </Grid>
                </Grid>
            </ScContainer>
            <EmployeeTestimonials />
        </>
    );
};
export default ContactUs;
