import { gql } from '@apollo/client';
import window from 'global/window';
import React from 'react';
import { client } from '../Graphql/ApolloClient';
import { CONTACT_INFO_ORDER_FIELDS } from '../Graphql/fragments';
import { isProduction } from '../config';
import {
    deleteCookie,
    empty,
    getCookie,
    getCookieDomain,
    setDualCookie,
} from '../functions';
import {
    AddItemsResponse,
    AddToCartItemProps,
    CouponApplyResponse,
    Item,
    Maybe,
    OpenBoxItem,
    Order,
    OrderItem,
    Promotion,
    User,
} from '../types';
import * as PaymentDeferredUtils from './PaymentDeferredUtils';

const QL_USER_LINK_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation linkOrderToUser($userId: ID!, $orderId: ID!) {
        linkOrderToUser(userId: $userId, orderId: $orderId) {
            order {
                ...ContactInfoOrderFields
            }
        }
    }
`;
const QL_USER_UNLINK_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation unlinkOrderFromUser($userId: ID!, $orderId: ID!) {
        unlinkOrderFromUser(userId: $userId, orderId: $orderId) {
            order {
                ...ContactInfoOrderFields
            }
        }
    }
`;
const QL_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    query getOrder($orderId: ID!) {
        order(id: $orderId) {
            ...ContactInfoOrderFields
        }
    }
`;

const QL_ADD_TO_CART = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation addToCart($orderId: ID!, $items: [AddToCartItemProps]!) {
        addItemsToOrder(orderId: $orderId, items: $items) {
            order {
                ...ContactInfoOrderFields
            }
        }
    }
`;

const QL_ADD_AND_CREATE_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation createOrder($items: [AddToCartItemProps]!) {
        createOrder(items: $items) {
            order {
                ...ContactInfoOrderFields
            }
        }
    }
`;
const QL_REMOVE_FROM_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation removeItemFromOrder($orderId: ID!, $orderItemId: ID!) {
        removeItemFromOrder(orderId: $orderId, orderItemId: $orderItemId) {
            ...ContactInfoOrderFields
        }
    }
`;
const QL_UPDATE_QTY_ON_ORDER = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation updateOrderItemQty($orderId: ID!, $orderItemId: ID!, $qty: Int!) {
        updateOrderItemQty(
            orderId: $orderId
            orderItemId: $orderItemId
            qty: $qty
        ) {
            ...ContactInfoOrderFields
        }
    }
`;

const QL_CHECKOUT_APPLY_COUPON = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    mutation applyCoupon($orderId: ID!, $code: String!) {
        applyCoupon(orderId: $orderId, code: $code) {
            error {
                message
            }
            couponInfo {
                valid
                code
                description
                startDate
                endDate
            }
            order {
                ...ContactInfoOrderFields
            }
        }
    }
`;

export interface AddToCartItem {
    item: Item | OpenBoxItem;
    qty: number;
    parentItemId?: string;
    customPrice?: number;
}

const LOCAL_STORAGE_KEY_ORDER = 'order';
const COOKIE_ORDER_ID = 'orderID';
const COOKIE_ORDER_COUNT = 'oi_cnt';

export const lastOrderState = {
    lastOrder: {} as Order,
    sentOrderData: false,
};

export const CartContext = React.createContext({
    order: {} as Order,
    setSentOrderData: (sentOrderData: boolean) => {
        return;
    },
    getSentOrderData: (): boolean => {
        return false;
    },
    clearOrder: (updateOrderState = true) => {
        return;
    },
    getOrder: (): Order => {
        return {} as Order;
    },
    getLastOrder: (): Order => {
        return {} as Order;
    },
    setOrder: (orderInfo: Order): void => {
        return;
    },
    setOrderIntoLocalStorage: (orderInfo: Order): void => {
        return;
    },
    getOrderFromServer: (
        orderId: string,
        updateOrderState = true,
    ): Promise<Order> => {
        return new Promise(() => {
            return;
        });
    },
    getOrderId: (): string => {
        return '';
    },
    hasOrder: (): boolean => {
        return false;
    },
    hasEndedPromotion: (orderInfo: Order): boolean => {
        return false;
    },
    isItemInCart: (itemId: string): boolean => {
        return false;
    },
    itemQtyInCart: (itemId: string): number => {
        return 0;
    },
    setOrderItemQty: (orderItem: OrderItem, qty: number): Promise<Order> => {
        return new Promise(() => {
            return;
        });
    },
    getOrderItem: (itemId: string): OrderItem => {
        return {} as OrderItem;
    },
    getTotalQtyInCart: (): number => {
        return 0;
    },
    getOrderItemQtyCart: (): number => {
        return 0;
    },
    addItemsToCart: (item: AddToCartItem[]): Promise<AddItemsResponse> => {
        return new Promise(() => {
            return;
        });
    },
    removeItemFromOrder: (orderItem: OrderItem): Promise<Order> => {
        return new Promise(() => {
            return;
        });
    },
    linkOrderToUser: (user: User): Promise<Order> => {
        return new Promise(() => {
            return;
        });
    },
    unlinkOrderFromUser: (user: User): Promise<Order> => {
        return new Promise(() => {
            return;
        });
    },
    applyCoupon: (code: string): Promise<CouponApplyResponse> => {
        return new Promise(() => {
            return;
        });
    },
});

export function CartProvider(props: { children: any }) {
    const getOrderFromServer = (orderId: string, updateOrderState = true) => {
        return new Promise<Order>((resolve, reject) => {
            (async function () {
                if (orderId.length < 2) {
                    reject(getOrder());
                } else {
                    const response = await client.query({
                        query: QL_ORDER,
                        variables: {
                            orderId: orderId,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject(getOrder());
                    }
                    // always allow order to be updated, we will handle non editable orders in cart
                    if (response.data.order) {
                        if (updateOrderState) {
                            setOrder(response.data.order);
                        }
                        resolve(response.data.order);
                    } else {
                        reject(getOrder());
                    }
                }
            })();
        });
    };

    const clearOrder = (updateOrderState = true) => {
        // Set the last order in case this is still needed
        lastOrderState.lastOrder = order;
        if (updateOrderState) {
            setOrder({} as Order);
        }
        localStorage.removeItem(LOCAL_STORAGE_KEY_ORDER);
        PaymentDeferredUtils.removePaymentDeferreds();
        deleteCookie(COOKIE_ORDER_ID);
        deleteCookie(COOKIE_ORDER_COUNT);

        deleteCookie(COOKIE_ORDER_ID, '/', getCookieDomain());
        deleteCookie(COOKIE_ORDER_COUNT, '/', getCookieDomain());
    };

    const getOrder = (): Order => {
        try {
            if (empty(getCookie(COOKIE_ORDER_ID))) {
                clearOrder(false);
                return {} as Order;
            }
            const data = localStorage.getItem(LOCAL_STORAGE_KEY_ORDER);
            return JSON.parse(data || '{"id": ""}') as Order;
        } catch (e) {
            return {} as Order;
        }
    };

    const [order, setOrderInfo] = React.useState(getOrder());
    React.useEffect(() => {
        const orderId = getOrderId();
        if (!orderId) {
            return;
        }
        getOrderFromServer(orderId).catch(err => {
            console.log('ERROR ORDER', err);
        });
    }, []);

    const getOrderId = (): string => {
        return getCookie(COOKIE_ORDER_ID) || '';
    };

    const hasOrder = (): boolean => {
        const o = getOrder();
        return o && o.id !== '' && o.orderItems && o.orderItems.length > 0;
    };

    const getSentOrderData = (): boolean => {
        return lastOrderState.sentOrderData;
    };

    const setSentOrderData = (sentOrderDataValue: boolean) => {
        lastOrderState.sentOrderData = sentOrderDataValue;
    };

    const setOrderIntoLocalStorage = (orderInfo: Order): void => {
        if (orderInfo && orderInfo.id) {
            const orderInfoStr = JSON.stringify(orderInfo);
            orderInfo = JSON.parse(orderInfoStr);
            orderInfo.paymentDeferreds =
                PaymentDeferredUtils.getPaymentDeferreds();
            PaymentDeferredUtils.setTotalOwed(orderInfo);
            orderInfo.pricing.totalOwed = PaymentDeferredUtils.getTotalOwed();
            orderInfo.pricingFormatted.totalOwed =
                PaymentDeferredUtils.currencyFormat(
                    orderInfo.pricing.totalOwed,
                );
            localStorage.setItem(
                LOCAL_STORAGE_KEY_ORDER,
                JSON.stringify(orderInfo),
            );
            if (
                empty(getCookie(COOKIE_ORDER_ID)) ||
                getCookie(COOKIE_ORDER_ID) != orderInfo.id
            ) {
                setDualCookie(COOKIE_ORDER_ID, orderInfo.id, undefined, '/');
            }
        }
    };

    const setOrder = (orderInfo: Order): void => {
        setOrderIntoLocalStorage(orderInfo);
        orderInfo = getOrder();
        setOrderInfo(orderInfo);
    };

    const hasEndedPromotion = (orderInfo: Order): boolean => {
        return (
            orderInfo &&
            orderInfo.orderItems &&
            orderInfo.orderItems.some(
                (oi: OrderItem) =>
                    oi.item.promotions &&
                    oi.item.promotions.some(
                        (p: Maybe<Promotion>) =>
                            p && new Date(p.dateEnd) < new Date(),
                    ),
            )
        );
    };

    const linkOrderToUser = async (user: User): Promise<Order> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const oId = getOrderId();
                if (
                    oId === null ||
                    oId === undefined ||
                    oId == '' ||
                    oId.length < 2 ||
                    empty(user.id)
                ) {
                    reject({});
                } else {
                    const response = await client.mutate({
                        mutation: QL_USER_LINK_ORDER,
                        variables: {
                            userId: user.id,
                            orderId: oId,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        throw response.errors[0];
                    }
                    if (
                        response.data &&
                        response.data.linkOrderToUser &&
                        response.data.linkOrderToUser.order !== null
                    ) {
                        setOrder(response.data.linkOrderToUser.order);
                        resolve(response.data.linkOrderToUser.order);
                    } else {
                        reject({});
                    }
                }
            })();
        });
    };

    const unlinkOrderFromUser = async (user: User): Promise<Order> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const oId = getOrderId();
                if (
                    oId === null ||
                    oId === undefined ||
                    oId == '' ||
                    oId.length < 2 ||
                    empty(user.id)
                ) {
                    reject({});
                } else {
                    try {
                        const response = await client.mutate({
                            mutation: QL_USER_UNLINK_ORDER,
                            variables: {
                                userId: user.id,
                                orderId: oId,
                            },
                            fetchPolicy: 'no-cache',
                        });
                        if (response.errors) {
                            reject(response.errors[0]);
                        }
                        if (
                            response.data &&
                            response.data.unlinkOrderFromUser &&
                            response.data.unlinkOrderFromUser.order !== null
                        ) {
                            setOrder(response.data.unlinkOrderFromUser.order);
                            resolve(response.data.unlinkOrderFromUser.order);
                        } else {
                            reject(getOrder());
                        }
                    } catch (er) {
                        reject(getOrder());
                    }
                }
            })();
        });
    };

    const isItemInCart = (itemId: string): boolean => {
        let inCart = false;
        if (hasOrder()) {
            getOrder().orderItems.map((oi: OrderItem, i: number) => {
                if (oi.itemId == itemId && oi.tdiId == null) {
                    inCart = true;
                } else if (oi.tdiId !== null && itemId == oi.tdiId) {
                    inCart = true;
                }
            });
        }
        return inCart;
    };

    const itemQtyInCart = (itemId: string): number => {
        let inCart = 0;
        if (hasOrder()) {
            getOrder().orderItems.map((oi: OrderItem, i: number) => {
                if (oi.itemId == itemId && oi.tdiId == null) {
                    inCart += oi.qty;
                } else if (oi.tdiId !== null && itemId == oi.tdiId) {
                    inCart += oi.qty;
                }
            });
        }
        return inCart;
    };

    const getOrderItem = (itemId: string): OrderItem => {
        const order = getOrder();
        if (order.orderItems && order.orderItems.length > 0) {
            return getOrder().orderItems.filter(
                (oi: OrderItem) => oi.tdiId === itemId || oi.id === itemId,
            )[0];
        }
        return {} as OrderItem;
    };

    const getTotalQtyInCart = (): number => {
        return getOrder()
            .orderItems?.map((oi: OrderItem) => oi.qty)
            .reduce((prev: number, current: number) => prev + current, 0);
    };
    const getOrderItemQtyCart = (): number => {
        return getOrder().orderItems?.length ?? 0;
    };

    const atcTrackGA = (items: AddToCartItem[]): void => {
        //GA4 stuff
        try {
            const googleAnalyticsData = {} as KeyValue;
            const itemPromoData = {} as KeyValue;
            const firstAtcItem = items[0];
            const firstItem = firstAtcItem.item as Item;
            const newItemObj = {
                item_id: firstItem.id,
                quantity: firstAtcItem.qty,
                item_name: firstItem.name,
                price: firstItem.pricing?.current ?? 0,
            };
            if (firstItem.promotions[0] && firstItem.promotions[0].id) {
                const bestPromo = firstItem.promotions[0];
                const newPromoObj = {
                    promotion_name: bestPromo.title,
                    creative_name: bestPromo.title,
                    promotion_id: bestPromo.id,
                    items: [newItemObj],
                };
                itemPromoData[bestPromo.id] = newPromoObj;
            }
            googleAnalyticsData[firstItem.id] = newItemObj;
            const googleAnalyticsATCData = [];
            let totalValue = 0;
            for (const i in items) {
                const googleAnalyticsObj =
                    googleAnalyticsData[items[i].item.id];
                if (googleAnalyticsObj == undefined) {
                    console.log(
                        'Could not find item for id:',
                        items[i].item.id,
                    );
                    continue;
                }
                const newItemObject = {} as KeyValue;
                for (const key in googleAnalyticsObj) {
                    newItemObject[key] = googleAnalyticsObj[key];
                }
                googleAnalyticsATCData.push(newItemObject);
                totalValue +=
                    googleAnalyticsData[items[i].item.id].price *
                    googleAnalyticsData[items[i].item.id].quantity;
            }
            if (itemPromoData) {
                for (const promoId in itemPromoData) {
                    if (promoId == undefined) {
                        console.log('undefined promo id');
                        continue;
                    }
                    const objToSend = itemPromoData[promoId];
                    if (!isProduction()) {
                        console.log(
                            'Sending to GA:',
                            'select_promotion',
                            objToSend,
                        );
                    }
                    if (window.gtag && typeof window.gtag === 'function') {
                        window.gtag('event', 'select_promotion', objToSend);
                    }
                }
            }
            const gaObj = {
                currency: 'USD',
                value: totalValue,
                items: googleAnalyticsATCData,
            };
            if (!isProduction()) {
                console.log('Sending to GA:', 'add_to_cart', gaObj);
            }
            if (window.gtag && typeof window.gtag === 'function') {
                window.gtag('event', 'add_to_cart', gaObj);
            }
        } catch (err) {
            console.error('Error with window.gtag add to cart: ' + err);
        }
    };

    const addItemsToCart = (
        items: AddToCartItem[],
    ): Promise<AddItemsResponse> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const itemProps = [] as AddToCartItemProps[];
                items.map((atcItem: AddToCartItem, i: number) => {
                    const itemObj = {
                        id: atcItem.item.id,
                        qty: atcItem.qty,
                        parentItemId: atcItem.parentItemId,
                        isTdi:
                            atcItem.item.__typename === 'OpenBoxItem'
                                ? true
                                : false,
                    } as AddToCartItemProps;
                    if (
                        atcItem.customPrice !== undefined &&
                        atcItem.customPrice > 0
                    ) {
                        itemObj.customPrice = atcItem.customPrice + '';
                    }
                    itemProps.push(itemObj);
                });
                if (hasOrder()) {
                    const response = await client.mutate({
                        mutation: QL_ADD_TO_CART,
                        variables: {
                            orderId: getOrderId(),
                            items: itemProps,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject({});
                    }
                    if (response.data.addItemsToOrder) {
                        atcTrackGA(items);
                        setOrder(response.data.addItemsToOrder.order);
                        document.body.dispatchEvent(
                            new CustomEvent('ListrakUpdateCartItems'),
                        );
                        resolve(response.data.addItemsToOrder);
                    }
                } else {
                    const response = await client.mutate({
                        mutation: QL_ADD_AND_CREATE_ORDER,
                        variables: {
                            items: itemProps,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject({});
                    }
                    if (response.data.createOrder) {
                        atcTrackGA(items);
                        setOrder(response.data.createOrder.order);
                        document.body.dispatchEvent(
                            new CustomEvent('ListrakUpdateCartItems'),
                        );
                        resolve(response.data.createOrder);
                    }
                }
                reject({});
            })();
        });
    };

    const removeItemFromOrder = (orderItem: OrderItem): Promise<Order> => {
        return new Promise((resolve, reject) => {
            (async function () {
                if (hasOrder()) {
                    const response = await client.mutate({
                        mutation: QL_REMOVE_FROM_ORDER,
                        variables: {
                            orderId: getOrderId(),
                            orderItemId: orderItem.id,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject({} as Order);
                    }
                    if (response.data.removeItemFromOrder) {
                        setOrder(response.data.removeItemFromOrder);
                        document.body.dispatchEvent(
                            new CustomEvent('ListrakUpdateCartItems'),
                        );
                        resolve(response.data.removeItemFromOrder);
                    }
                } else {
                    reject({} as Order);
                }
            })();
        });
    };

    const setOrderItemQty = (
        orderItem: OrderItem,
        qty: number,
    ): Promise<Order> => {
        return new Promise((resolve, reject) => {
            (async function () {
                if (hasOrder()) {
                    const response = await client.mutate({
                        mutation: QL_UPDATE_QTY_ON_ORDER,
                        variables: {
                            orderId: getOrderId(),
                            orderItemId: orderItem.id,
                            qty: qty,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        reject({} as Order);
                    }
                    if (response.data.updateOrderItemQty) {
                        setOrder(response.data.updateOrderItemQty);
                        document.body.dispatchEvent(
                            new CustomEvent('ListrakUpdateCartItems'),
                        );
                        resolve(response.data.updateOrderItemQty);
                    }
                } else {
                    reject({} as Order);
                }
            })();
        });
    };

    const applyCoupon = (code: string) => {
        return new Promise<CouponApplyResponse>((resolve, reject) => {
            (async () => {
                try {
                    const response = await client.mutate({
                        mutation: QL_CHECKOUT_APPLY_COUPON,
                        variables: {
                            orderId: order.id,
                            code: code,
                        },
                        fetchPolicy: 'no-cache',
                    });
                    if (response.errors) {
                        return reject(response.errors[0].message);
                    }
                    if (response.data.applyCoupon) {
                        if (response.data.applyCoupon.error?.message) {
                            return reject(
                                response.data.applyCoupon.error.message,
                            );
                        }
                        if (response.data.applyCoupon.order) {
                            setOrder(response.data.applyCoupon.order);
                        }
                        return resolve(response.data.applyCoupon);
                    } else {
                        return reject('An error occurred');
                    }
                } catch (err: any) {
                    return reject(err.message);
                }
            })();
        });
    };

    const getLastOrder = (): Order => {
        return lastOrderState.lastOrder;
    };

    return (
        <CartContext.Provider
            value={{
                order,
                itemQtyInCart,
                getTotalQtyInCart,
                getOrderItemQtyCart,
                getOrderItem,
                isItemInCart,
                clearOrder,
                setOrder,
                setOrderIntoLocalStorage,
                getOrder,
                getLastOrder,
                getOrderId,
                getOrderFromServer,
                hasOrder,
                hasEndedPromotion,
                addItemsToCart,
                removeItemFromOrder,
                setOrderItemQty,
                linkOrderToUser,
                unlinkOrderFromUser,
                applyCoupon,
                getSentOrderData,
                setSentOrderData,
            }}
        >
            {props.children}
        </CartContext.Provider>
    );
}
