import styled from '@emotion/styled';
import { Box } from '@mui/system';
import theme from '../../../Theme';
import BannersByArray from './BannersByArray';

export const BannerBaseContainer = styled(Box)(() => ({
    display: 'grid',
    gap: '10px',
    textAlign: 'center',
    maxWidth: 1600,
    margin: theme.spacing(0, 'auto', 2, 'auto'),
    a: {
        display: 'block',
        '> img': {
            height: '100%',
            paddingTop: '0.0625em',
            objectFit: 'cover',
            [theme.breakpoints.up('md')]: {
                paddingTop: '1em',
                // width: '100%',
                // height: '100%',
            },
        },
    },
    '.subgrid': {
        display: 'grid',
        gap: '10px',
        [theme.breakpoints.up('md')]: {
            img: {
                // height: '100%'
            },
        },
    },
}));

export const BannerCarouselContainer = styled(BannerBaseContainer)(() => ({
    '.slick-slider': {
        display: 'grid',
        '.slick-dots': {
            bottom: -20,
            height: 30,
            '> li': {
                height: 6,
                '> button': {
                    padding: 0,
                    height: 6,
                    ':before': {
                        background: theme.palette.primary.light,
                        height: 6,
                        padding: 0,
                        margin: 0,
                        borderRadius: 2,
                        content: `''`,
                    },
                },
            },
        },
    },
}));

const SPLIT_GRID_COLUMNS = 'minmax(50%, 100%) minmax(25%, 50%)';
export const BannerThreeUpPrimeTwins = styled(BannersByArray)(() => ({
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: SPLIT_GRID_COLUMNS,
    },
    '.subgrid': {
        [theme.breakpoints.down('md')]: {
            overflowX: 'scroll',
            gridTemplateColumns: 'repeat(2, 80%)',
        },
    },
}));
export const BannerTwoUpPrimeSide = styled(BannersByArray)(() => ({
    [theme.breakpoints.up('md')]: {
        gridTemplateColumns: SPLIT_GRID_COLUMNS,
    },
}));
export const BannerTwoUpHeroThin = styled(BannersByArray)(() => ({
    display: 'grid',
}));
export const BannerThin = styled(BannersByArray)(() => ({
    display: 'block',
}));
export const BannerHero = styled(BannersByArray)(() => ({
    display: 'block',
}));
