export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
    T extends { [key: string]: unknown },
    K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
    | T
    | {
          [P in keyof T]?: P extends ' $fragmentName' | '__typename'
              ? T[P]
              : never;
      };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
};

export type AddItemsResponse = {
    __typename?: 'AddItemsResponse';
    items: PaginatedItemsResponse;
    order: Order;
    relatedItems?: Maybe<PaginatedItemsResponse>;
    warranties?: Maybe<PaginatedItemsResponse>;
};

export type AddToCartItemProps = {
    customPrice?: InputMaybe<Scalars['String']['input']>;
    id: Scalars['ID']['input'];
    isTdi?: InputMaybe<Scalars['Boolean']['input']>;
    parentItemId?: InputMaybe<Scalars['String']['input']>;
    qty: Scalars['Int']['input'];
};

export type Address = {
    __typename?: 'Address';
    city?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    line1?: Maybe<Scalars['String']['output']>;
    line2?: Maybe<Scalars['String']['output']>;
    state?: Maybe<Scalars['String']['output']>;
    zip?: Maybe<Scalars['String']['output']>;
};

export type AddressVerificationResponse = {
    __typename?: 'AddressVerificationResponse';
    addressReviewId: Scalars['String']['output'];
    currentAddress: Address;
    isValidAddress: Scalars['Boolean']['output'];
    suggestedAddress: Address;
};

export type Answer = {
    __typename?: 'Answer';
    datetime: Scalars['String']['output'];
    helpfulVotes: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    isExpert: Scalars['Boolean']['output'];
    isVerified: Scalars['Boolean']['output'];
    location: Scalars['String']['output'];
    name: Scalars['String']['output'];
    notHelpfulVotes: Scalars['Int']['output'];
    questionId: Scalars['ID']['output'];
    source: Scalars['String']['output'];
    text: Scalars['String']['output'];
};

export type Attribute = {
    __typename?: 'Attribute';
    checked: Scalars['Boolean']['output'];
    count?: Maybe<Scalars['Int']['output']>;
    evar: Scalars['String']['output'];
    label: Scalars['String']['output'];
    labelId: Scalars['ID']['output'];
    parameter: Scalars['String']['output'];
    selectable: Scalars['Boolean']['output'];
    url: Scalars['String']['output'];
};

export type Banner = {
    __typename?: 'Banner';
    altText: Scalars['String']['output'];
    href: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    image: Scalars['String']['output'];
    imageHeight: Scalars['Int']['output'];
    imageHeightMobile: Scalars['Int']['output'];
    imageMobile: Scalars['String']['output'];
    imageWidth: Scalars['Int']['output'];
    imageWidthMobile: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    setTitle: Scalars['Boolean']['output'];
};

export type BannerCampaign = {
    __typename?: 'BannerCampaign';
    action: Scalars['String']['output'];
    actionId: Scalars['String']['output'];
    banners: Array<Maybe<Banner>>;
    id: Scalars['ID']['output'];
    isDefault: Scalars['Boolean']['output'];
    layout: BannerLayouts;
    name: Scalars['String']['output'];
    schedule?: Maybe<BannerCampaignSchedule>;
};

export type BannerCampaignSchedule = {
    __typename?: 'BannerCampaignSchedule';
    end: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    start: Scalars['String']['output'];
};

export enum BannerLayouts {
    BrandThin = 'BRAND_THIN',
    HomeHero = 'HOME_HERO',
    ThreeUpPrimeTwins = 'THREE_UP_PRIME_TWINS',
    TwoUpHeroThin = 'TWO_UP_HERO_THIN',
    TwoUpPrimeSide = 'TWO_UP_PRIME_SIDE',
}

export type Brand = {
    __typename?: 'Brand';
    about?: Maybe<Scalars['String']['output']>;
    deptId?: Maybe<Scalars['Int']['output']>;
    description?: Maybe<Scalars['String']['output']>;
    features: Array<Maybe<Scalars['String']['output']>>;
    id: Scalars['ID']['output'];
    imageUrl?: Maybe<Scalars['String']['output']>;
    logoUrl?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    shopAllUrl?: Maybe<Scalars['String']['output']>;
    shortName?: Maybe<Scalars['String']['output']>;
    url: Scalars['String']['output'];
    videoImageUrl?: Maybe<Scalars['String']['output']>;
    videoUrl?: Maybe<Scalars['String']['output']>;
};

export type BrandsCount = {
    __typename?: 'BrandsCount';
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type Breadcrumb = {
    __typename?: 'Breadcrumb';
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export enum CacheControlScope {
    Private = 'PRIVATE',
    Public = 'PUBLIC',
}

export type ColorSwatch = {
    __typename?: 'ColorSwatch';
    hexCode?: Maybe<Scalars['String']['output']>;
    iconUrl?: Maybe<Scalars['String']['output']>;
    itemId: Scalars['String']['output'];
    title?: Maybe<Scalars['String']['output']>;
    type: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type Config = {
    __typename?: 'Config';
    freeShipping: Scalars['Int']['output'];
};

export type Contact = {
    __typename?: 'Contact';
    address1?: Maybe<Scalars['String']['output']>;
    address2?: Maybe<Scalars['String']['output']>;
    city?: Maybe<Scalars['String']['output']>;
    company?: Maybe<Scalars['String']['output']>;
    country?: Maybe<Scalars['String']['output']>;
    email?: Maybe<Scalars['String']['output']>;
    fax?: Maybe<Scalars['String']['output']>;
    firstName?: Maybe<Scalars['String']['output']>;
    hashedEmail?: Maybe<Scalars['String']['output']>;
    lastName?: Maybe<Scalars['String']['output']>;
    phone?: Maybe<Scalars['String']['output']>;
    state?: Maybe<Scalars['String']['output']>;
    title?: Maybe<Scalars['String']['output']>;
    zip?: Maybe<Scalars['String']['output']>;
};

export type Content = {
    __typename?: 'Content';
    author?: Maybe<Employee>;
    body: Scalars['String']['output'];
    dateModified: Scalars['String']['output'];
    datePublished: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    designSystem: ContentDesignSystems;
    id: Scalars['ID']['output'];
    imageUrl?: Maybe<Scalars['String']['output']>;
    intro: Scalars['String']['output'];
    scripts: Array<Maybe<ContentScripts>>;
    source: Scalars['String']['output'];
    stylesheets?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    template: Scalars['String']['output'];
    title: Scalars['String']['output'];
    url?: Maybe<Scalars['String']['output']>;
};

export enum ContentDesignSystems {
    Smarty = 'SMARTY',
    Yii = 'YII',
}

export type ContentLinks = {
    __typename?: 'ContentLinks';
    description?: Maybe<Scalars['String']['output']>;
    imageUrl?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type ContentScripts = {
    __typename?: 'ContentScripts';
    data: Scalars['String']['output'];
    hash: Scalars['String']['output'];
    loadType: ContentScriptsLoadType;
    type: Scalars['String']['output'];
};

export enum ContentScriptsLoadType {
    Inline = 'INLINE',
    Remote = 'REMOTE',
}

export type CouponApplyResponse = {
    __typename?: 'CouponApplyResponse';
    couponInfo: CouponCodeInfo;
    error?: Maybe<ErrorResponse>;
    order: Order;
};

export type CouponCodeInfo = {
    __typename?: 'CouponCodeInfo';
    code: Scalars['String']['output'];
    description: Scalars['String']['output'];
    endDate: Scalars['String']['output'];
    startDate: Scalars['String']['output'];
    valid: Scalars['Boolean']['output'];
};

export type CustomerReview = {
    __typename?: 'CustomerReview';
    buyerStatus?: Maybe<Scalars['String']['output']>;
    countHelpful: Scalars['Int']['output'];
    countUnhelpful: Scalars['Int']['output'];
    datetime: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isSyndicated: Scalars['Boolean']['output'];
    location: Scalars['String']['output'];
    media?: Maybe<Array<CustomerReviewMedia>>;
    merchantResponse?: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    rating: Scalars['Float']['output'];
    source: Scalars['String']['output'];
    status: Scalars['String']['output'];
    syndicationImage?: Maybe<Scalars['String']['output']>;
    syndicationLink?: Maybe<Scalars['String']['output']>;
    syndicationSource?: Maybe<Scalars['String']['output']>;
    text: Scalars['String']['output'];
    title: Scalars['String']['output'];
    userId?: Maybe<Scalars['ID']['output']>;
};

export type CustomerReviewMedia = {
    __typename?: 'CustomerReviewMedia';
    caption?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    sourceId: Scalars['String']['output'];
    thumbnailUrl: Scalars['String']['output'];
    type: CustomerReviewMediaTypes;
    url: Scalars['String']['output'];
};

export enum CustomerReviewMediaTypes {
    Image = 'image',
    Video = 'video',
}

export type Department = {
    __typename?: 'Department';
    childDepartments?: Maybe<Array<Maybe<Department>>>;
    customerDescription: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    h1Title: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    imageType: DepartmentImageType;
    imageUrl?: Maybe<Scalars['String']['output']>;
    path?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
    useSearchResults: Scalars['Boolean']['output'];
};

export type DepartmentCollection = {
    __typename?: 'DepartmentCollection';
    departments: Array<Maybe<Department>>;
    name: Scalars['ID']['output'];
};

export type DepartmentContent = {
    __typename?: 'DepartmentContent';
    content: Content;
    position: Scalars['String']['output'];
};

export enum DepartmentImageType {
    Lifestyle = 'lifestyle',
    Product = 'product',
}

export type DiscountPricing = {
    __typename?: 'DiscountPricing';
    amount: Scalars['Float']['output'];
    code: Scalars['String']['output'];
};

export type DiscountPricingFormatted = {
    __typename?: 'DiscountPricingFormatted';
    amount: Scalars['String']['output'];
    code: Scalars['String']['output'];
};

export type Discounts = {
    __typename?: 'Discounts';
    coupons: Array<Maybe<DiscountPricing>>;
    giftCards: Array<Maybe<DiscountPricing>>;
    rewardPoints: Array<Maybe<DiscountPricing>>;
    total: Scalars['Float']['output'];
};

export type DiscountsFormatted = {
    __typename?: 'DiscountsFormatted';
    coupons: Array<Maybe<DiscountPricingFormatted>>;
    giftCards: Array<Maybe<DiscountPricingFormatted>>;
    rewardPoints: Array<Maybe<DiscountPricingFormatted>>;
    total: Scalars['String']['output'];
};

export type Employee = {
    __typename?: 'Employee';
    email: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
};

export type ErrorResponse = {
    __typename?: 'ErrorResponse';
    message?: Maybe<Scalars['String']['output']>;
};

export type ExpertReviewClass = {
    __typename?: 'ExpertReviewClass';
    author?: Maybe<Scalars['String']['output']>;
    content?: Maybe<Content>;
    fields: Array<Maybe<ExpertReviewField>>;
    id: Scalars['ID']['output'];
    imageUrl: Scalars['String']['output'];
    name: Scalars['String']['output'];
    parentClass?: Maybe<ExpertReviewClass>;
    rating: Scalars['String']['output'];
    ratingDescription: Scalars['String']['output'];
    type: ExpertReviewType;
    url: Scalars['String']['output'];
    videoImageUrl?: Maybe<Scalars['String']['output']>;
    videoUrl?: Maybe<Scalars['String']['output']>;
};

export type ExpertReviewField = {
    __typename?: 'ExpertReviewField';
    contentId: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    rank: Scalars['Int']['output'];
    rating: Scalars['Float']['output'];
};

export type ExpertReviewItem = {
    __typename?: 'ExpertReviewItem';
    contentSchema?: Maybe<Scalars['String']['output']>;
    fields: Array<Maybe<ExpertReviewField>>;
    imageUrl: Scalars['String']['output'];
    item?: Maybe<Item>;
    rating: Scalars['String']['output'];
    ratingDescription: Scalars['String']['output'];
    summary: Scalars['String']['output'];
    title: Scalars['String']['output'];
    url?: Maybe<Scalars['String']['output']>;
};

export enum ExpertReviewType {
    Grid = 'grid',
    Item = 'item',
    Main = 'main',
}

export type ExtendedWarranty = {
    __typename?: 'ExtendedWarranty';
    category: Scalars['String']['output'];
    contentId: Scalars['Int']['output'];
    highlights: Array<Maybe<Scalars['String']['output']>>;
    imageUrl: Scalars['String']['output'];
    items: Array<Maybe<Item>>;
    planId: Scalars['ID']['output'];
    title: Scalars['String']['output'];
};

export type Facet = {
    __typename?: 'Facet';
    attributes?: Maybe<Array<Maybe<Attribute>>>;
    helpContentId?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
};

export type FeaturedDept = {
    __typename?: 'FeaturedDept';
    href: Scalars['String']['output'];
    image: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type GiftCardInfo = {
    __typename?: 'GiftCardInfo';
    code: Scalars['String']['output'];
    currentValue: Scalars['Float']['output'];
    currentValueFormatted: Scalars['String']['output'];
    originalValue: Scalars['Float']['output'];
    originalValueFormatted: Scalars['String']['output'];
};

export type GiftCardInfoResponse = {
    __typename?: 'GiftCardInfoResponse';
    code: Scalars['String']['output'];
    currentValue: Scalars['Float']['output'];
    currentValueFormatted: Scalars['String']['output'];
    error?: Maybe<Scalars['String']['output']>;
    originalValue: Scalars['Float']['output'];
    originalValueFormatted: Scalars['String']['output'];
};

export type HoursOfOperation = {
    __typename?: 'HoursOfOperation';
    primary: Scalars['String']['output'];
    secondary?: Maybe<Scalars['String']['output']>;
};

export type Image360Type = {
    __typename?: 'Image360Type';
    images: Array<Maybe<ImageType>>;
    setName: Scalars['String']['output'];
};

export type ImageType = {
    __typename?: 'ImageType';
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    rank?: Maybe<Scalars['Int']['output']>;
    url: Scalars['String']['output'];
};

export type Item = {
    __typename?: 'Item';
    active: Scalars['Boolean']['output'];
    bulletPoints?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    category: Scalars['String']['output'];
    description?: Maybe<Scalars['String']['output']>;
    fuelType?: Maybe<Scalars['String']['output']>;
    has360Image: Scalars['Boolean']['output'];
    hasCustomPricing: Scalars['Boolean']['output'];
    hasExtendedWarranty: Scalars['Boolean']['output'];
    hasImage: Scalars['Boolean']['output'];
    hasInstallationAvailable: Scalars['Boolean']['output'];
    hasVideo: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    imageUrl: Scalars['String']['output'];
    inStock: Scalars['Boolean']['output'];
    isAvailable: Scalars['Boolean']['output'];
    isCallForPricing: Scalars['Boolean']['output'];
    isFreeShipping: Scalars['Boolean']['output'];
    isFreight: Scalars['Boolean']['output'];
    isKit: Scalars['Boolean']['output'];
    isQuickShip: Scalars['Boolean']['output'];
    manufacturerName: Scalars['String']['output'];
    manufacturerUrl: Scalars['String']['output'];
    modelNumber: Scalars['String']['output'];
    moreOptionsAvailable?: Maybe<Scalars['Int']['output']>;
    name: Scalars['String']['output'];
    openBoxCount: Scalars['Int']['output'];
    optionsAvailable: Array<Maybe<ColorSwatch>>;
    optionsAvailableCount: Scalars['Int']['output'];
    optionsAvailableDisplayType: OptionsAvailableDisplayType;
    pricing: Prices;
    pricingCustom: Array<Maybe<PricesCustom>>;
    pricingFormatted: PricesFormatted;
    pricingStrategy: Scalars['String']['output'];
    pricingValidUntil: Scalars['String']['output'];
    promotions: Array<Maybe<Promotion>>;
    prop65?: Maybe<Scalars['String']['output']>;
    qtyAvailable: Scalars['Int']['output'];
    hideStockQty: Scalars['Boolean']['output'];
    isDirectShip: Scalars['Boolean']['output'];
    qtyMax: Scalars['Int']['output'];
    rank: Scalars['Float']['output'];
    shippingDescription: Scalars['String']['output'];
    shipsIn: Scalars['String']['output'];
    shopperschoicePath: Scalars['String']['output'];
    showProLogo: Scalars['Boolean']['output'];
    stockStatus: Scalars['String']['output'];
    upc?: Maybe<Scalars['String']['output']>;
    url: Scalars['String']['output'];
    userReviewsCount: Scalars['Int']['output'];
    userReviewsRating: Scalars['Float']['output'];
    videoUrl?: Maybe<Scalars['String']['output']>;
    warrantyInfo?: Maybe<Scalars['String']['output']>;
    warrantyLengthMonths?: Maybe<Scalars['Int']['output']>;
    weight?: Maybe<Scalars['Int']['output']>;
};

export type ItemAccessories = {
    __typename?: 'ItemAccessories';
    helpTipContentId: Scalars['Int']['output'];
    options: Array<ItemAccessoryOption>;
    prefixLabel: Scalars['String']['output'];
};

export type ItemAccessoryOption = {
    __typename?: 'ItemAccessoryOption';
    id: Scalars['ID']['output'];
    imageUrl?: Maybe<Scalars['String']['output']>;
    includeInCart?: Maybe<Scalars['String']['output']>;
    label: Scalars['String']['output'];
    priceDifference?: Maybe<Scalars['Float']['output']>;
    pricing?: Maybe<Prices>;
    pricingFormatted?: Maybe<PricesFormatted>;
    pricingText?: Maybe<Scalars['String']['output']>;
    pricingType?: Maybe<Scalars['String']['output']>;
    qty?: Maybe<Scalars['Int']['output']>;
    showImage?: Maybe<Scalars['Boolean']['output']>;
};

export type ItemCompare = {
    __typename?: 'ItemCompare';
    item: Item;
    itemId: Scalars['ID']['output'];
    specifications: Array<Maybe<Specification>>;
};

export type ItemOption = {
    __typename?: 'ItemOption';
    id: Scalars['ID']['output'];
    imageUrl: Scalars['String']['output'];
    priceDifference: Scalars['Float']['output'];
    pricing: Prices;
    pricingFormatted: PricesFormatted;
    pricingText?: Maybe<Scalars['String']['output']>;
    pricingType?: Maybe<Scalars['String']['output']>;
    showImage: Scalars['Boolean']['output'];
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
    itemId: Scalars['String']['output'];
};

export type ItemOptions = {
    __typename?: 'ItemOptions';
    helpTipContentId?: Maybe<Scalars['Int']['output']>;
    label: Scalars['String']['output'];
    options: Array<ItemOption>;
    prefixLabel?: Maybe<Scalars['String']['output']>;
    selectBoxName: Scalars['String']['output'];
    selectBoxValueSelected: Scalars['String']['output'];
};

export type ItemReviewRatingCounts = {
    __typename?: 'ItemReviewRatingCounts';
    star1: Scalars['Int']['output'];
    star2: Scalars['Int']['output'];
    star3: Scalars['Int']['output'];
    star4: Scalars['Int']['output'];
    star5: Scalars['Int']['output'];
    total: Scalars['Int']['output'];
};

export type ItemReviewRatingPercents = {
    __typename?: 'ItemReviewRatingPercents';
    recommended: Scalars['String']['output'];
    star1: Scalars['String']['output'];
    star2: Scalars['String']['output'];
    star3: Scalars['String']['output'];
    star4: Scalars['String']['output'];
    star5: Scalars['String']['output'];
};

export type ItemReviewRatings = {
    __typename?: 'ItemReviewRatings';
    counts?: Maybe<ItemReviewRatingCounts>;
    overallRating: Scalars['Float']['output'];
    percents?: Maybe<ItemReviewRatingPercents>;
};

export type LearningCenter = {
    __typename?: 'LearningCenter';
    documents: Array<Maybe<LearningCenterItem>>;
    links: Array<Maybe<LearningCenterItem>>;
    videos: Array<Maybe<LearningCenterItem>>;
};

export type LearningCenterContent = {
    __typename?: 'LearningCenterContent';
    content_id?: Maybe<Scalars['ID']['output']>;
    content_title: Scalars['String']['output'];
    dept_id: Scalars['String']['output'];
    dept_title: Scalars['String']['output'];
    rank: Scalars['String']['output'];
    slug: Scalars['String']['output'];
};

export type LearningCenterItem = {
    __typename?: 'LearningCenterItem';
    image?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type Location = {
    __typename?: 'Location';
    address: Address;
    hours: HoursOfOperation;
    name: Scalars['String']['output'];
    phone: Scalars['String']['output'];
    type: LocationType;
};

export enum LocationType {
    CallCenter = 'CALL_CENTER',
    DesignCenter = 'DESIGN_CENTER',
}

export type Locations = {
    __typename?: 'Locations';
    showrooms: Array<Location>;
    website: Location;
};

export type Menu = {
    __typename?: 'Menu';
    id: Scalars['ID']['output'];
    menuItems: Array<MenuItem>;
    name: Scalars['String']['output'];
};

export type MenuItem = {
    __typename?: 'MenuItem';
    children: Array<Maybe<MenuItem>>;
    cssClasses?: Maybe<Scalars['String']['output']>;
    desktopOnly: Scalars['String']['output'];
    featImage?: Maybe<Scalars['String']['output']>;
    featItem?: Maybe<Scalars['String']['output']>;
    href?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    image?: Maybe<Scalars['String']['output']>;
    isNofollow: Scalars['String']['output'];
    learningCenter?: Maybe<Array<Maybe<LearningCenterContent>>>;
    menuColumn: Scalars['String']['output'];
    mobileOnly: Scalars['String']['output'];
    shopAllText: Scalars['String']['output'];
    text: Scalars['String']['output'];
};

export type MessageResponse = {
    __typename?: 'MessageResponse';
    message?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
};

export type MetaTag = {
    __typename?: 'MetaTag';
    content?: Maybe<Scalars['String']['output']>;
    name?: Maybe<Scalars['String']['output']>;
    property?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
    __typename?: 'Mutation';
    addItemsToOrder?: Maybe<AddItemsResponse>;
    applyCoupon: CouponApplyResponse;
    cartExternalLinkQuote: OrderResponse;
    checkGiftCardBalance: GiftCardInfoResponse;
    checkoutPaymentSubmit: PaymentSubmitResponse;
    checkoutPaymentToken: PaymentTokenResponse;
    createOrder?: Maybe<AddItemsResponse>;
    createWishlist: Wishlist;
    customerReviewHelpful: PaginatedCustomerReviewsResponse;
    customerReviewUnhelpful: PaginatedCustomerReviewsResponse;
    deleteWishlist: MessageResponse;
    editWishlist: Wishlist;
    linkOrderToUser?: Maybe<OrderResponse>;
    login?: Maybe<UserAuth>;
    logout?: Maybe<SuccessResponse>;
    markAnswerHelpful: SuccessResponse;
    newsletterSignup: MessageResponse;
    reauthorize?: Maybe<UserAuth>;
    register?: Maybe<UserAuth>;
    removeItemFromOrder?: Maybe<Order>;
    removeWishlistItem: Wishlist;
    resetPassword?: Maybe<PasswordResetResponse>;
    sendOrderData: MessageResponse;
    sendVendorInfo: MessageResponse;
    unlinkOrderFromUser?: Maybe<OrderResponse>;
    updateOrderCustomerInfo?: Maybe<Order>;
    updateOrderItemQty?: Maybe<Order>;
    updateOrderSMSPhone?: Maybe<MessageResponse>;
    updatePassword?: Maybe<PasswordResetResponse>;
    updateUserAccountInfo?: Maybe<SuccessOrErrorResponse>;
    updateUserAccountSecurityQuestion?: Maybe<SuccessOrErrorResponse>;
    updateWishlist: Wishlist;
    userDeleteSavedPaymentMethod?: Maybe<SavedPaymentMethodsResponse>;
    userSavedPaymentMethods?: Maybe<SavedPaymentMethodsResponse>;
    verifyAddress?: Maybe<AddressVerificationResponse>;
};

export type MutationAddItemsToOrderArgs = {
    items: Array<InputMaybe<AddToCartItemProps>>;
    orderId: Scalars['ID']['input'];
};

export type MutationApplyCouponArgs = {
    code: Scalars['String']['input'];
    orderId: Scalars['ID']['input'];
};

export type MutationCartExternalLinkQuoteArgs = {
    secret: Scalars['String']['input'];
};

export type MutationCheckGiftCardBalanceArgs = {
    code: Scalars['String']['input'];
};

export type MutationCheckoutPaymentSubmitArgs = {
    additionalData?: InputMaybe<Scalars['String']['input']>;
    cartVerificationToken: Scalars['String']['input'];
    orderId: Scalars['ID']['input'];
    paymentDeferreds?: InputMaybe<Scalars['String']['input']>;
    token: Scalars['String']['input'];
    type: Scalars['String']['input'];
};

export type MutationCheckoutPaymentTokenArgs = {
    orderId: Scalars['ID']['input'];
    returnUrl?: InputMaybe<Scalars['String']['input']>;
    type: Scalars['String']['input'];
    userId?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateOrderArgs = {
    items: Array<InputMaybe<AddToCartItemProps>>;
};

export type MutationCreateWishlistArgs = {
    items: Array<InputMaybe<WishlistItemProps>>;
    title: Scalars['String']['input'];
};

export type MutationCustomerReviewHelpfulArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
    reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationCustomerReviewUnhelpfulArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
    reviewId?: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteWishlistArgs = {
    id: Scalars['ID']['input'];
};

export type MutationEditWishlistArgs = {
    id: Scalars['ID']['input'];
    isPublic?: InputMaybe<Scalars['Boolean']['input']>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationLinkOrderToUserArgs = {
    orderId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationLoginArgs = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationLogoutArgs = {
    jwt: Scalars['String']['input'];
};

export type MutationMarkAnswerHelpfulArgs = {
    answerId: Scalars['ID']['input'];
    helpful: Scalars['Boolean']['input'];
};

export type MutationNewsletterSignupArgs = {
    email: Scalars['String']['input'];
};

export type MutationReauthorizeArgs = {
    siteId?: InputMaybe<Scalars['ID']['input']>;
    token: Scalars['String']['input'];
};

export type MutationRegisterArgs = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationRemoveItemFromOrderArgs = {
    orderId: Scalars['ID']['input'];
    orderItemId: Scalars['ID']['input'];
};

export type MutationRemoveWishlistItemArgs = {
    itemId: Scalars['ID']['input'];
    wishlistId: Scalars['ID']['input'];
};

export type MutationResetPasswordArgs = {
    email: Scalars['String']['input'];
};

export type MutationSendVendorInfoArgs = {
    VendorRegistrationForm: VendorRegistrationProps;
};

export type MutationUnlinkOrderFromUserArgs = {
    orderId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationUpdateOrderCustomerInfoArgs = {
    addressLine1: Scalars['String']['input'];
    addressLine2: Scalars['String']['input'];
    city: Scalars['String']['input'];
    company?: InputMaybe<Scalars['String']['input']>;
    country?: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    orderId: Scalars['ID']['input'];
    phone: Scalars['String']['input'];
    state: Scalars['String']['input'];
    type: Scalars['String']['input'];
    zip: Scalars['String']['input'];
};

export type MutationUpdateOrderItemQtyArgs = {
    orderId: Scalars['ID']['input'];
    orderItemId: Scalars['ID']['input'];
    qty: Scalars['Int']['input'];
};

export type MutationUpdateOrderSmsPhoneArgs = {
    orderId: Scalars['ID']['input'];
    phone: Scalars['String']['input'];
};

export type MutationUpdatePasswordArgs = {
    email: Scalars['String']['input'];
    newPassword: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type MutationUpdateUserAccountInfoArgs = {
    addressLine1: Scalars['String']['input'];
    addressLine2: Scalars['String']['input'];
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    dob: Scalars['String']['input'];
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    state: Scalars['String']['input'];
    zip: Scalars['String']['input'];
};

export type MutationUpdateUserAccountSecurityQuestionArgs = {
    answer: Scalars['String']['input'];
    questionId: Scalars['String']['input'];
};

export type MutationUpdateWishlistArgs = {
    id: Scalars['ID']['input'];
    items: Array<InputMaybe<WishlistItemProps>>;
    title?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserDeleteSavedPaymentMethodArgs = {
    token: Scalars['ID']['input'];
};

export type MutationVerifyAddressArgs = {
    city: Scalars['String']['input'];
    country?: InputMaybe<Scalars['String']['input']>;
    line1: Scalars['String']['input'];
    line2: Scalars['String']['input'];
    orderId: Scalars['ID']['input'];
    state: Scalars['String']['input'];
    zip: Scalars['String']['input'];
};

export type NormalizedOrderData = {
    __typename?: 'NormalizedOrderData';
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    email: Scalars['String']['output'];
    firstName: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    phone: Scalars['String']['output'];
    state: Scalars['String']['output'];
    zip: Scalars['String']['output'];
};

export type OpenBoxItem = {
    __typename?: 'OpenBoxItem';
    available: Scalars['Boolean']['output'];
    condition: Scalars['String']['output'];
    description: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    image?: Maybe<Scalars['String']['output']>;
    images: Array<Maybe<ImageType>>;
    inStock: Scalars['Boolean']['output'];
    isCallForPricing: Scalars['Boolean']['output'];
    isFreeShipping: Scalars['Boolean']['output'];
    isInCart: Scalars['Boolean']['output'];
    listPrice: Scalars['String']['output'];
    name: Scalars['String']['output'];
    price: Scalars['String']['output'];
    qtyMax: Scalars['Int']['output'];
    shipsIn: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export enum OptionsAvailableDisplayType {
    ColorSwatches = 'colorSwatches',
    Default = 'default',
    MoreOptions = 'moreOptions',
}

export type Order = {
    __typename?: 'Order';
    billingAddress: Contact;
    cartVerificationToken?: Maybe<Scalars['String']['output']>;
    deliveryDateRange: Scalars['String']['output'];
    editable: Scalars['Boolean']['output'];
    estimatedOrderShipDate?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isProOrder: Scalars['Boolean']['output'];
    normalizedOrderData?: Maybe<NormalizedOrderData>;
    orderDate: Scalars['String']['output'];
    orderItems: Array<OrderItem>;
    orderNumber: Scalars['String']['output'];
    orderStatus: Scalars['String']['output'];
    orderTotal: Scalars['String']['output'];
    origin?: Maybe<Scalars['String']['output']>;
    paymentDeferreds?: Maybe<Array<PaymentDeferred>>;
    paymentMethod?: Maybe<Scalars['String']['output']>;
    pricing: OrderPricing;
    pricingFormatted: OrderPricingFormatted;
    serverSidePaymentDeferreds?: Maybe<Array<PaymentDeferred>>;
    shippingAddress: Contact;
    totalOrders: Scalars['Int']['output'];
};

export type OrderItem = {
    __typename?: 'OrderItem';
    canAddWarrantyToItem: Scalars['Boolean']['output'];
    canBeRemovedFromOrder: Scalars['Boolean']['output'];
    dateAdded: Scalars['String']['output'];
    deliveryDateRange: Scalars['String']['output'];
    hasCartLink: Scalars['Boolean']['output'];
    hasLinkedWarranty: Scalars['Boolean']['output'];
    id: Scalars['ID']['output'];
    imageUrl: Scalars['String']['output'];
    isChildItem: Scalars['Boolean']['output'];
    isParentItem: Scalars['Boolean']['output'];
    item: Item;
    itemId: Scalars['String']['output'];
    itemUrl: Scalars['String']['output'];
    name: Scalars['String']['output'];
    orderId: Scalars['String']['output'];
    price: Scalars['String']['output'];
    pricing: OrderItemPricing;
    pricingFormatted: OrderItemPricingFormatted;
    qty: Scalars['Int']['output'];
    qtyShipped: Scalars['Int']['output'];
    shipping: Scalars['String']['output'];
    status: Scalars['String']['output'];
    tdiId?: Maybe<Scalars['String']['output']>;
    trackingUrl?: Maybe<Scalars['String']['output']>;
};

export type OrderItemPricing = {
    __typename?: 'OrderItemPricing';
    price: Scalars['Float']['output'];
    shipping: Scalars['Float']['output'];
    tax: Scalars['Float']['output'];
    taxRate: Scalars['Float']['output'];
    unitPrice: Scalars['Float']['output'];
    unitPriceWithTax: Scalars['Float']['output'];
};

export type OrderItemPricingFormatted = {
    __typename?: 'OrderItemPricingFormatted';
    price: Scalars['String']['output'];
    shipping: Scalars['String']['output'];
    tax: Scalars['String']['output'];
    unitPrice: Scalars['String']['output'];
};

export type OrderPricing = {
    __typename?: 'OrderPricing';
    discounts: Discounts;
    priceWithTax: Scalars['Float']['output'];
    proSavings: Scalars['Float']['output'];
    rewardPointsAvailable: RewardPointsAvailable;
    shipping: Scalars['Float']['output'];
    subtotal: Scalars['Float']['output'];
    tax: Scalars['Float']['output'];
    taxLines: Array<Maybe<TaxLine>>;
    taxRate: Scalars['Float']['output'];
    total: Scalars['Float']['output'];
    totalOwed: Scalars['Float']['output'];
    totalOwedWithoutDeferred: Scalars['Float']['output'];
};

export type OrderPricingFormatted = {
    __typename?: 'OrderPricingFormatted';
    discounts: DiscountsFormatted;
    proSavings: Scalars['String']['output'];
    rewardPointsAvailable: RewardPointsAvailableFormatted;
    shipping: Scalars['String']['output'];
    subtotal: Scalars['String']['output'];
    tax: Scalars['String']['output'];
    taxLines: Array<Maybe<TaxLineFormatted>>;
    total: Scalars['String']['output'];
    totalOwed: Scalars['String']['output'];
    totalOwedWithoutDeferred: Scalars['String']['output'];
};

export type OrderResponse = {
    __typename?: 'OrderResponse';
    order?: Maybe<Order>;
};

export type Page = {
    __typename?: 'Page';
    action?: Maybe<Scalars['String']['output']>;
    actionId?: Maybe<Scalars['String']['output']>;
    canonical?: Maybe<Scalars['String']['output']>;
    digitalData?: Maybe<Scalars['String']['output']>;
    id?: Maybe<Scalars['ID']['output']>;
    meta?: Maybe<Array<Maybe<MetaTag>>>;
    statusCode: Scalars['Int']['output'];
    title?: Maybe<Scalars['String']['output']>;
    url?: Maybe<Scalars['ID']['output']>;
};

export type PaginatedBrandsCountResponse = {
    __typename?: 'PaginatedBrandsCountResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<BrandsCount>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedBrandsResponse = {
    __typename?: 'PaginatedBrandsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Brand>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedContentResponse = {
    __typename?: 'PaginatedContentResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Content>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedCustomerReviewMediaResponse = {
    __typename?: 'PaginatedCustomerReviewMediaResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<CustomerReviewMedia>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedCustomerReviewsResponse = {
    __typename?: 'PaginatedCustomerReviewsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<CustomerReview>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedDepartmentCollectionsResponse = {
    __typename?: 'PaginatedDepartmentCollectionsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<DepartmentCollection>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedDepartmentsResponse = {
    __typename?: 'PaginatedDepartmentsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Department>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedExpertReviewClassesResponse = {
    __typename?: 'PaginatedExpertReviewClassesResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<ExpertReviewClass>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedExpertReviewItemResponse = {
    __typename?: 'PaginatedExpertReviewItemResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<ExpertReviewItem>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedExtendedWarrantyResponse = {
    __typename?: 'PaginatedExtendedWarrantyResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<ExtendedWarranty>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedItemsResponse = {
    __typename?: 'PaginatedItemsResponse';
    checkedFacets?: Maybe<Array<Maybe<SelectedAttribute>>>;
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    metaData?: Maybe<PaginatedItemsResponseMetaData>;
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Item>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedItemsResponseMetaData = {
    __typename?: 'PaginatedItemsResponseMetaData';
    finderType?: Maybe<Scalars['String']['output']>;
    groupingName?: Maybe<Scalars['String']['output']>;
    moreOptionsRenderStyle?: Maybe<Scalars['String']['output']>;
    queryTime?: Maybe<Scalars['Float']['output']>;
    redirectUrl?: Maybe<Scalars['String']['output']>;
    requestType?: Maybe<Scalars['String']['output']>;
    searchTerm?: Maybe<Scalars['String']['output']>;
    searchTypeId?: Maybe<Scalars['Int']['output']>;
    trackingId?: Maybe<Scalars['String']['output']>;
};

export type PaginatedLearningCenterLinks = {
    __typename?: 'PaginatedLearningCenterLinks';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<ContentLinks>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedOpenBoxItemsResponse = {
    __typename?: 'PaginatedOpenBoxItemsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<OpenBoxItem>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedOrdersResponse = {
    __typename?: 'PaginatedOrdersResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Order>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedPromotionsResponse = {
    __typename?: 'PaginatedPromotionsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Promotion>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedQuestionsAndAnswersResponse = {
    __typename?: 'PaginatedQuestionsAndAnswersResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<Question>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedRelatedDepartmentsResponse = {
    __typename?: 'PaginatedRelatedDepartmentsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<RelatedDepartment>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedRewardPointsResponse = {
    __typename?: 'PaginatedRewardPointsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<RewardPointsHistory>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedSiteHeaderPromotionsResponse = {
    __typename?: 'PaginatedSiteHeaderPromotionsResponse';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<SiteHeaderPromotions>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PaginatedUserWishlists = {
    __typename?: 'PaginatedUserWishlists';
    countResults: Scalars['Int']['output'];
    currentPage: Scalars['Int']['output'];
    nextPage?: Maybe<Scalars['String']['output']>;
    offset: Scalars['Int']['output'];
    prevPage?: Maybe<Scalars['String']['output']>;
    results: Array<Maybe<WishlistUser>>;
    totalPages: Scalars['Int']['output'];
    totalResults: Scalars['Int']['output'];
};

export type PasswordResetResponse = {
    __typename?: 'PasswordResetResponse';
    email?: Maybe<Scalars['String']['output']>;
    errorMessage?: Maybe<Scalars['String']['output']>;
    expires?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
};

export type PaymentDeferred = {
    __typename?: 'PaymentDeferred';
    amount: Scalars['Float']['output'];
    applied: Scalars['Boolean']['output'];
    deferredType: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    orderId: Scalars['String']['output'];
    paymentMethodKey?: Maybe<Scalars['String']['output']>;
    token: Scalars['String']['output'];
};

export type PaymentSubmitResponse = {
    __typename?: 'PaymentSubmitResponse';
    errorMessage?: Maybe<Scalars['String']['output']>;
    order?: Maybe<Order>;
    redirectUrl?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
};

export type PaymentTokenResponse = {
    __typename?: 'PaymentTokenResponse';
    signature?: Maybe<Scalars['String']['output']>;
    token: Scalars['String']['output'];
    type: Scalars['String']['output'];
};

export type Prices = {
    __typename?: 'Prices';
    current: Scalars['Float']['output'];
    financing: Scalars['Float']['output'];
    installation: Scalars['Float']['output'];
    list: Scalars['Float']['output'];
    openBox: Scalars['Float']['output'];
    retail: Scalars['Float']['output'];
    sale: Scalars['Float']['output'];
    savings: Scalars['Float']['output'];
    savingsPercent: Scalars['Float']['output'];
    shipping: Scalars['Float']['output'];
    type: Scalars['String']['output'];
    unit: Scalars['Float']['output'];
};

export type PricesCustom = {
    __typename?: 'PricesCustom';
    label: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type PricesFormatted = {
    __typename?: 'PricesFormatted';
    current: Scalars['String']['output'];
    financing: Scalars['String']['output'];
    installation: Scalars['String']['output'];
    list: Scalars['String']['output'];
    openBox: Scalars['String']['output'];
    retail: Scalars['String']['output'];
    sale: Scalars['String']['output'];
    savings: Scalars['String']['output'];
    savingsPercent: Scalars['String']['output'];
    shipping: Scalars['String']['output'];
    type: Scalars['String']['output'];
    unit: Scalars['String']['output'];
};

export type ProAccountRep = {
    __typename?: 'ProAccountRep';
    email: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    name: Scalars['String']['output'];
    phone: Scalars['String']['output'];
    photo: Scalars['String']['output'];
};

export type Promotion = {
    __typename?: 'Promotion';
    categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    dateEnd: Scalars['String']['output'];
    dateStart: Scalars['String']['output'];
    description: Scalars['String']['output'];
    displayText: Scalars['String']['output'];
    displayTextColor: Scalars['String']['output'];
    displayType: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    imageUrl?: Maybe<Scalars['String']['output']>;
    shortTitle: Scalars['String']['output'];
    showCountdown: Scalars['Boolean']['output'];
    tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
    title: Scalars['String']['output'];
    type: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type PromotionCategories = {
    __typename?: 'PromotionCategories';
    links: Array<Maybe<PromotionCategoryLink>>;
    title: Scalars['String']['output'];
};

export type PromotionCategoryLink = {
    __typename?: 'PromotionCategoryLink';
    link: Scalars['String']['output'];
    tags: Array<Maybe<Scalars['String']['output']>>;
    title: Scalars['String']['output'];
};

export type PromotionSidebarCategories = {
    __typename?: 'PromotionSidebarCategories';
    categories: Array<Maybe<PromotionCategories>>;
    title: Scalars['String']['output'];
};

export type Query = {
    __typename?: 'Query';
    _empty?: Maybe<Scalars['String']['output']>;
    bannerCampaigns?: Maybe<Array<Maybe<BannerCampaign>>>;
    brand?: Maybe<Brand>;
    brandBestSellers: PaginatedBrandsResponse;
    brandBestSellingItems: PaginatedItemsResponse;
    brandDepartments: PaginatedDepartmentsResponse;
    brandExpertReviews: PaginatedExpertReviewItemResponse;
    brandLearningCenterLinks: PaginatedLearningCenterLinks;
    brandPromotions: PaginatedPromotionsResponse;
    brands: PaginatedBrandsResponse;
    brandsCount: PaginatedBrandsCountResponse;
    breadcrumbs: Array<Maybe<Breadcrumb>>;
    content?: Maybe<Content>;
    contentByTags?: Maybe<PaginatedContentResponse>;
    contentByUrl?: Maybe<Content>;
    customerReviewMedia: PaginatedCustomerReviewMediaResponse;
    customerReviews: PaginatedCustomerReviewsResponse;
    customerReviewsSummary: ItemReviewRatings;
    department?: Maybe<Department>;
    departmentBestSellingItems: PaginatedItemsResponse;
    departmentBrands: PaginatedBrandsResponse;
    departmentChildren: PaginatedDepartmentsResponse;
    departmentCollections: PaginatedDepartmentCollectionsResponse;
    departmentContent: Array<Maybe<DepartmentContent>>;
    departmentExpertReviews: PaginatedExpertReviewItemResponse;
    departmentFacets: Array<Maybe<Facet>>;
    departmentItems: PaginatedItemsResponse;
    departmentLearningCenterLinks: PaginatedLearningCenterLinks;
    departmentTopDealItems: PaginatedItemsResponse;
    expertReviewClass?: Maybe<ExpertReviewClass>;
    expertReviewClassByItem: ExpertReviewClass;
    expertReviewClassItems: PaginatedExpertReviewItemResponse;
    expertReviewClasses: PaginatedExpertReviewClassesResponse;
    expertReviewRelatedItems: PaginatedItemsResponse;
    item: Item;
    itemAccessories: Array<Maybe<ItemAccessories>>;
    itemCABs: PaginatedItemsResponse;
    itemCollection: PaginatedItemsResponse;
    itemCompare: Array<Maybe<ItemCompare>>;
    itemFBTs: PaginatedItemsResponse;
    itemImages: Array<Maybe<ImageType>>;
    itemImages360: Array<Maybe<Image360Type>>;
    itemLearningCenterLinks: LearningCenter;
    itemOptions: Array<Maybe<ItemOptions>>;
    itemRelatedDepartmentItems: PaginatedItemsResponse;
    itemRelatedDepartments: PaginatedRelatedDepartmentsResponse;
    itemSpecifications: Array<Maybe<Specification>>;
    itemWarranties: PaginatedExtendedWarrantyResponse;
    itemsByIds: PaginatedItemsResponse;
    me?: Maybe<User>;
    menu?: Maybe<Array<Maybe<Menu>>>;
    menus?: Maybe<Array<Maybe<Menu>>>;
    openBoxItems: PaginatedOpenBoxItemsResponse;
    order: Order;
    orderByOrderNumber: Order;
    orderStatus: Order;
    orders: Array<Maybe<Order>>;
    page?: Maybe<Page>;
    questionsAndAnswers: PaginatedQuestionsAndAnswersResponse;
    searchFacets: Array<Maybe<Facet>>;
    searchItems: PaginatedItemsResponse;
    searchSortOptions: Array<Maybe<SortOption>>;
    site?: Maybe<Site>;
    siteClientIP: MessageResponse;
    siteHeaderPromotions: PaginatedSiteHeaderPromotionsResponse;
    sitePromotionCategories: PromotionSidebarCategories;
    sitePromotions: PaginatedPromotionsResponse;
    userAddresses?: Maybe<UserAddressesResponse>;
    userOrders: PaginatedOrdersResponse;
    userRewardPoints: PaginatedRewardPointsResponse;
    userSecurityQuestion: UserSecurityQuestionResponse;
    userSecurityQuestions: SecurityQuestionsResponse;
    userWishlists: PaginatedUserWishlists;
    warehouseDealFacets: Array<Maybe<Facet>>;
    warehouseDealItems: PaginatedItemsResponse;
    wishlist?: Maybe<Wishlist>;
};

export type QueryBannerCampaignsArgs = {
    query?: InputMaybe<Scalars['String']['input']>;
    url?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBrandArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBrandBestSellingItemsArgs = {
    brandId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBrandDepartmentsArgs = {
    brandId?: InputMaybe<Scalars['ID']['input']>;
    depth?: InputMaybe<Scalars['Int']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBrandExpertReviewsArgs = {
    brandId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryBrandLearningCenterLinksArgs = {
    brandId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBrandPromotionsArgs = {
    brandId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryBreadcrumbsArgs = {
    type?: InputMaybe<Scalars['String']['input']>;
    typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentByTagsArgs = {
    tags?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryContentByUrlArgs = {
    url?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryCustomerReviewMediaArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCustomerReviewsArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    rating?: InputMaybe<Scalars['Int']['input']>;
    searchTerm?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
};

export type QueryCustomerReviewsSummaryArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentBestSellingItemsArgs = {
    deptId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDepartmentBrandsArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentChildrenArgs = {
    depth?: InputMaybe<Scalars['Int']['input']>;
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentCollectionsArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentContentArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentExpertReviewsArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryDepartmentFacetsArgs = {
    deptId?: InputMaybe<Scalars['ID']['input']>;
    refinements?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDepartmentItemsArgs = {
    deptId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
    refinements?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
};

export type QueryDepartmentLearningCenterLinksArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryDepartmentTopDealItemsArgs = {
    deptId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExpertReviewClassArgs = {
    id: Scalars['ID']['input'];
};

export type QueryExpertReviewClassByItemArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryExpertReviewClassItemsArgs = {
    id: Scalars['ID']['input'];
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryExpertReviewRelatedItemsArgs = {
    id: Scalars['ID']['input'];
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
    qty?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemAccessoriesArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemCaBsArgs = {
    itemIds?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemCollectionArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemCompareArgs = {
    itemIds?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemFbTsArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemImagesArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryItemImages360Args = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryItemLearningCenterLinksArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryItemOptionsArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemRelatedDepartmentItemsArgs = {
    deptId?: InputMaybe<Scalars['ID']['input']>;
    itemId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemRelatedDepartmentsArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemSpecificationsArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryItemWarrantiesArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryItemsByIdsArgs = {
    itemIds?: InputMaybe<Scalars['ID']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryMenuArgs = {
    name?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOpenBoxItemsArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrderArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryOrderByOrderNumberArgs = {
    orderNumber?: InputMaybe<Scalars['String']['input']>;
};

export type QueryOrderStatusArgs = {
    orderNumber: Scalars['String']['input'];
    zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPageArgs = {
    url?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryQuestionsAndAnswersArgs = {
    itemId?: InputMaybe<Scalars['ID']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    searchTerm?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
};

export type QuerySearchFacetsArgs = {
    deptId?: InputMaybe<Scalars['String']['input']>;
    refinements?: InputMaybe<Scalars['String']['input']>;
    term?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySearchItemsArgs = {
    deptId?: InputMaybe<Scalars['String']['input']>;
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
    refinements?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
    term?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySearchSortOptionsArgs = {
    type?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySiteArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySiteHeaderPromotionsArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
    siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitePromotionCategoriesArgs = {
    siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuerySitePromotionsArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
    pricingTierId?: InputMaybe<Scalars['Int']['input']>;
    siteId?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryUserOrdersArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserRewardPointsArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUserWishlistsArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryWarehouseDealFacetsArgs = {
    refinements?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWarehouseDealItemsArgs = {
    page?: InputMaybe<Scalars['Int']['input']>;
    refinements?: InputMaybe<Scalars['String']['input']>;
    sort?: InputMaybe<Scalars['String']['input']>;
};

export type QueryWishlistArgs = {
    id?: InputMaybe<Scalars['ID']['input']>;
};

export type Question = {
    __typename?: 'Question';
    answerCount: Scalars['Int']['output'];
    answers: Array<Maybe<Answer>>;
    datetime: Scalars['String']['output'];
    expertAnswerCount: Scalars['Int']['output'];
    id: Scalars['ID']['output'];
    location: Scalars['String']['output'];
    name: Scalars['String']['output'];
    text: Scalars['String']['output'];
};

export type RelatedDepartment = {
    __typename?: 'RelatedDepartment';
    description?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    imageUrl?: Maybe<Scalars['String']['output']>;
    title: Scalars['String']['output'];
    url: Scalars['String']['output'];
};

export type RewardPointsAvailable = {
    __typename?: 'RewardPointsAvailable';
    inDollars: RewardPointsPricingInDollars;
    inPoints: RewardPointsPricingInPoints;
};

export type RewardPointsAvailableFormatted = {
    __typename?: 'RewardPointsAvailableFormatted';
    inDollars: RewardPointsPricingFormatted;
    inPoints: RewardPointsPricingFormatted;
};

export type RewardPointsHistory = {
    __typename?: 'RewardPointsHistory';
    id: Scalars['ID']['output'];
    orderId?: Maybe<Scalars['String']['output']>;
    pointsChanged: Scalars['Int']['output'];
    timestamp: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type RewardPointsPricingFormatted = {
    __typename?: 'RewardPointsPricingFormatted';
    applied: Scalars['String']['output'];
    available: Scalars['String']['output'];
    cost: Scalars['String']['output'];
    earnable: Scalars['String']['output'];
    needed: Scalars['String']['output'];
};

export type RewardPointsPricingInDollars = {
    __typename?: 'RewardPointsPricingInDollars';
    applied: Scalars['Float']['output'];
    available: Scalars['Float']['output'];
    cost: Scalars['Float']['output'];
    earnable: Scalars['Float']['output'];
    needed: Scalars['Float']['output'];
};

export type RewardPointsPricingInPoints = {
    __typename?: 'RewardPointsPricingInPoints';
    applied: Scalars['Int']['output'];
    available: Scalars['Int']['output'];
    cost: Scalars['Int']['output'];
    earnable: Scalars['Int']['output'];
    needed: Scalars['Int']['output'];
};

export type SavedPaymentMethod = {
    __typename?: 'SavedPaymentMethod';
    cardType: Scalars['String']['output'];
    dateAdded: Scalars['String']['output'];
    expirationDate: Scalars['String']['output'];
    imageUrl: Scalars['String']['output'];
    isDefault: Scalars['Boolean']['output'];
    isExpired: Scalars['Boolean']['output'];
    isVerified: Scalars['Boolean']['output'];
    last4?: Maybe<Scalars['String']['output']>;
    maskedNumber?: Maybe<Scalars['String']['output']>;
    paymentMethodKey: Scalars['String']['output'];
    title: Scalars['String']['output'];
    token: Scalars['ID']['output'];
    type: Scalars['String']['output'];
    typeId: Scalars['Int']['output'];
    userId: Scalars['Int']['output'];
};

export type SavedPaymentMethodsResponse = {
    __typename?: 'SavedPaymentMethodsResponse';
    applepay: Array<Maybe<SavedPaymentMethod>>;
    credit: Array<Maybe<SavedPaymentMethod>>;
    googlepay: Array<Maybe<SavedPaymentMethod>>;
    paypal: Array<Maybe<SavedPaymentMethod>>;
};

export type SecurityQuestion = {
    __typename?: 'SecurityQuestion';
    id: Scalars['ID']['output'];
    question: Scalars['String']['output'];
};

export type SecurityQuestionsResponse = {
    __typename?: 'SecurityQuestionsResponse';
    questions: Array<Maybe<SecurityQuestion>>;
};

export type SelectedAttribute = {
    __typename?: 'SelectedAttribute';
    attribute: Attribute;
    attributeLabel: Scalars['String']['output'];
    facetLabel: Scalars['String']['output'];
};

export type Site = {
    __typename?: 'Site';
    adobeLaunchUrl: Scalars['String']['output'];
    config: Config;
    domain: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    legalName: Scalars['String']['output'];
    locations: Locations;
    name: Scalars['String']['output'];
};

export type SiteHeaderPromotions = {
    __typename?: 'SiteHeaderPromotions';
    backgroundColor?: Maybe<Scalars['String']['output']>;
    dateEnd?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    text: Scalars['String']['output'];
    textColor?: Maybe<Scalars['String']['output']>;
    url: Scalars['String']['output'];
};

export type SortOption = {
    __typename?: 'SortOption';
    label: Scalars['String']['output'];
    value: Scalars['String']['output'];
};

export type Specification = {
    __typename?: 'Specification';
    contentId: Scalars['Int']['output'];
    fieldName: Scalars['String']['output'];
    fieldValue: Scalars['String']['output'];
};

export type SuccessOrErrorResponse = {
    __typename?: 'SuccessOrErrorResponse';
    message: Scalars['String']['output'];
    success: Scalars['Boolean']['output'];
};

export type SuccessResponse = {
    __typename?: 'SuccessResponse';
    success: Scalars['Boolean']['output'];
};

export type TaxLine = {
    __typename?: 'TaxLine';
    amount: Scalars['Float']['output'];
    name: Scalars['String']['output'];
};

export type TaxLineFormatted = {
    __typename?: 'TaxLineFormatted';
    amount: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type User = {
    __typename?: 'User';
    actValue: Scalars['Float']['output'];
    address?: Maybe<Contact>;
    birthday?: Maybe<Scalars['String']['output']>;
    company: Scalars['String']['output'];
    email: Scalars['String']['output'];
    employee?: Maybe<Employee>;
    firstName: Scalars['String']['output'];
    hashedEmail?: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isEmployee: Scalars['Boolean']['output'];
    isPro: Scalars['Boolean']['output'];
    lastLogin: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    name: Scalars['String']['output'];
    pastCustomer: Scalars['Boolean']['output'];
    phone: Scalars['String']['output'];
    pricingTierId: Scalars['Int']['output'];
    proAccountRep?: Maybe<ProAccountRep>;
    rewardPoints: UserRewardPointsData;
    verified: Scalars['Boolean']['output'];
};

export type UserAddressesResponse = {
    __typename?: 'UserAddressesResponse';
    billing: Array<Maybe<Contact>>;
    shipping: Array<Maybe<Contact>>;
    user: Array<Maybe<Contact>>;
};

export type UserAuth = {
    __typename?: 'UserAuth';
    authenticated: Scalars['Boolean']['output'];
    errorMessage?: Maybe<Scalars['String']['output']>;
    token: Scalars['String']['output'];
    user: User;
};

export type UserRewardPointsData = {
    __typename?: 'UserRewardPointsData';
    available: Scalars['Int']['output'];
    cashValue: Scalars['Float']['output'];
    number: Scalars['String']['output'];
};

export type UserSecurityQuestionResponse = {
    __typename?: 'UserSecurityQuestionResponse';
    answer: Scalars['String']['output'];
    questionId: Scalars['ID']['output'];
};

export type VendorRegistrationProps = {
    companyAddress: Scalars['String']['input'];
    companyCity: Scalars['String']['input'];
    companyHistory: Scalars['String']['input'];
    companyName: Scalars['String']['input'];
    companyPhone: Scalars['String']['input'];
    companyState: Scalars['String']['input'];
    companyZip: Scalars['String']['input'];
    email: Scalars['String']['input'];
    name: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    productBrief: Scalars['String']['input'];
    productCategory?: InputMaybe<Scalars['String']['input']>;
    productDropShop: Scalars['String']['input'];
    productForm: Scalars['String']['input'];
    productManufacturer?: InputMaybe<Scalars['String']['input']>;
    productMargins?: InputMaybe<Scalars['String']['input']>;
    productReturns: Scalars['String']['input'];
    productStoreList?: InputMaybe<Scalars['String']['input']>;
    productStores: Scalars['String']['input'];
    title?: InputMaybe<Scalars['String']['input']>;
};

export type Wishlist = {
    __typename?: 'Wishlist';
    createdAt: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isPublic: Scalars['Boolean']['output'];
    items: Array<Maybe<WishlistItem>>;
    title: Scalars['String']['output'];
    updatedAt: Scalars['String']['output'];
    username?: Maybe<Scalars['String']['output']>;
};

export type WishlistItem = {
    __typename?: 'WishlistItem';
    id: Scalars['ID']['output'];
    item?: Maybe<Item>;
    notes: Scalars['String']['output'];
    qty: Scalars['Int']['output'];
};

export type WishlistItemProps = {
    id: Scalars['ID']['input'];
    notes?: InputMaybe<Scalars['String']['input']>;
    qty: Scalars['Int']['input'];
};

export type WishlistUser = {
    __typename?: 'WishlistUser';
    id: Scalars['ID']['output'];
    userId: Scalars['String']['output'];
    wishlist: Wishlist;
};
