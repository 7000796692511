import { AddSharp } from '@mui/icons-material';
import { Box, Link, SxProps, Typography } from '@mui/material';
import React from 'react';
import { SSRContext } from '../../Contexts/SSRProvider';
import { Item } from '../../types';
import { screenReaderPrice } from './Pricing';
import OpenBoxModal from '../../Pages/PageItem/Components/OpenBoxItems/OpenBoxModal';

export const FreeShippingText = (props: {
    isFreeShipping: boolean;
    withPlusSign?: boolean;
    text?: string;
    size?: 'small' | 'large';
}) => {
    const {
        isFreeShipping = true,
        withPlusSign = false,
        text = 'Free Shipping',
        size = 'large',
    } = props;
    const sx = {
        marginLeft: withPlusSign ? 0.5 : 0,
        verticalAlign: 'middle',
        display: 'block',
        height: '1.5rem',
        fontSize: '1.1rem',
    };
    return isFreeShipping ? (
        <Typography
            variant={size == 'small' ? 'freeShippingSmall' : 'freeShipping'}
            sx={sx}
        >
            {withPlusSign ? (
                <AddSharp sx={{ height: 18, mt: '-0.125em' }} />
            ) : (
                ''
            )}
            <Typography variant="freeShippingSmall"></Typography> {text}
        </Typography>
    ) : (
        <Typography
            variant={size == 'small' ? 'freeShippingSmall' : 'freeShipping'}
            sx={sx}
        >
            &nbsp;
        </Typography>
    );
};

export const ShipsInText = (props: { shipsIn: string }) => {
    return (
        <Box
            component="span"
            sx={{ whiteSpace: 'nowrap' }}
            aria-label={props.shipsIn.replace('-', ' to ')}
        >
            {props.shipsIn}
        </Box>
    );
};

export const LeavesWarehouseIn = ({
    shipsIn,
    size,
    inline = false,
}: {
    shipsIn: string;
    size: 'small' | 'medium' | 'large';
    inline?: boolean;
}) => {
    let fontSize = '1rem';
    switch (size) {
        case 'small':
            fontSize = '.8125rem';
            break;
        case 'medium':
            fontSize = '.9375rem';
            break;
    }
    return (
        <Typography variant="body2" fontSize={fontSize} marginBottom={1.5}>
            {shipsIn ? (
                size == 'small' ? (
                    <>
                        <span aria-label={'Leaves our warehouse in '}>
                            Leaves&nbsp;Warehouse:
                        </span>
                        {inline ? <>&nbsp;</> : <br />}
                        <ShipsInText shipsIn={shipsIn} />
                    </>
                ) : size == 'medium' ? (
                    <>
                        Leaves our warehouse in{' '}
                        <ShipsInText shipsIn={shipsIn} />
                    </>
                ) : (
                    <>
                        This item leaves our warehouse within{' '}
                        <ShipsInText shipsIn={shipsIn} />
                    </>
                )
            ) : (
                <></>
            )}
        </Typography>
    );
};

export const ItemShippingText = (props: {
    item: Item;
    withPlusSign?: boolean;
}) => {
    const { item, withPlusSign = true } = props;

    if (item.isFreeShipping) {
        return (
            <FreeShippingText
                isFreeShipping={props.item.isFreeShipping}
                withPlusSign={withPlusSign}
                text={'Free Shipping'}
                size="small"
            />
        );
    }

    return item.shippingDescription !== 'Flat Rate Shipping' ? (
        <Typography
            component="div"
            variant="muted"
            sx={{
                fontSize: '50%',
                marginLeft: 1,
                whiteSpace: 'break-spaces',
                mb: 1,
            }}
        >
            {item.shippingDescription}
        </Typography>
    ) : (
        <></>
    );
};

export const OpenBoxLink = (props: {
    itemId: string;
    price: string;
    onPageLink?: string;
    sx?: SxProps;
}) => {
    const { isClientSide } = React.useContext(SSRContext);
    const { itemId, price, onPageLink = '', sx = {} } = props;
    const isCallForPricing = price == '$0.00';
    const priceText = isCallForPricing ? 'Call Us' : 'from ' + price;
    const screenReaderPriceText = isCallForPricing
        ? 'Call Us for pricing'
        : 'from ' + screenReaderPrice(price);
    const onPageElement =
        onPageLink.length > 0 &&
        isClientSide() &&
        document &&
        document.querySelector('[id="' + onPageLink + '"]')
            ? document.querySelector('[id="' + onPageLink + '"]')
            : null;
    return (
        <Box sx={{ my: 1, '> a': { display: 'inline' }, ...sx }}>
            <Typography
                component="span"
                variant="bold"
                sx={{
                    height: '1.5rem',
                    '&::before': {
                        content: '""',
                        display: 'block',
                    },
                }}
                aria-label={
                    'Open Box item available ' +
                    (isCallForPricing
                        ? 'Call us for pricing'
                        : screenReaderPrice(price))
                }
            >
                Open-Box:
            </Typography>
            <>
                {onPageElement !== null ? (
                    <Link
                        href="#open-box"
                        sx={{ ml: 0.5 }}
                        underline="always"
                        onClick={() => onPageElement.scrollIntoView()}
                    >
                        {priceText}
                    </Link>
                ) : (
                    <OpenBoxModal
                        itemId={itemId}
                        link={
                            <Link
                                href="#"
                                sx={{ ml: 0.5 }}
                                aria-label={
                                    'Open-Box items available ' +
                                    screenReaderPriceText +
                                    '. Click here to view the open box items.'
                                }
                                underline="always"
                            >
                                {priceText}
                            </Link>
                        }
                    />
                )}
            </>
        </Box>
    );
};
