// DigitalDataContext.tsx
import React, { createContext } from 'react';
import { Item, PaginatedItemsResponseMetaData } from '../types';
import { findDigitalDataEntry, loadScript } from '../functions';
import { gql } from '@apollo/client';
import { client } from '../Graphql/ApolloClient';
import {
    isAdobeLaunchDevelopment,
    isAdobeLaunchProduction,
    isAdobeLaunchTesting,
    isThirdPartyProduction,
} from '../config';
import document from 'global/document';
import window from 'global/window';
import AdobeTargetEvent from '../Events/AdobeTargetEvent';

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isObject(item: Item) {
    return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
function isArray(item: Item) {
    return item && Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
function mergeDeep(target: any, ...sources: any) {
    const mergeArrayKeys = ['user'];
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else if (isArray(source[key]) && isArray(target[key])) {
                if (
                    mergeArrayKeys.indexOf(key) >= 0 &&
                    source[key].length > 0 &&
                    target[key].length > 0
                ) {
                    // This is one of the arrays we'll merge in
                    mergeDeep(target[key][0], source[key][0]);
                    continue;
                }

                for (let i = 0; i < source[key].length; ++i) {
                    target[key].push(source[key][i]);
                }
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

function getAdobeLaunchUrl() {
    const adobeLaunchDevelopment =
        '/script/thirdparty/AdobeReact/Development/35a296a88c7a/bc6cc9f8202f/launch-d90c72f987d2-development.min.js';
    const adobeLaunchStaging =
        '/script/thirdparty/AdobeReact/Staging/35a296a88c7a/bc6cc9f8202f/launch-a54d0c2e107d-staging.min.js';
    const adobeLaunchProduction =
        '/script/thirdparty/AdobeReact/Production/35a296a88c7a/bc6cc9f8202f/launch-18309580bc36.min.js';

    if (isAdobeLaunchProduction()) {
        return adobeLaunchProduction;
    }

    if (isAdobeLaunchTesting()) {
        return adobeLaunchStaging;
    }

    if (isAdobeLaunchDevelopment()) {
        return adobeLaunchDevelopment;
    }

    console.error(
        'Adobe Launch environment variable not set. Defaulting to development.',
    );
    // Default to development
    return adobeLaunchDevelopment;
}

const GLOBAL_DATA_ID = 'globalData';

function getGlobalDataElement() {
    let element = document.getElementById(GLOBAL_DATA_ID);
    if (!element) {
        element = document.createElement('span');
        element.id = GLOBAL_DATA_ID;
        element.style.display = 'none';
        document.body.appendChild(element);
    }
    return element;
}

function resetGlobalData() {
    const globalDataElement = document.getElementById(GLOBAL_DATA_ID);
    if (globalDataElement) {
        globalDataElement.remove();
    }
}

/**
 * Workaround because window does not consistently save this
 * @param key
 * @param value
 */
function setGlobalData(key: string, value: string) {
    const dataElement = getGlobalDataElement();
    const dataKey = 'data-' + key;
    dataElement.setAttribute(dataKey, value);
}

function getGlobalData(key: string) {
    const dataElement = getGlobalDataElement();
    const dataKey = 'data-' + key;
    return dataElement.getAttribute(dataKey);
}

function getGlobalDataObject(key: string) {
    const jsonString = getGlobalData(key);
    if (!jsonString) {
        return {};
    }
    return JSON.parse(jsonString);
}

function setGlobalDataObject(key: string, dataObject: any) {
    setGlobalData(key, JSON.stringify(dataObject));
}

export const QL_CLIENT_IP = gql`
    query getSite {
        siteClientIP {
            success
            message
        }
    }
`;

export const LOCAL_STORAGE_KEY_CLIENT_IP = 'client_ip';

export const DigitalDataContext = createContext({
    setDigitalData: (data: object) => {
        return;
    },
    getLoading: (): boolean => {
        return true;
    },
    resetAnalytics: () => {
        return;
    },
    deferredPageload: () => {
        return;
    },
    pushDigitalDataDependency: (
        dependencyName: string,
        dependencyValue: any,
    ) => {
        return;
    },
    hasDependencySet: (dependencyName: string) => {
        return;
    },
    pushItemDependency: (data: {
        results: Item[];
        totalResults: number;
        metaData: PaginatedItemsResponseMetaData;
    }) => {
        return;
    },
    pushUserDataDependency: (data: { ipAddress: string }) => {
        return;
    },
    pushCarouselDependency: (data: {
        carouselName: string;
        results: Item[];
        itemWidth: number;
    }) => {
        return;
    },
    setTransactionId: (transId: string) => {
        return;
    },
    initializeAnalyticsDependencies: () => {
        return;
    },
    analyticsDependenciesReady: (): boolean => {
        return true;
    },
    urlChanged: (): boolean => {
        return true;
    },
    setLocalStorageParamsFromQuery: () => {
        return;
    },
    mergeDeferredDigitalDataDependencies: () => {
        return;
    },
    trackAjaxPageload: () => {
        return;
    },
    trackAdobePageload: (): boolean => {
        return true;
    },
    loadAdobeLaunch: (dd: object): boolean => {
        return true;
    },
    getClientIPAddress: (): Promise<string> => {
        return new Promise(() => {
            return;
        });
    },
});

export const DigitalDataProvider = React.memo(function DigitalDataProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const PREVIOUS_URL = 'previous-url';
    const ADOBE_LOADING = 'adobe-loading';
    const ADOBE_LOADED = 'adobe-loaded';
    const ADOBE_LAUNCH_BEACON_SENT = 'adobe-launch-beacon-sent';
    const ANALYTICS_DEPENDENCIES = 'analytics-dependencies';
    const DEFERRED_DIGITAL_DATA_ELEMENTS = 'deferred-digital-data-elements';
    const ADOBE_LAUNCH_SCRIPT_INITIALIZED = 'adobe-launch-script-initialized';

    const setLoading = (loading: boolean) => {
        setGlobalData(ADOBE_LOADING, loading ? 'true' : 'false');
    };
    const getLoading = (): boolean => {
        return getGlobalData(ADOBE_LOADING) == 'true';
    };

    const setLoaded = (loaded: boolean) => {
        setGlobalData(ADOBE_LOADED, loaded ? 'true' : 'false');
    };
    const getLoaded = (): boolean => {
        return getGlobalData(ADOBE_LOADED) == 'true';
    };

    const setAdobeLaunchScriptInitialized = (initialized: boolean) => {
        setGlobalData(
            ADOBE_LAUNCH_SCRIPT_INITIALIZED,
            initialized ? 'true' : 'false',
        );
    };

    const getAdobeLaunchScriptInitialized = (): boolean => {
        return getGlobalData(ADOBE_LAUNCH_SCRIPT_INITIALIZED) == 'true';
    };

    const setAdobeLaunchBeaconSent = (sent: boolean) => {
        setGlobalData(ADOBE_LAUNCH_BEACON_SENT, sent ? 'true' : 'false');
    };

    const getAdobeLaunchBeaconSent = (): boolean => {
        return getGlobalData(ADOBE_LAUNCH_BEACON_SENT) == 'true';
    };

    const getAnalyticsDependencies = (): any => {
        return getGlobalDataObject(ANALYTICS_DEPENDENCIES);
    };

    const setAnalyticsDependency = (key: string, value: boolean) => {
        const analyticsDependencies = getAnalyticsDependencies();
        analyticsDependencies[key] = value;
        setGlobalDataObject(ANALYTICS_DEPENDENCIES, analyticsDependencies);
    };

    const getDeferredDigitalDataElements = (): any => {
        return getGlobalDataObject(DEFERRED_DIGITAL_DATA_ELEMENTS);
    };

    const setDeferredDigitalDataElement = (key: string, value: boolean) => {
        const deferredDigitalDataElements = getDeferredDigitalDataElements();
        deferredDigitalDataElements[key] = value;
        setGlobalDataObject(
            DEFERRED_DIGITAL_DATA_ELEMENTS,
            deferredDigitalDataElements,
        );
    };

    const resetAnalytics = () => {
        const adobeLaunchScriptInitialized = getAdobeLaunchScriptInitialized();
        resetGlobalData();
        // Reset Adobe Target
        AdobeTargetEvent.reset();
        setAdobeLaunchScriptInitialized(adobeLaunchScriptInitialized);
        setLoading(true);
        window.digitalData = {};
        const thirdPartyProduction = isThirdPartyProduction();
        window.isThirdPartyProduction = function () {
            return thirdPartyProduction;
        };
    };

    const setPreviousUrl = () => {
        setGlobalData(PREVIOUS_URL, window.location.href);
    };

    const getPreviousUrl = () => {
        return getGlobalData(PREVIOUS_URL);
    };

    const urlChanged = () => {
        return window.location.href !== getPreviousUrl();
    };

    const setLocalStorageParamsFromQuery = () => {
        const localStorageParams = ['twclid'];
        const searchParams = new URLSearchParams(window.location.search);

        for (let i = 0; i < localStorageParams.length; ++i) {
            const storageParam = localStorageParams[i];
            if (searchParams.has(storageParam)) {
                const storageValue = searchParams.get(storageParam) ?? '';
                if (storageValue) {
                    localStorage.setItem(storageParam, storageValue);
                }
            }
        }
    };

    const createTransactionId = (): string => {
        let tId = '';
        try {
            for (let j = 0; j < 4; ++j) {
                tId += Math.random().toString(36).substring(2, 15);
            }
        } catch (e) {
            console.warn(e);
        }

        try {
            const maxLength = 100;

            if (tId.length > maxLength) {
                tId = tId.substring(0, maxLength);
            }

            if (!tId) {
                // Last-ditch attempt to set transaction ID
                tId = 'tidfb';
                for (let k = 0; k < 4; ++k) {
                    tId += Math.random().toString().replace('0.', '');
                }
            }
        } catch (e) {
            console.warn(e);
        }
        setTransactionId(tId);
        return tId;
    };

    const deferredPageload = () => {
        mergeDeferredDigitalDataDependencies();
        trackAdobePageload();
    };

    const resetAnalyticsIfUrlChanged = () => {
        if (urlChanged()) {
            resetAnalytics();
            setPreviousUrl();
        }
    };

    const setDigitalData = (data: object) => {
        resetAnalyticsIfUrlChanged();
        setLocalStorageParamsFromQuery();

        if (getLoading()) {
            window.digitalData = data;
            setLoading(false);
            loadAdobeLaunch();
        }
    };

    const pushDigitalDataDependency = (
        dependencyName: string,
        dependencyValue: any,
    ) => {
        if (getAdobeLaunchBeaconSent()) {
            // Already sent
            return;
        }

        setAnalyticsDependency(dependencyName, true);
        setDeferredDigitalDataElement(dependencyName, dependencyValue);

        if (analyticsDependenciesReady()) {
            deferredPageload();
        }
    };

    const hasDependencySet = (dependencyName: string) => {
        const analyticsDependencies = getAnalyticsDependencies();
        if (
            !Object.prototype.hasOwnProperty.call(
                analyticsDependencies,
                dependencyName,
            )
        ) {
            return false;
        }
        return analyticsDependencies[dependencyName];
    };

    const pushCarouselDependency = (data: {
        carouselName: string;
        results: Item[];
        itemWidth: number;
    }) => {
        try {
            resetAnalyticsIfUrlChanged();

            const dependencyName = data.carouselName;

            if (hasDependencySet(dependencyName)) {
                return;
            }

            const productIds: string[] = [];
            const items = data.results;

            for (let i = 0; i < items.length; ++i) {
                const item = items[i];
                const id = item.id;
                productIds.push(id);
            }

            pushDigitalDataDependency(dependencyName, {
                page: {
                    attributes: {
                        carousels: [
                            {
                                'carousel-id': dependencyName,
                                'carousel-item-width': data.itemWidth,
                                'item-ids': productIds,
                            },
                        ],
                    },
                },
            });
        } catch (e) {
            console.error(e);
        }
    };

    const pushItemDependency = (data: {
        results: Item[];
        totalResults: number;
        metaData: PaginatedItemsResponseMetaData;
    }) => {
        try {
            resetAnalyticsIfUrlChanged();

            const dependencyName = 'items';
            if (hasDependencySet(dependencyName)) {
                return;
            }

            const productIds: string[] = [];
            const googleAnalyticsData: any[] = [];

            const items = data.results;

            if (items !== undefined) {
                for (let i = 0; i < items.length; ++i) {
                    const item = items[i];
                    const id = item.id;
                    productIds.push(id);
                    googleAnalyticsData.push({
                        item_name: item.name,
                        item_id: item.id,
                        currency: 'USD',
                        price: item.pricing.sale.toString(),
                        quantity: '1',
                    });
                }
            }

            let pushedDependency = false;

            if (data.metaData) {
                const metaData = data.metaData;
                const requestType = metaData.requestType;
                switch (requestType) {
                    case 'search':
                        pushedDependency = true;
                        pushDigitalDataDependency(dependencyName, {
                            page: {
                                attributes: {
                                    googleAnalyticsData: googleAnalyticsData,
                                    queryTime: metaData.queryTime,
                                    searchTypeId: metaData.searchTypeId,
                                    trackingId: metaData.trackingId,
                                },
                                pageInfo: {
                                    numProductResults: data.totalResults,
                                    productIds: productIds,
                                    onsiteSearchTerm: metaData.searchTerm,
                                },
                            },
                        });
                        break;
                    case 'category':
                        pushedDependency = true;
                        pushDigitalDataDependency(dependencyName, {
                            page: {
                                attributes: {
                                    finderType: metaData.finderType,
                                    groupingName: metaData.groupingName,
                                    googleAnalyticsData: googleAnalyticsData,
                                },
                                pageInfo: {
                                    numProductResults: data.totalResults,
                                    productIds: productIds,
                                },
                            },
                        });
                        break;
                }
            }

            if (!pushedDependency) {
                pushDigitalDataDependency(dependencyName, {
                    page: {
                        attributes: {
                            googleAnalyticsData: googleAnalyticsData,
                        },
                        pageInfo: {
                            numProductResults: data.totalResults,
                            productIds: productIds,
                        },
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const pushUserDataDependency = (data: { ipAddress: string }) => {
        try {
            resetAnalyticsIfUrlChanged();

            const dependencyName = 'user-data';
            if (hasDependencySet(dependencyName)) {
                return;
            }

            pushDigitalDataDependency(dependencyName, {
                user: [
                    {
                        profile: {
                            profileInfo: {
                                ipAddress: data.ipAddress,
                            },
                        },
                    },
                ],
            });
        } catch (e) {
            console.error(e);
        }
    };

    const setTransactionId = (transId: string) => {
        window.transactionID = transId;
    };

    const initializeAnalyticsDependencies = () => {
        setGlobalDataObject(ANALYTICS_DEPENDENCIES, {});

        const analyticsDependenciesList = findDigitalDataEntry([
            'page',
            'attributes',
            'analyticsDependencies',
        ]);

        if (analyticsDependenciesList) {
            for (let i = 0; i < analyticsDependenciesList.length; ++i) {
                const key = analyticsDependenciesList[i];
                setAnalyticsDependency(key, false);
            }
        }

        mergeDeferredDigitalDataDependencies();
    };

    const analyticsDependenciesReady = () => {
        if (!getLoaded()) {
            return false;
        }

        const analyticsDependencies = getAnalyticsDependencies();

        for (const analyticsDependency in analyticsDependencies) {
            if (!analyticsDependencies[analyticsDependency]) {
                // At least one dependency was not loaded
                return false;
            }
        }

        return true;
    };

    const mergeDeferredDigitalDataDependencies = () => {
        const analyticsDependencies = getAnalyticsDependencies();
        const deferredDigitalDataElements = getDeferredDigitalDataElements();

        if (
            Object.keys(deferredDigitalDataElements).length === 0 ||
            Object.keys(analyticsDependencies).length === 0 ||
            Object.keys(window.digitalData).length === 0
        ) {
            return;
        }

        for (const dependencyName in deferredDigitalDataElements) {
            if (
                Object.prototype.hasOwnProperty.call(
                    deferredDigitalDataElements,
                    dependencyName,
                )
            ) {
                // Has the analytics dependency
                setAnalyticsDependency(dependencyName, true);
                window.digitalData = mergeDeep(
                    { ...window.digitalData },
                    deferredDigitalDataElements[dependencyName],
                );
            }
        }
    };

    const trackAjaxPageload = () => {
        document.body.dispatchEvent(new CustomEvent('reactAjaxPageloadEvent'));
    };

    const trackAdobePageload = () => {
        setAdobeLaunchBeaconSent(true);

        if (getAdobeLaunchScriptInitialized()) {
            // Adobe Launch is loading/loaded.
            // Fire off the AJAX pageload event.
            trackAjaxPageload();
            // Now exit, we don't want to load it twice.
            return false;
        }

        setAdobeLaunchScriptInitialized(true);

        loadScript(getAdobeLaunchUrl()).then(() => {
            // Adobe Launch successfully loaded
        });
        return true;
    };

    const loadAdobeLaunch = (): boolean => {
        if (getLoaded()) {
            return false;
        }

        setLoaded(true);
        // Set or reset the transaction ID
        createTransactionId();
        initializeAnalyticsDependencies();

        if (analyticsDependenciesReady()) {
            return trackAdobePageload();
        }

        return false;
    };

    const getClientIPAddress = async (): Promise<string> => {
        return new Promise((resolve, reject) => {
            (async function () {
                let clientIPAddress: string = '0.0.0.0';
                // localStorage.getItem(LOCAL_STORAGE_KEY_CLIENT_IP) || '';
                if (clientIPAddress !== '') {
                    resolve(clientIPAddress as string);
                } else {
                    try {
                        const response = await client.query({
                            query: QL_CLIENT_IP,
                        });
                        if (response.errors) {
                            reject(response.errors[0]);
                        }
                        if (response.data?.siteClientIP) {
                            clientIPAddress =
                                response.data.siteClientIP.message;
                            if (response.data.siteClientIP.success) {
                                localStorage.setItem(
                                    LOCAL_STORAGE_KEY_CLIENT_IP,
                                    clientIPAddress,
                                );
                                resolve(clientIPAddress as string);
                            } else {
                                reject('IP address not found');
                            }
                        }
                    } catch (error: any) {
                        reject(error);
                    }
                }
            })();
        });
    };

    return (
        <DigitalDataContext.Provider
            value={{
                setDigitalData,
                getLoading,
                setTransactionId,
                initializeAnalyticsDependencies,
                analyticsDependenciesReady,
                mergeDeferredDigitalDataDependencies,
                trackAdobePageload,
                trackAjaxPageload,
                loadAdobeLaunch,
                deferredPageload,
                pushDigitalDataDependency,
                pushCarouselDependency,
                hasDependencySet,
                pushItemDependency,
                pushUserDataDependency,
                resetAnalytics,
                urlChanged,
                setLocalStorageParamsFromQuery,
                getClientIPAddress,
            }}
        >
            {children}
        </DigitalDataContext.Provider>
    );
});
